import { useAppDispatch, useAppSelector } from "@/store";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { selectPendingSafe, updatePendingSafe } from "@/store/pendingSafeSlice";
import { TonwebContext } from "@/components/TonwebProvider";
import { useWallet, useTonProvider } from "@/hooks";
import { supportedChain } from "@/config/chains";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { checkSafeCreationTx, createNewSafe, handleSafeCreationError } from "@/components/new-safe/create/logic";
import { isClientSide } from "@/utils";
import { toRawAddress } from "@/utils/addresses";

export enum SafeCreationStatus {
  AWAITING,
  PROCESSING,
  WALLET_REJECTED,
  ERROR,
  REVERTED,
  TIMEOUT,
  SUCCESS,
  INDEXED,
  INDEX_FAILED,
}

export const useSafeCreation = (
  status: SafeCreationStatus,
  setStatus: Dispatch<SetStateAction<SafeCreationStatus>>,
) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isWatching, setIsWatching] = useState(false);
  const pendingSafe = useAppSelector(selectPendingSafe);
  const tonweb = useContext(TonwebContext);
  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useAppDispatch();
  const wallet = useWallet();
  const { chainId } = supportedChain;
  const { requestAccounts, walletsFound } = useTonProvider();

  const createSafe = useCallback(async () => {
    if (
      !tonweb ||
      !wallet ||
      !pendingSafe ||
      !pendingSafe.address ||
      !pendingSafe.initCode ||
      !pendingSafe.initData ||
      pendingSafe.sentData ||
      !isClientSide() ||
      !walletsFound ||
      isCreating
    ) {
      return;
    }

    setIsCreating(true);

    try {
      // TODO: workaround for integration of tonconnect and openmask,
      //       check connection before deploy contract
      await tonConnectUI.getWallets();

      const accounts: string[] = await requestAccounts();
      const accountIndex = accounts.findIndex((a) => toRawAddress(a) === wallet.address);
      if (accountIndex == -1) {
        setStatus(SafeCreationStatus.ERROR);
        return;
      }
      const signerAddress = accounts[accountIndex];

      const { sentData } = await createNewSafe(
        tonweb.provider,
        signerAddress,
        pendingSafe,
        walletsFound,
        tonConnectUI.connector,
      );
      console.log("deploy success");
      const newPendingSafe = {
        ...pendingSafe,
        senderAddress: signerAddress,
        sentData,
      };
      dispatch(updatePendingSafe(newPendingSafe));
      setStatus(SafeCreationStatus.SUCCESS);

      const txStatus = await checkSafeCreationTx(chainId, tonweb.provider, newPendingSafe, walletsFound);
      setStatus(txStatus);
    } catch (e) {
      console.log(e);
      const status = handleSafeCreationError(e);
      setStatus(status);
    }

    setIsCreating(false);
  }, [
    chainId,
    dispatch,
    isCreating,
    pendingSafe,
    requestAccounts,
    setStatus,
    tonConnectUI,
    tonweb,
    wallet,
    walletsFound,
  ]);

  const watchSafeTx = useCallback(async () => {
    if (
      !tonweb ||
      !pendingSafe ||
      !pendingSafe.address ||
      !pendingSafe.sentData ||
      !pendingSafe.senderAddress ||
      isWatching
    ) {
      return;
    }
    setStatus(SafeCreationStatus.PROCESSING);
    setIsWatching(true);

    const txStatus = await checkSafeCreationTx(chainId, tonweb.provider, pendingSafe, walletsFound);
    setStatus(txStatus);

    setIsWatching(false);
  }, [chainId, isWatching, pendingSafe, setStatus, tonweb, walletsFound]);

  useEffect(() => {
    if (status !== SafeCreationStatus.AWAITING) return;

    if (pendingSafe?.sentData && !isCreating) {
      void watchSafeTx();
      return;
    }

    createSafe();
  }, [createSafe, isCreating, pendingSafe, status, watchSafeTx]);

  return { createSafe };
};
