import type { ReactElement, ReactNode } from "react";
import { Typography } from "@mui/material";
import styles from "./styles.module.css";

type PagePlaceholderProps = {
  img: ReactNode;
  text: ReactNode;
  children?: ReactNode;
};

const PagePlaceholder = ({ img, text, children }: PagePlaceholderProps): ReactElement => {
  return (
    <div className={styles.container}>
      {img}

      {typeof text === "string" ? (
        <Typography variant="body1" color="primary.light" mt={3} className={styles.description}>
          {text}
        </Typography>
      ) : (
        text
      )}

      {children}
    </div>
  );
};

export default PagePlaceholder;
