import type { ReactElement } from "react";
import List, { type ListProps } from "@mui/material/List";
import ListItemButton, { type ListItemButtonProps } from "@mui/material/ListItemButton";
import ListItemIcon, { type ListItemIconProps } from "@mui/material/ListItemIcon";
import ListItemText, { type ListItemTextProps } from "@mui/material/ListItemText";
import Link from "next/link";
import type { LinkProps } from "next/link";
import Badge from "@mui/material/Badge";
import styles from "./styles.module.css";

export const SidebarList = ({ children, ...rest }: Omit<ListProps, "className">): ReactElement => (
  <List className={styles.list} {...rest}>
    {children}
  </List>
);

export const SidebarListItemButton = ({
  href,
  children,
  ...rest
}: Omit<ListItemButtonProps, "sx"> & { href?: LinkProps["href"] }): ReactElement => {
  const button = (
    <ListItemButton className={styles.listItemButton} {...rest}>
      {children}
    </ListItemButton>
  );

  return href ? (
    <Link href={href} passHref>
      {button}
    </Link>
  ) : (
    button
  );
};

export const SidebarListItemIcon = ({
  children,
  badge = false,
  ...rest
}: Omit<ListItemIconProps, "className"> & { badge?: boolean }): ReactElement => (
  <ListItemIcon
    className={styles.icon}
    sx={{
      "& svg": {
        width: "24px",
        height: "24px",
        "& path": ({ palette }) => ({
          fill: palette.logo.main,
        }),
      },
    }}
    {...rest}
  >
    <Badge color="error" variant="dot" invisible={!badge} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      {children}
    </Badge>
  </ListItemIcon>
);

export const SidebarListItemText = ({
  children,
  bold = false,
  ...rest
}: ListItemTextProps & { bold?: boolean }): ReactElement => (
  <ListItemText primaryTypographyProps={{ variant: "body2", fontWeight: bold ? 700 : undefined }} {...rest}>
    {children}
  </ListItemText>
);
