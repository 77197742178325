import { createTheme } from "@mui/material";
import palette from "@/constants/lightPalette";

const colors = palette;
export const buttonTheme = createTheme({
  palette: {
    mode: "light",
    ...colors,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "stretched" },
          style: {
            padding: "12px 48px",
          },
        },
        {
          props: { variant: "danger" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.error.background,
            color: theme.palette.error.main,
            "&:hover": {
              color: theme.palette.error.dark,
              backgroundColor: theme.palette.error.light,
            },
          }),
        },
      ],
      styleOverrides: {
        sizeSmall: {
          fontSize: "14px",
          padding: "8px 24px",
        },
        sizeMedium: {
          fontSize: "16px",
          padding: "12px 24px",
        },
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          fontWeight: "bold",
          lineHeight: 1.25,
          borderColor: theme.palette.primary.main,
          textTransform: "none",
          "&:hover": {
            boxShadow: "none",
          },
        }),
        outlined: {
          border: "2px solid",
          "&:hover": {
            border: "2px solid",
          },
        },
        sizeLarge: { fontSize: "16px" },
      },
    },
  },
});

export const accordionTheme = createTheme({
  palette: {
    mode: "light",
    ...colors,
  },
  components: {
    MuiAccordion: {
      variants: [
        {
          props: { variant: "elevation" },
          style: ({ theme }) => ({
            border: "none",
            boxShadow: "0",
            "&:not(:last-of-type)": {
              borderRadius: "0 !important",
              borderBottom: `1px solid ${theme.palette.border.light}`,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: "8px",
            },
          }),
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          transition: "background 0.2s, border 0.2s",
          borderRadius: theme.shape.borderRadius,
          border: `1px solid ${theme.palette.border.light}`,
          overflow: "hidden",

          "&::before": {
            content: "none",
          },

          "&:hover": {
            borderColor: theme.palette.secondary.light,
          },

          "&:hover > .MuiAccordionSummary-root": {
            background: theme.palette.background.light,
          },

          "&.Mui-expanded": {
            borderColor: theme.palette.secondary.light,
          },

          "&.Mui-expanded > .MuiAccordionSummary-root": {
            background: theme.palette.background.light,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
        content: {
          "&.Mui-expanded": {
            margin: "12px 0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
  },
});

export const txTheme = createTheme(buttonTheme, accordionTheme);
