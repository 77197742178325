import { Coins } from "ton3-core";
import { Typography, IconButton, Skeleton, Tooltip, SvgIcon } from "@mui/material/";
import { useSafeInfo, useSafeAddress, useTonBalance } from "@/hooks";
import { supportedChain } from "@/config/chains";
import SafeIcon from "@/components/common/SafeIcon";
import NewTxButton from "@/components/sidebar/NewTxButton";
import copyIcon from "#/images/common/copy.svg";
import linkIcon from "#/images/sidebar/link.svg";
import CopyButton from "@/components/common/CopyButton";
import { shortenAddress } from "@/utils";
import styles from "./styles.module.css";

const SafeHeader = () => {
  const { tonBalance, loading: isTonBalanceLoading } = useTonBalance();
  const { safe, safeLoading: isSafeLoading } = useSafeInfo();
  const { threshold, owners: safeOwners, name } = safe;

  const owners = safeOwners.map((owner) => owner.address);
  const safeAddress = useSafeAddress();
  const blockExplorerLink = supportedChain?.explorerUrl + "/address/" + safeAddress;

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.safe}>
          <div>
            {isSafeLoading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <SafeIcon address={safeAddress} threshold={threshold} owners={owners?.length} />
            )}
          </div>

          <div className={styles["info-container"]}>
            {isSafeLoading ? (
              <Typography variant="body2">
                <Skeleton variant="text" width={86} />
              </Typography>
            ) : (
              <p className={styles["safe-name"]}>{name}</p>
            )}

            {isSafeLoading ? (
              <Typography variant="body2">
                <Skeleton variant="text" width={86} />
              </Typography>
            ) : (
              <p className={styles["safe-address"]}>{shortenAddress(safeAddress)}</p>
            )}

            <p className={styles.balance}>
              {isTonBalanceLoading ? (
                <Skeleton variant="text" width={60} />
              ) : (
                `${Coins.fromNano(tonBalance).toString()} TON`
              )}
            </p>
          </div>
        </div>

        <div className={styles.iconButtons}>
          <CopyButton text={safeAddress} className={styles.iconButton}>
            <SvgIcon
              component={copyIcon}
              inheritViewBox
              sx={{ width: "24px", height: "24px", color: "#8D9199", background: "#fff" }}
            />
          </CopyButton>

          <Tooltip title="View on tonscan" placement="top">
            <IconButton className={styles.iconButton} target="_blank" rel="noreferrer" href={blockExplorerLink}>
              <SvgIcon
                component={linkIcon}
                inheritViewBox
                sx={{ width: "24px", height: "24px", color: "#8D9199", background: "#fff" }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <NewTxButton />
    </div>
  );
};

export default SafeHeader;
