import { supportedChain } from "@/config/chains";
import { isClientSide } from "@/utils";
import { createContext, FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import { TonClient } from "ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import axios from "axios";

export type TonState = TonClient | undefined;

const initialState: TonState = undefined;

export const TonContext = createContext<TonState>(initialState);

type TonProviderProps = {
  children: JSX.Element;
};

const TonProvider: FunctionComponent<TonProviderProps> = ({ children }) => {
  const [ton, setTon] = useState<TonClient>();

  useEffect(() => {
    const handler = async () => {
      if (!isClientSide()) {
        return undefined;
      }

      if (!supportedChain) {
        return undefined;
      }

      let endpoint = supportedChain.tonCenterUrl;
      setTon(
        new TonClient({
          endpoint,
          httpAdapter: async (config) => {
            const data = JSON.parse(config.data);
            if (data.method) {
              config.data = JSON.stringify({ ...data, method: "ton_" + data.method });
            }

            const r = await axios.defaults.adapter!(config);

            const response = JSON.parse(r.data);

            if (response.result) {
              response.ok = true;
            }

            r.data = JSON.stringify(response);

            return r;
          },
        }),
      );
    };
    handler();
  }, []);

  return <TonContext.Provider value={ton}>{children}</TonContext.Provider>;
};

export default memo(TonProvider);
