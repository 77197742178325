const lightPalette = {
  text: {
    primary: "#121312",
    secondary: "#A1A3A7",
    disabled: "#DDDEE0",
  },
  primary: {
    dark: "#3c3c3c",
    main: "#121312",
    light: "#636669",
  },
  secondary: {
    dark: "#0FDA6D",
    main: "#12FF80",
    light: "#CCE5FF",
    background: "#EFFFF4",
  },
  border: {
    main: "#A1A3A7",
    light: "#DCDEE0",
    background: "#F4F4F4",
  },
  error: {
    dark: "#AC2C3B",
    main: "#FF5F72",
    light: "#FFB4BD",
    background: "#FFE6EA",
  },
  success: {
    dark: "#028D4C",
    main: "#00B460",
    light: "#72F5B8",
    background: "#F2FFF9",
  },
  info: {
    dark: "#52BFDC",
    main: "#5FDDFF",
    light: "#B7F0FF",
    background: "#EFFCFF",
  },
  warning: {
    dark: "#CD674E",
    main: "#FF8061",
    light: "#FFB7A6",
    background: "#FFF0ED",
  },
  background: {
    default: "#F4F4F4",
    main: "#F4F4F4",
    paper: "#FFFFFF",
    light: "#E7F2FF",
  },
  backdrop: {
    main: "#636669",
  },
  logo: {
    main: "#121312",
    background: "#EEEFF0",
  },
  static: {
    main: "#121312",
  },
};

export default lightPalette;
