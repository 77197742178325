import type { listenerMiddlewareInstance } from "@/store";
import { makeLoadableSlice } from "./common";
import { Transaction } from "@/types/tx";
import { selectPendingTxs } from "./pendingTxsSlice";
import { clearPendingTx } from "@/store/pendingTxsSlice";
import { getTimestamp } from "@/utils/date";
import { TRANSACTION_TIMEOUT } from "@/config";

const { slice, selector } = makeLoadableSlice<Transaction[] | undefined>("txHistory", undefined);

export const txHistorySlice = slice;
export const selectTxHistory = selector;

export const txHistoryListener = (listenerMiddleware: typeof listenerMiddlewareInstance) => {
  listenerMiddleware.startListening({
    actionCreator: txHistorySlice.actions.set,
    effect: (action, listenerApi) => {
      const {
        payload: { data },
      } = action;

      if (!data) {
        return;
      }

      const pendingTxs = selectPendingTxs(listenerApi.getState());

      for (let i = 0, maxI = data.length; i < maxI; i++) {
        const {
          summary: { multiSigExecutionInfo },
          details,
        } = data[i];

        if (multiSigExecutionInfo) {
          const { orderCellBoc } = multiSigExecutionInfo;

          if (pendingTxs[orderCellBoc] && details) {
            listenerApi.dispatch(clearPendingTx({ txId: orderCellBoc }));
          }
        }
      }

      const now = getTimestamp();
      for (const [txId, pendingTx] of Object.entries(pendingTxs)) {
        if (!pendingTx.timestamp || now - pendingTx.timestamp > TRANSACTION_TIMEOUT) {
          listenerApi.dispatch(clearPendingTx({ txId }));
        }
      }
    },
  });
};
