import { Tooltip } from "@mui/material";
import { formatDateTime, formatTime, formatTimeInWords } from "@/utils/date";
import { useRouter } from "next/router";
import { Pathnames } from "@/config";
import { useTxFilter } from "@/utils/tx-history-filter";
import styles from "./styles.module.css";

const DAYS_THRESHOLD = 60;

/**
 * If queue, show relative time until threshold then show full date and time
 * If history, show time (as date labels are present)
 * If filter, show full date and time
 */

const DateTime = ({ value, forceShowTime = false }: { value: number; forceShowTime?: boolean }) => {
  const [filter] = useTxFilter();
  const router = useRouter();

  // (non-filtered) history is the endpoint that returns date labels
  const showTime = (router.pathname === Pathnames.TransactionHistory && !filter) || forceShowTime;

  const isOld = Math.floor((Date.now() - value) / 1000 / 60 / 60 / 24) > DAYS_THRESHOLD;
  const showDateTime = isOld;
  const time = showTime ? formatTime(value) : showDateTime ? formatDateTime(value) : formatTimeInWords(value);
  const [clock, meridiem] = time.split(" ");

  return (
    <Tooltip title={showDateTime ? "" : formatDateTime(value)} placement="top">
      {!showTime ? (
        <span>{time}</span>
      ) : (
        <>
          <span className={styles.clock}>{clock}</span> <span className={styles.meridiem}>{meridiem}</span>
        </>
      )}
    </Tooltip>
  );
};

export default DateTime;
