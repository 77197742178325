import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  type ListItemIconProps,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import type { TransactionDetails } from "@safe-global/safe-gateway-typescript-sdk";
import { ExpandMore } from "@mui/icons-material";
import {
  // useIsPending,
  useTransactionStatus,
  STATUS_LABELS,
} from "@/hooks";
import { isCancellationTxInfo, isMultisigDetailedExecutionInfo } from "@/utils/transaction-guards";
import { Profile } from "@/components";
import CircleIcon from "#/images/status-stepper/processing.svg";
import CheckIcon from "#/images/status-stepper/success.svg";
import CancelIcon from "#/images/common/cancel.svg";
import { TransactionSummary } from "@/types/transactions";
import styles from "./TxSigners.module.css";

const Created = () => <SvgIcon component={CheckIcon} inheritViewBox className={styles.icon} />;
const MissingConfirmation = () => <SvgIcon component={CircleIcon} inheritViewBox className={styles.icon} />;
const Check = () => <SvgIcon component={CheckIcon} inheritViewBox className={styles.icon} />;
const Cancel = () => <SvgIcon component={CancelIcon} inheritViewBox className={styles.icon} />;

enum StepState {
  CONFIRMED = "CONFIRMED",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  ERROR = "ERROR",
}

const StyledListItemIcon = ({
  $state,
  ...rest
}: {
  $state: StepState;
} & ListItemIconProps) => (
  <ListItemIcon
    sx={() => ({
      padding: "0 !important",
      width: "24px !important",
      height: "24px !important",
      minWidth: "24px !important",
      borderRadius: "50%",
      background: "#D3E4FF !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "16px",
    })}
    {...rest}
  />
);

type TxSignersProps = {
  txDetails: TransactionDetails;
  txSummary: TransactionSummary;
};

export const TxSigners = ({ txDetails, txSummary }: TxSignersProps) => {
  const { detailedExecutionInfo, txInfo, txId } = txDetails;
  // const isPending = useIsPending(txId);
  const txStatus = useTransactionStatus(txSummary);

  if (!detailedExecutionInfo || !isMultisigDetailedExecutionInfo(detailedExecutionInfo)) {
    return null;
  }

  const { confirmations: _confirmations, confirmationsRequired, executor } = detailedExecutionInfo;
  const confirmations = _confirmations.filter((c) => c.signature !== "" && c.signature !== undefined);
  const { length: confirmationsCount } = confirmations;
  const canExecute = true;
  const confirmationsNeeded = confirmationsRequired - confirmationsCount;
  const isConfirmed = confirmationsNeeded <= 0 || canExecute;

  return (
    <>
      <List className={styles.container}>
        <ListItem className={`${styles["list-item"]} mb-10`}>
          {isCancellationTxInfo(txInfo) ? (
            <>
              <StyledListItemIcon $state={StepState.ERROR}>
                <Cancel />
              </StyledListItemIcon>
              <ListItemText primaryTypographyProps={{ fontWeight: 700 }}>On-chain rejection created</ListItemText>
            </>
          ) : (
            <>
              <StyledListItemIcon $state={StepState.CONFIRMED}>
                <Created />
              </StyledListItemIcon>
              <ListItemText primaryTypographyProps={{ fontWeight: 700 }}>Created</ListItemText>
            </>
          )}
        </ListItem>

        <ListItem className={`${styles["list-item"]} mb-10`}>
          <Accordion className="!w-full !border-0 !shadow-none !bg-transparent">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="!p-0 !max-h-[36px]"
              sx={{ ".MuiAccordionSummary-content ": { alignItems: "center" } }}
            >
              <StyledListItemIcon $state={isConfirmed ? StepState.CONFIRMED : StepState.ACTIVE}>
                {isConfirmed ? <Check /> : <MissingConfirmation />}
              </StyledListItemIcon>
              <ListItemText primaryTypographyProps={{ fontWeight: 700 }}>
                Confirmations{" "}
                <Box className={styles.confirmationsTotal}>({`${confirmationsCount} of ${confirmationsRequired}`})</Box>
              </ListItemText>
            </AccordionSummary>
            <AccordionDetails className="!p-0">
              {confirmations.map(({ signer }) => (
                <ListItem key={signer.value} sx={{ py: 0 }}>
                  <ListItemText>
                    <Profile
                      address={signer.value}
                      name={signer.name}
                      avatarSize={24}
                      classOfName="text-xs"
                      shortAddress
                    />
                  </ListItemText>
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem className={styles["list-item"]}>
          <StyledListItemIcon $state={executor ? StepState.CONFIRMED : StepState.DISABLED}>
            {executor ? <Check /> : <MissingConfirmation />}
          </StyledListItemIcon>
          <ListItemText primaryTypographyProps={{ fontWeight: 700 }}>
            {executor ? "Executed" : txStatus === STATUS_LABELS.AWAITING_EXECUTION ? "Ready for execution" : txStatus}
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default TxSigners;
