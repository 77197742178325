import { Tooltip as MuiTooltip, tooltipClasses, TooltipProps, styled } from "@mui/material";
import { Error } from "@mui/icons-material";
import { isClientSide } from "@/utils";
import { useIsWrongChain, useWallet } from "@/hooks";
import { supportedChain } from "@/config/chains";
import styles from "./Switch.module.css";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "transparent",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2F3033",
    marginTop: "10px !important",
    padding: "4px 8px",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#F1F0F4",
  },
}));

const Switch = () => {
  const isConnected = !!useWallet();
  const isWrongChain = useIsWrongChain();
  const hasError = isClientSide() && isWrongChain;

  return (
    <Tooltip title={hasError ? "Please connect to the appropriate TON network." : ""}>
      <div
        className={`${styles.switch}  ${
          isConnected ? "border-[#73777F] hover:bg-[#49454F1F]/10" : "border-[#49454F1F]/10 text-[#1A1C1E]/40"
        }`}
      >
        {hasError && <Error className="mr-2 text-[#FF5449]" />}
        {isConnected ? supportedChain.chainName : "Not Connected"}
      </div>
    </Tooltip>
  );
};

export default Switch;
