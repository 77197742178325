import { PropsWithChildren } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from "@apollo/client";
import { SafeThemeProvider } from "@safe-global/safe-react-components";
import { ThemeProvider, Theme } from "@mui/material/styles";
import { theme } from "@/config";

const BASE_URL = process.env.NEXT_PUBLIC_GRAPHQL_URI;
const httpLink = new HttpLink({ uri: BASE_URL });
const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${BASE_URL}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});
const client = new ApolloClient({
  link: ApolloLink.from([namedLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const AppProvider = ({ children }: PropsWithChildren) => {
  const themeMode = "light";

  return (
    <ApolloProvider client={client}>
      <SafeThemeProvider mode={themeMode}>
        {(safeTheme: Theme) => (
          <ThemeProvider theme={{ ...safeTheme, breakpoints: theme.breakpoints }}>{children}</ThemeProvider>
        )}
      </SafeThemeProvider>
    </ApolloProvider>
  );
};

export default AppProvider;
