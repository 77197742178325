import { type ReactElement, type ReactNode, type SyntheticEvent, useState } from "react";
import { Button, DialogContent } from "@mui/material";
import ErrorMessage from "@/components/tx/ErrorMessage";
import { isClientSide } from "@/utils";
import { getTransferpayload, TransferParams, useCreateNativeTransfer } from "tonkey-gateway-typescript-sdk";
import { toRawAddress } from "@/utils/addresses";
import {
  TON_CONNECT_ERROR_CODE_POP_UP_CLOSED,
  TON_CONNECT_ERROR_CODE_WALLET_REJECTED,
} from "@/components/Web3Provider/errorCode";
import { MultiSig } from "tonkey-sdk";
import * as ton3 from "ton3-core";
import { useWallet, useTonProvider } from "@/hooks";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { captureException } from "@/utils";
import styles from "./styles.module.css";

type SignOrExecuteProps = {
  txId?: string;
  onSubmit: () => void;
  children?: ReactNode;
  error?: Error;
  isExecutable?: boolean;
  isRejection?: boolean;
  onlyExecute?: boolean;
  disableSubmit?: boolean;
  origin?: string;
  transferParams?: TransferParams;
};

const SignOrExecuteForm = ({
  txId,
  onlyExecute,
  onSubmit,
  children,
  isExecutable = false,
  isRejection = false,
  disableSubmit = false,
  origin,
  transferParams,
  ...props
}: SignOrExecuteProps): ReactElement => {
  const [isSubmittable, setIsSubmittable] = useState<boolean>(true);
  const [submitError, setSubmitError] = useState<Error | undefined>();
  const { createTransfer, data, loading, error } = useCreateNativeTransfer();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useWallet();
  const { requestAccounts, rawSign, walletsFound } = useTonProvider();

  // // Check that the transaction is executable
  // const canExecute = isCorrectNonce && (isExecutable || isNewExecutableTx)

  // // If checkbox is checked and the transaction is executable, execute it, otherwise sign it
  // const willExecute = shouldExecute && canExecute
  const willExecute = false;

  // // Assert that wallet, tx and provider are defined
  // const assertDependencies = (): [ConnectedWallet] => {
  //   if (!wallet) throw new Error("Wallet not connected");
  //   return [wallet];
  // };

  // Sign transaction
  const onSign = async (): Promise<string[] | undefined> => {
    if (isClientSide() && wallet && walletsFound && transferParams) {
      try {
        // TODO: workaround for integration of tonconnect and openmask,
        //       check connection before deploy contract
        await tonConnectUI.getWallets();

        const accounts: string[] = await requestAccounts();
        const accountIndex = accounts.findIndex((a) => toRawAddress(a) === wallet.address);
        if (accountIndex == -1) {
          throw new Error("Connected wallet not found");
        }
        const signerAddress = accounts[accountIndex];

        const [orderCell] = ton3.BOC.from(transferParams.orderCellBoc);
        const signature = await MultiSig.signOrder(orderCell, async (orderCellHash) => {
          const signature = await rawSign([
            {
              data: orderCellHash,
            },
            signerAddress,
          ]);
          return signature;
        });

        const signatures = new Array(transferParams.safe.owners.length);
        for (let i = 0; i < signatures.length; i++) {
          if (transferParams.safe.owners[i].address === toRawAddress(transferParams.wallet.address)) {
            signatures[i] = signature;
          } else {
            signatures[i] = "";
          }
        }
        return signatures;
      } catch (error) {
        captureException(error);
      }
    }

    // const [connectedWallet, createdTx, provider] = assertDependencies();

    // // Smart contract wallets must sign via an on-chain tx
    // if (await isSmartContractWallet(connectedWallet)) {
    //   // If the first signature is a smart contract wallet, we have to propose w/o signatures
    //   // Otherwise the backend won't pick up the tx
    //   // The signature will be added once the on-chain signature is indexed
    //   const id = txId || (await proposeTx(createdTx));
    //   await dispatchOnChainSigning(createdTx, provider, id);
    //   return id;
    // }

    // // Otherwise, sign off-chain
    // const signedTx = await dispatchTxSigning(createdTx, safe.version, txId);

    // /**
    //  * We need to handle this case because of the way useTxSender is designed,
    //  * but it should never happen here because this function is explicitly called
    //  * through a user interaction
    //  */
    // if (!signedTx) {
    //   throw new Error("Could not sign transaction");
    // }

    // return await proposeTx(signedTx);
    return;
  };

  // Execute transaction
  const onExecute = async (): Promise<string | undefined> => {
    // const [, createdTx, provider] = assertDependencies();

    // // If no txId was provided, it's an immediate execution of a new tx
    // const id = txId || (await proposeTx(createdTx));
    // const txOptions = getTxOptions(advancedParams, currentChain);
    // await dispatchTxExecution(createdTx, provider, txOptions, id);

    // return id;
    return;
  };
  // On modal submit
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsSubmittable(false);
    setSubmitError(undefined);

    try {
      if (willExecute) {
        await onExecute();
      } else {
        try {
          const signatures = await onSign();
          if (transferParams && signatures) {
            transferParams.signatures = signatures;
            const payload = await getTransferpayload(transferParams);
            // workaround
            // (payload.multiSigExecutionInfo as any).message = transferParams.message;
            const result = await createTransfer({ variables: { content: payload } });
            if (result.errors || !result.data) {
              setSubmitError(new Error("Something wrong with graphql server"));
              setSubmitError(new Error("create transaction failed"));
              return;
            }
            const status = result.data.createTransfer;
            if (!status.success) {
              setSubmitError(new Error("create transaction failed"));
              throw new Error(JSON.stringify(status?.error));
            }
          }
        } catch (e) {
          console.log(e);
          const error = e as { code: number | undefined };
          if (
            error.code === TON_CONNECT_ERROR_CODE_WALLET_REJECTED ||
            error.code === TON_CONNECT_ERROR_CODE_POP_UP_CLOSED
          ) {
            setSubmitError(new Error("signing is rejected"));
          } else {
            setSubmitError(new Error("unexpected error occurred"));
          }
          return;
        }
      }
    } catch (err) {
      setIsSubmittable(true);
      setSubmitError(err as Error);
      return;
    }
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        {children}

        {/* Error messages */}
        {submitError ? (
          <ErrorMessage error={submitError}>Error submitting the transaction. Please try again.</ErrorMessage>
        ) : null}

        {/* Submit button */}
        <Button variant="contained" type="submit" disabled={disableSubmit} className={styles.button}>
          Submit
        </Button>
      </DialogContent>
    </form>
  );
};

export default SignOrExecuteForm;
