import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { List, Typography, Collapse, Button, IconButton, SvgIcon, Box, Link as MuiLink } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Add from "@mui/icons-material/Add";
import { CHAIN } from "@tonconnect/protocol";
import AddIcon from "#/images/common/add.svg";
import { AddedSafesOnChain, selectAddedSafes } from "@/store/addedSafesSlice";
import SafeListItem from "@/components/sidebar/SafeListItem";
import { Pathnames } from "@/config";
import styles from "./SafeList.module.css";
import { sameAddress } from "@/utils/addresses";
import { supportedChain } from "@/config/chains";
import { useSafeInfo, useOwnedSafes, useWallet } from "@/hooks";
import { isOwner } from "@/utils/transaction-guards";
import { useAppSelector } from "@/store";
import NotConnectedIcon from "#/images/sidebar/not-connected.png";
import { OwnerInfo, SidebarSafeInfo } from "@/types/safeInfo";

export const getSortedSafes = (
  address: string,
  addedSafesOnChain: [
    string,
    {
      name: string;
      address: string;
      chainId: CHAIN;
      walletId: number;
      threshold: number;
      owners: OwnerInfo[];
      shouldHide?: boolean | undefined;
    },
  ][],
) => {
  const sortedSafes = addedSafesOnChain.map(([, safe]) => safe) as SidebarSafeInfo[];
  let indexOfReadonlySafe = 0;
  for (let i = 0, maxI = sortedSafes.length; i < maxI; i++) {
    const { owners } = sortedSafes[i];
    const isReadOnly = !isOwner(owners, address);
    // TODO: sortedSafes is not extensible
    sortedSafes[i] = {
      ...sortedSafes[i],
      isReadOnly,
    };

    if (!isReadOnly) {
      [addedSafesOnChain[indexOfReadonlySafe], addedSafesOnChain[i]] = [
        addedSafesOnChain[i],
        addedSafesOnChain[indexOfReadonlySafe],
      ];
      indexOfReadonlySafe++;
    }
  }

  return sortedSafes;
};

const shouldExpandSafeList = ({
  isCurrentChain,
  safeAddress,
  ownedSafesOnChain,
  addedSafesOnChain,
}: {
  isCurrentChain: boolean;
  safeAddress: string;
  ownedSafesOnChain: string[];
  addedSafesOnChain: AddedSafesOnChain;
}): boolean => {
  let shouldExpand = false;

  const addedAddressesOnChain = Object.keys(addedSafesOnChain);

  if (isCurrentChain && ownedSafesOnChain.some((address) => sameAddress(address, safeAddress))) {
    // Expand the Owned Safes if the current Safe is owned, but not added
    shouldExpand = !addedAddressesOnChain.some((address) => sameAddress(address, safeAddress));
  } else {
    // Expand the Owned Safes if there are no added Safes
    shouldExpand = !addedAddressesOnChain.length && ownedSafesOnChain.length <= MAX_EXPANDED_SAFES;
  }

  return shouldExpand;
};

const MAX_EXPANDED_SAFES = 3;
const NO_SAFE_MESSAGE = "Create a new safe or add";
const { chainId, chainName } = supportedChain;

const SafeList = ({ closeDrawer }: { closeDrawer?: () => void }) => {
  const nameMap = useRef(new Map<string, string>());
  const [pinnedSafes, setPinnedSafes] = useState<SidebarSafeInfo[]>([]);
  const { pathname, push, query } = useRouter();

  const wallet = useWallet();
  const isConnected = !!wallet;
  const { safe } = useSafeInfo();
  const { ownedSafes } = useOwnedSafes();
  const addedSafes = useAppSelector(selectAddedSafes);

  const [open, setOpen] = useState<Record<string, boolean>>({});
  const toggleOpen = (chainId: string, open: boolean) => {
    setOpen((prev) => ({ ...prev, [chainId]: open }));
  };

  const ownedSafesOnChain = ownedSafes[chainId];
  const addedSafesOnChain = useMemo(() => Object.entries(addedSafes[chainId] ?? {}), [addedSafes]);
  const hasSafes = Object.keys(ownedSafes).length !== 0 || Object.keys(addedSafes).length !== 0;
  const isWelcomePage = pathname === Pathnames.Home;
  const isCurrentChain = chainId === chainId;
  const showNoSafeContent = isConnected && !hasSafes;
  const showSafeContent =
    isConnected && hasSafes && (isCurrentChain || ownedSafesOnChain.length || addedSafesOnChain.length);
  const isSafeListOpen =
    chainId in open
      ? open[chainId]
      : shouldExpandSafeList({
          isCurrentChain,
          safeAddress: safe.address,
          ownedSafesOnChain: ownedSafesOnChain.map(({ address }) => address),
          addedSafesOnChain: addedSafes[chainId] ?? {},
        });

  const onClickCreate = useCallback(() => {
    push(Pathnames.CreateSafe);
  }, [push]);

  const safesContent = (
    <>
      {/* No Safes yet */}
      {!addedSafesOnChain.length && !ownedSafesOnChain.length && (
        <Typography variant="body2" color="primary.light" p={2} textAlign="center">
          {!isWelcomePage ? (
            <Link
              href={{
                pathname: Pathnames.Home,
                query,
              }}
              passHref
            >
              <MuiLink onClick={closeDrawer}>{NO_SAFE_MESSAGE}</MuiLink>
            </Link>
          ) : (
            <>{NO_SAFE_MESSAGE}</>
          )}{" "}
          an existing one on this network
        </Typography>
      )}

      {/* Added Safes */}
      <List className={styles.list}>
        {pinnedSafes
          .filter(({ shouldHide }) => !shouldHide)
          .map((safeInfo, index) => (
            <SafeListItem key={index} safeInfo={safeInfo} closeDrawer={closeDrawer} />
          ))}
      </List>

      {/* Owned Safes */}
      {ownedSafesOnChain.length > 0 && (
        <>
          <div onClick={() => toggleOpen(chainId, !isSafeListOpen)} className={styles.ownedLabelWrapper}>
            <Typography variant="body2" display="inline" className={styles.ownedLabel}>
              All Tonkeys owned on {chainName} ({ownedSafesOnChain.length})
              <IconButton disableRipple>{isSafeListOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </Typography>
          </div>

          <Collapse key={chainId} in={isSafeListOpen}>
            <List sx={{ py: 0 }}>
              {ownedSafesOnChain.map((safeInfo, index) => (
                <SafeListItem
                  key={index}
                  safeInfo={{ ...safeInfo, name: nameMap.current.get(safeInfo.address) || "" }}
                  closeDrawer={closeDrawer}
                />
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );

  useEffect(() => {
    if (wallet) {
      const { address } = wallet;
      setPinnedSafes(getSortedSafes(address, addedSafesOnChain));
    }
  }, [addedSafesOnChain, wallet]);

  useEffect(() => {
    addedSafesOnChain.forEach(([_, safe]) => {
      const { address, name } = safe;
      nameMap.current.set(address, name || nameMap.current.get(address) || "");
    });
  }, [addedSafesOnChain]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h4" display="inline" fontWeight={700} className={styles.title}>
          My Tonkey Accounts
        </Typography>

        {!isWelcomePage && (
          <Link href={{ pathname: Pathnames.CreateSafe }} passHref>
            <Button
              disableElevation
              size="small"
              variant="outlined"
              onClick={closeDrawer}
              startIcon={<SvgIcon component={AddIcon} inheritViewBox fontSize="small" />}
            >
              Add
            </Button>
          </Link>
        )}
      </div>

      {!isConnected && (
        <div className={styles["not-connect-container"]}>
          <div className={styles["not-connect-image"]}>
            <Image src={NotConnectedIcon} alt="Not connected" layout="fill" objectFit="cover" />
          </div>
          <p className={styles["not-connect-instruction"]}>
            Connect a wallet to view your Tonkey accounts or to create a new one.
          </p>
        </div>
      )}

      {showNoSafeContent && (
        <Box display="flex" flexDirection="column" alignItems="center" py={6}>
          <Typography variant="body2" color="primary.light" textAlign="center" mt={3}>
            {isWelcomePage ? (
              <div className="mt-[40%]">
                <p className={styles["no-safe-instruction"]}>
                  Create a new Tonkey Account or add an existing one on this network.
                </p>
                <Button variant="contained" onClick={onClickCreate} className={styles["new-safe-button"]}>
                  <Add sx={{ width: "18px", height: "18px", marginRight: "8px" }} />
                  Create new Tonkey
                </Button>
              </div>
            ) : (
              <Link href={{ pathname: Pathnames.Home, query }} passHref>
                <MuiLink onClick={closeDrawer}>{NO_SAFE_MESSAGE}</MuiLink>
              </Link>
            )}
          </Typography>
        </Box>
      )}

      {showSafeContent ? safesContent : null}
    </div>
  );
};

export default SafeList;
