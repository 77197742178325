import { createContext } from "react";

const TransactionsContext = createContext(
  {} as {
    state: Tonkey.TransactionsContext.State;
    actions: Tonkey.TransactionsContext.Actions;
  },
);

export default TransactionsContext;
