import { PendingSafeData } from "@/components/new-safe/create/steps/StatusStep";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export const pendingSafeSlice = createSlice({
  name: "pendingSafe",
  initialState: null as PendingSafeData | null,
  reducers: {
    setPendingSafe: (_state, action: PayloadAction<PendingSafeData>) => action.payload,
    updatePendingSafe: (_state, action: PayloadAction<PendingSafeData>) => ({
      ...action.payload,
    }),
    clearPendingSafe: () => null,
  },
});

export const { setPendingSafe, updatePendingSafe, clearPendingSafe } = pendingSafeSlice.actions;

export const selectPendingSafe = (state: RootState): PendingSafeData | null => {
  return state[pendingSafeSlice.name];
};
