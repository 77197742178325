import { useMemo } from "react";
import isEqual from "lodash/isEqual";
import { useAppSelector } from "@/store";
import { initialBalancesState, selectBalances } from "@/store/balancesSlice";
import { Balance } from "@/types/balance";

const useBalances = (): {
  balances: Balance;
  loading: boolean;
  error?: string;
} => {
  const state = useAppSelector(selectBalances, isEqual);
  const { data, error, loading } = state;

  return useMemo(
    () => ({
      balances: data,
      error,
      loading: loading || initialBalancesState === data,
    }),
    [data, error, loading],
  );
};

export default useBalances;
