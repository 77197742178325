import { useContext, useState, useEffect } from "react";
import { TonwebContext } from "@/components/TonwebProvider";

export const useIsWalletActivated = (address: string) => {
  const [isWalletActivated, setIsWalletActivated] = useState(true);
  const tonweb = useContext(TonwebContext);

  useEffect(() => {
    if (tonweb) {
      tonweb.provider
        .call2(address, "get_public_key")
        .then(() => {
          setIsWalletActivated(true);
        })
        .catch(() => {
          setIsWalletActivated(false);
        });
    }
  }, [address, tonweb]);

  return isWalletActivated;
};
