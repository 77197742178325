import { useEffect, useState, useContext } from "react";
import type { TransactionSummary } from "@safe-global/safe-gateway-typescript-sdk";
import { TonwebContext } from "@/components/TonwebProvider";
import { useSafeInfo, useTonBalance, useBalances } from "@/hooks";
import * as ton3 from "ton3-core";
import { toUserFriendlyAddress } from "@/utils/addresses";
import TonWeb from "tonweb";
import { getTransactionFee } from "@/utils/gas";
import { TransactionInfoType, TransactionTokenType } from "@safe-global/safe-gateway-typescript-sdk";

const useFee = (txSummary: TransactionSummary) => {
  const tonweb = useContext(TonwebContext);
  const { tonBalance } = useTonBalance();
  const { safe } = useSafeInfo();
  const { balances } = useBalances();
  const [fee, setFee] = useState<number>(-Infinity);
  const [isOutOfFee, setIsOutOfFee] = useState<boolean>(false);

  useEffect(() => {
    async function handler() {
      if (tonweb && safe) {
        if (txSummary.txInfo.type !== TransactionInfoType.TRANSFER) {
          return;
        }
        const nanoFee = await getTransactionFee(txSummary.txInfo.transferInfo.type);
        const fee = parseFloat(ton3.Coins.fromNano(nanoFee).toString());
        setFee(fee);

        if (txSummary.txInfo.transferInfo.type === TransactionTokenType.NATIVE_COIN) {
          if (tonBalance && nanoFee + parseInt(txSummary.txInfo.transferInfo.value, 10) > tonBalance)
            setIsOutOfFee(true);
          else {
            setIsOutOfFee(false);
          }
        } else if (txSummary.txInfo.transferInfo.type === TransactionTokenType.ERC20) {
          const tokenAddress = txSummary.txInfo.transferInfo.tokenAddress;
          const selectedToken = tokenAddress
            ? balances.assets.find((asset) => asset.tokenInfo.jettonMinterAddress === tokenAddress)
            : undefined;
          if (!selectedToken) return;

          const selectedTokenBalance = parseInt(selectedToken.balance, 10);
          const transferAmount = parseInt(txSummary.txInfo.transferInfo.value, 10);

          if (tonBalance && nanoFee > tonBalance) setIsOutOfFee(true);
          else if (transferAmount > selectedTokenBalance) setIsOutOfFee(true);
          else {
            setIsOutOfFee(false);
          }
        }
      }
      return;
    }
    handler();
  }, [txSummary, tonweb, balances, safe, tonBalance]);

  return { fee, isOutOfFee };
};

export default useFee;
