import React, { type ReactElement } from "react";
import type { TransactionDetails } from "@safe-global/safe-gateway-typescript-sdk";
import type { Transfer } from "@safe-global/safe-gateway-typescript-sdk";
import { Operation, TransferDirection } from "@safe-global/safe-gateway-typescript-sdk";
import { Box, CircularProgress } from "@mui/material";
import TxSigners from "@/components/transactions/TxSigners";
import Summary from "@/components/transactions/TxDetails/Summary";
import TxData from "@/components/transactions/TxDetails/TxData";
import { useAsync, useWallet, useIsWrongChain, useIsSafeOwner } from "@/hooks";
import { supportedChain } from "@/config/chains";
import {
  isAwaitingExecution,
  isModuleExecutionInfo,
  isMultisigDetailedExecutionInfo,
  isMultisigExecutionInfo,
  isTxQueued,
} from "@/utils/transaction-guards";
import { InfoDetails } from "@/components/transactions/InfoDetails";
import EthHashInfo from "@/components/common/EthHashInfo";
import ErrorMessage from "@/components/tx/ErrorMessage";
import ExecuteTxButton from "@/components/transactions/ExecuteTxButton";
import SignTxButton from "@/components/transactions/SignTxButton";
import { DelegateCallWarning } from "@/components/transactions/Warning";
import styles from "./styles.module.css";

export const NOT_AVAILABLE = "n/a";

type TxDetailsProps = {
  txSummary: TransactionSummary;
  txDetails: TransactionDetails;
};

import { DetailedExecutionInfoType } from "@safe-global/safe-gateway-typescript-sdk";
import { TransactionSummary } from "@/types/transactions";

async function getTransactionDetails(chainId: string, summary: TransactionSummary): Promise<TransactionDetails> {
  let txHash,
    executedAt,
    executor,
    confirmed = false;

  if (!isTxQueued(summary.txStatus)) {
    txHash = "i7Gvt+TWa5WtqWvP2GwDBUO7pOGcgh5yAmVVAfuIBes=";
    executedAt = summary.timestamp - 3000;
    confirmed = true;
    executor = {
      value: "EQAuz15H1ZHrZ_psVrAra7HealMIVeFq0wguqlmFno1f3EJj",
      name: "Executor",
    };
  }

  const confirmations = [
    {
      signer: { value: "EQB1GOQhhKgYtUWqJDj6QAG7OHwHL7q7A1JtSpCeAtjIPfn3" },
      submittedAt: Date.now() - 67000,
    },
    {
      signer: { value: "EQBBdlMfTp9nxHGlfut77IIevOnmqPdYXyDfXalN_59A47Y3" },
      submittedAt: Date.now() - 63000,
    },
    {
      signer: { value: "EQAuz15H1ZHrZ_psVrAra7HealMIVeFq0wguqlmFno1f3EJj" },
      submittedAt: Date.now() - 61000,
    },
  ].slice(0, (summary.executionInfo as any).confirmationsSubmitted);

  return {
    safeAddress: "0xcccc",
    txId: summary.id,
    executedAt,
    txStatus: summary.txStatus,
    txInfo: summary.txInfo,
    txData: void 0 /*TransactionData*/,
    detailedExecutionInfo: /*DetailedExecutionInfo*/ {
      type: DetailedExecutionInfoType.MULTISIG,
      submittedAt: Date.now(),
      nonce: 1,
      safeTxGas: "123",
      baseGas: "123",
      gasPrice: "21",
      gasToken: "TONGAS",
      refundReceiver: { value: "0x0", name: "blackhole" },
      safeTxHash: "0xsafetxhash",
      executor,
      signers: [] /*addressEx[]*/,
      confirmationsRequired: 3,
      confirmations /*MultisigConfirmation[]*/,
      rejectors: void 0 /*AddressEx[]?*/,
      gasTokenInfo: void 0 /*TokenInfo*/,
      trusted: true,
    },
    txHash,
    safeAppInfo: void 0 /*SafeAppInfo*/,
  };
}

const TxDetailsBlock = ({ txSummary, txDetails }: TxDetailsProps): ReactElement => {
  const wallet = useWallet();
  const isWrongChain = useIsWrongChain();
  const isQueue = isTxQueued(txSummary.txStatus);
  const awaitingExecution = isAwaitingExecution(txSummary.txStatus);
  const isSafeOwner = useIsSafeOwner();
  const canModifyTx = isSafeOwner;
  const isUnsigned =
    isMultisigExecutionInfo(txSummary.executionInfo) && txSummary.executionInfo.confirmationsSubmitted === 0;

  return (
    <>
      {/* /Details */}
      <div className={`${styles.details} ${isUnsigned ? styles.noSigners : ""}`}>
        <div className={styles.txData}>
          <TxData txDetails={txDetails} />
        </div>

        {/* Module information*/}
        {isModuleExecutionInfo(txSummary.executionInfo) && (
          <div className={styles.txModule}>
            <InfoDetails title="Module:">
              <EthHashInfo
                address={txSummary.executionInfo.address.value}
                shortAddress={false}
                showCopyButton
                hasExplorer
              />
            </InfoDetails>
          </div>
        )}

        <div className={styles.txSummary}>
          {txDetails.txData?.operation === Operation.DELEGATE && (
            <div className={styles.delegateCall}>
              <DelegateCallWarning showWarning={!txDetails.txData.trustedDelegateCallTarget} />
            </div>
          )}
          <Summary txDetails={txDetails} txSummary={txSummary} />
        </div>
      </div>

      {/* Signers */}
      {!isUnsigned && (txDetails?.txInfo as Transfer)?.direction === TransferDirection.OUTGOING && (
        <div className={styles.txSigners}>
          <TxSigners txDetails={txDetails} txSummary={txSummary} />
          {wallet && !isWrongChain && isQueue && canModifyTx && (
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} mt={2} width="100%">
              {awaitingExecution ? (
                <ExecuteTxButton txSummary={txSummary} txDetails={txDetails} />
              ) : (
                <SignTxButton txSummary={txSummary} />
              )}
            </Box>
          )}
        </div>
      )}
    </>
  );
};

const TxDetails = ({
  txSummary,
  txDetails,
}: {
  txSummary: TransactionSummary;
  txDetails?: TransactionDetails; // optional
}): ReactElement => {
  const { chainId } = supportedChain;
  const [txDetailsData, error, loading] = useAsync<TransactionDetails>(
    async () => {
      return txDetails || getTransactionDetails(chainId, txSummary);
    },
    [txDetails, chainId, txSummary.id],
    false,
  );

  return (
    <div
      className={`${styles.container} ${
        (txDetailsData?.txInfo as Transfer)?.direction === TransferDirection.INCOMING ? "!block" : ""
      }`}
    >
      {txDetailsData && <TxDetailsBlock txSummary={txSummary} txDetails={txDetailsData} />}
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {error && (
        <div className={styles.error}>
          <ErrorMessage error={error}>Couldn&apos;t load the transaction details</ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default TxDetails;
