import { useEffect } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { Box, Button, Grid, SvgIcon } from "@mui/material";
import type { NewSafeFormData } from "@/components/new-safe/create";
import type { StepRenderProps } from "@/components/new-safe/CardStepper/useCardStepper";
import layoutCss from "@/components/new-safe/create/styles.module.css";
import KeyholeIcon from "#/images/common/keyhole.svg";
import useSyncSafeCreationStep from "@/components/new-safe/create/useSyncSafeCreationStep";
import { useWallet } from "@/hooks";
import { selectPendingSafe } from "@/store/pendingSafeSlice";
import { useAppSelector } from "@/store";
import { toUserFriendlyAddress } from "@/utils/addresses";
import { LoadSafeFormData } from "@/components/new-safe/load";

const ConnectWalletStep = ({ onSubmit, setStep }: StepRenderProps<NewSafeFormData | LoadSafeFormData>) => {
  useSyncSafeCreationStep(setStep);
  const pendingSafe = useAppSelector(selectPendingSafe);
  const wallet = useWallet();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if (!wallet || pendingSafe) return;

    onSubmit({
      owners: [{ address: toUserFriendlyAddress(wallet.address), name: "" }],
    });
  }, [onSubmit, wallet, pendingSafe]);

  return (
    <>
      <Box className={layoutCss.row}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box width={200} height={200} display="flex" alignItems="center" justifyContent="center">
              <SvgIcon component={KeyholeIcon} inheritViewBox sx={{ width: "100%", height: "100%", fill: "none" }} />
            </Box>

            <Button
              onClick={async () => {
                await tonConnectUI.connectWallet();
              }}
              variant="contained"
              size="stretched"
              disableElevation
              sx={{
                background: "var(--m-3-sys-light-primary)",
                textTransform: "capitalize",
                borderRadius: "8px",
                padding: "10px 24px",
                color: "var(--m-3-sys-light-on-primary)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
              }}
            >
              Connect
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ConnectWalletStep;
