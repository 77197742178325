import {
  TON_CONNECT_ERROR_CODE_POP_UP_CLOSED,
  TON_CONNECT_ERROR_CODE_WALLET_REJECTED,
} from "@/components/Web3Provider/errorCode";
import { isClientSide } from "@/utils";
import { isAddressActive } from "@/utils/addresses";
import TonWeb from "tonweb";
import { HttpProvider } from "tonweb/dist/types/providers/http-provider";
import { PendingSafeData } from "../steps/StatusStep";
import { SafeCreationStatus } from "../steps/StatusStep/useSafeCreation";
import { validateSafeByAddress } from "tonkey-gateway-typescript-sdk";
import { delay } from "@/utils";
import { CHAIN } from "@tonconnect/protocol";
import { ITonConnect } from "@tonconnect/ui-react";
import { ContractBase } from "ton3-core/dist/contracts";
import { BOC } from "ton3-core";

const toNano = TonWeb.utils.toNano;

export const createNewSafe = async (
  provider: HttpProvider,
  signerAddress: string,
  pendingSafe: PendingSafeData,
  walletsFound: boolean,
  tonconnect: ITonConnect,
): Promise<{ sentData: string }> => {
  if (
    !provider ||
    !pendingSafe ||
    !pendingSafe.address ||
    !pendingSafe.initCode ||
    !pendingSafe.initData ||
    !isClientSide() ||
    !walletsFound
  ) {
    throw new Error("unexpected error occurred");
  }

  const active = await isAddressActive(provider, pendingSafe.address);
  if (active) {
    console.log("safe is already deployed");
  }

  const contract = new ContractBase({
    workchain: 0,
    code: BOC.from(pendingSafe.initCode).root[0],
    storage: BOC.from(pendingSafe.initData).root[0],
  });
  const stateInit = new BOC([contract.state]).toString("base64");
  await tonconnect.sendTransaction({
    validUntil: new Date().getTime() + 60 * 60 * 1000,
    from: signerAddress,
    messages: [
      {
        address: contract.address.toString("base64"),
        amount: toNano("0.05").toString(),
        stateInit,
      },
    ],
  });

  return { sentData: stateInit };
};

export const handleSafeCreationError = (e: unknown) => {
  const error = e as { message: string; code: number };

  if (error.message === "safe is already deployed") {
    return SafeCreationStatus.INDEXED;
  }

  if (error.code === TON_CONNECT_ERROR_CODE_WALLET_REJECTED || error.code === TON_CONNECT_ERROR_CODE_POP_UP_CLOSED) {
    return SafeCreationStatus.WALLET_REJECTED;
  }

  return SafeCreationStatus.ERROR;
};

export const checkSafeCreationTx = async (
  chainId: CHAIN,
  provider: HttpProvider,
  pendingSafe: PendingSafeData,
  walletsFound: boolean,
): Promise<SafeCreationStatus> => {
  try {
    if (
      !provider ||
      !pendingSafe ||
      !pendingSafe.address ||
      !pendingSafe.senderAddress ||
      !pendingSafe.sentData ||
      !isClientSide() ||
      !walletsFound
    ) {
      console.log("unexpected error occurred");
      return SafeCreationStatus.ERROR;
    }

    let retryCount = 20;
    while (retryCount > 0) {
      const active = await isAddressActive(provider, pendingSafe.address);
      if (active) {
        if (await validateSafeByAddress(pendingSafe.address, chainId, process.env.NEXT_PUBLIC_GRAPHQL_URI)) {
          return SafeCreationStatus.INDEXED;
        }
      }
      await delay(5000);
      retryCount -= 1;
    }
    console.log("safe is still not active");
    return SafeCreationStatus.TIMEOUT;
  } catch (e) {
    console.log(e);
  }
  return SafeCreationStatus.ERROR;
};
