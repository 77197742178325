import { useEffect } from "react";
import { type AsyncResult } from "../useAsync";
import { useGetBalanceBySafeAddress } from "tonkey-gateway-typescript-sdk";
import useSafeAddress from "../useSafeAddress";
import { supportedChain } from "@/config/chains";
import { POLLING_INTERVAL } from "@/constants";
import { Balance } from "@/types/balance";
import { toRawAddress } from "@/utils/addresses";

const balancesCache: { [address: string]: Balance } = {};

export const useLoadBalances = (): AsyncResult<Balance> => {
  const address = useSafeAddress();
  const { chainId } = supportedChain;
  const { data, error, loading, refetch } = useGetBalanceBySafeAddress(address, chainId, POLLING_INTERVAL);

  useEffect(() => {
    if (address) {
      const rawAddress = toRawAddress(address);
      refetch({ chainId: chainId, safeAddress: rawAddress });
    }
  }, [chainId, address, refetch]);

  useEffect(() => {
    if (address && data) {
      balancesCache[address] = data;
    }
  }, [address, data]);

  return [address && balancesCache[address] ? balancesCache[address] : data, error, loading];
};

export default useLoadBalances;
