import { CHAIN } from "@tonconnect/protocol";
import { ChainInfo } from "@/types/chainInfo";

export const supportedChain: ChainInfo =
  process.env.NEXT_PUBLIC_NETWORK === "mainnet"
    ? {
        chainId: CHAIN.MAINNET,
        chainName: "Mainnet",
        network: "mainnet",
        tonCenterUrl: "https://mainnet-rpc.tonfura.com/v1/json-rpc/425c72d2-76e8-4480-9a53-9dc104de511a",
        explorerUrl: "https://tonscan.org",
        nativeCurrency: {
          name: "Toncoin",
          symbol: "TON",
          decimals: 9,
          logoUri: "",
        },
      }
    : {
        chainId: CHAIN.TESTNET,
        chainName: "Testnet",
        network: "testnet",
        tonCenterUrl: "https://testnet-rpc.tonfura.com/v1/json-rpc/d44978b1-8761-443d-b237-57f7a2b24da4",
        explorerUrl: "https://testnet.tonscan.org",
        nativeCurrency: {
          name: "Toncoin",
          symbol: "TON",
          decimals: 9,
          logoUri: "",
        },
      };
