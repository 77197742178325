import { type TransactionDetails } from "@safe-global/safe-gateway-typescript-sdk";
import { Accordion, AccordionDetails, AccordionSummary, ThemeProvider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import TxSummary from "@/components/transactions/TxSummary";
import TxDetails from "@/components/transactions/TxDetails";
import CreateTxInfo from "@/components/transactions/SafeCreationTx";
import { isCreationTxInfo } from "@/utils/transaction-guards";
import { CompactTransaction } from "@/types/transactions";
import { txTheme } from "@/components/TxTheme";
import styles from "./TxListItem.module.css";

type ExpandableTransactionItemProps = {
  isGrouped?: boolean;
  item: CompactTransaction;
  txDetails?: TransactionDetails;
};

export const ExpandableTransactionItem = ({ isGrouped = false, item, txDetails }: ExpandableTransactionItemProps) => {
  return (
    <ThemeProvider theme={txTheme}>
      <Accordion
        disableGutters
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: false,
        }}
        elevation={0}
        className={classNames(styles.accordion, { [styles.batched]: false })}
        sx={{ borderRadius: "16px !important", marginBottom: "8px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            justifyContent: "flex-start",
            overflowX: "auto",
            padding: "0 24px",
          }}
        >
          <TxSummary item={item} isGrouped={isGrouped} />
        </AccordionSummary>

        <AccordionDetails sx={{ padding: 0 }}>
          {isCreationTxInfo(item.transaction.txInfo) ? (
            <CreateTxInfo txSummary={item.transaction} />
          ) : (
            <TxDetails txSummary={item.transaction} txDetails={txDetails} />
          )}
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
};

export default ExpandableTransactionItem;
