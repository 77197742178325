import { useMemo } from "react";
import { formatCurrency } from "@/utils/formatNumber";
import styles from "./styles.module.css";

const FiatValue = ({ value }: { value: string | number }) => {
  const fiat = useMemo(() => {
    return formatCurrency(value, "usd");
  }, [value]);

  return (
    <span suppressHydrationWarning className={styles["cell-value"]}>
      {fiat}
    </span>
  );
};

export default FiatValue;
