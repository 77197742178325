import * as Sentry from "@sentry/nextjs";
import { Pathnames } from "@/config";

export const captureException = (error: unknown, samplePercentage = 1) => {
  if (samplePercentage > Math.random()) {
    Sentry.captureException(error);
  }
};

export function isClientSide(): boolean {
  return typeof window !== "undefined";
}

export function isServerSide(): boolean {
  return !isClientSide();
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const shouldHideSideDrawer = (pathname: string) =>
  [Pathnames.CreateSafe, Pathnames.LoadSafe, Pathnames.Home].includes(pathname as Pathnames);
