import { useMemo, useContext } from "react";
import { Card, Grid, SvgIcon } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
import { useWallet, useIsWalletActivated } from "@/hooks";
import { supportedChain } from "@/config/chains";
import { ChainIndicator, NewSafeContext } from "@/components";
import { SafeCreationStatus } from "../steps/StatusStep/useSafeCreation";
import logo from "#/images/header/logo.svg";
import styles from "./OverviewWidget.module.css";

interface OverviewWidgetProps {
  safeName: string;
  activeStep: number;
  status?: SafeCreationStatus;
  isLoadMode?: boolean;
}

const LOGO_DIMENSIONS = "40px";
const MAX_STEP = 5;
const [, , THIRD_STEP, FOURTH_STEP, FIFTH_STEP] = new Array(MAX_STEP).fill("").map((_element, index) => index);

const OverviewWidget = ({ safeName, activeStep, status, isLoadMode = false }: OverviewWidgetProps) => {
  const newSafeContext = useContext(NewSafeContext);
  const wallet = useWallet();
  const isWalletActivated = useIsWalletActivated(wallet?.address || "");
  const shouldShowActivateWalletSection =
    !isLoadMode &&
    activeStep === THIRD_STEP &&
    (!isWalletActivated || newSafeContext?.state?.someOwnerAddressNotActivated);
  const hasError = status
    ? status >= SafeCreationStatus.WALLET_REJECTED && status <= SafeCreationStatus.TIMEOUT
    : false;
  const isProcessing = !hasError && status !== SafeCreationStatus.INDEXED;

  const rows = useMemo(() => {
    const userFriendlyAddress = wallet?.address ? toUserFriendlyAddress(wallet.address) : "";

    return [
      ...(safeName !== "" ? [{ title: "Name", component: <p className={styles.value}>{safeName}</p> }] : []),
      ...(wallet
        ? [
            {
              title: "Wallet",
              component: (
                <p className={styles.value}>
                  {userFriendlyAddress.slice(0, 5)}.....
                  {userFriendlyAddress.slice(-4)}
                </p>
              ),
            },
          ]
        : []),
      ...(supportedChain
        ? [{ title: "Network", component: <ChainIndicator chainInfo={supportedChain} inline /> }]
        : []),
    ];
  }, [safeName, wallet]);

  const Rows = useMemo(
    () =>
      rows.map((row) => (
        <div key={row.title} className={styles.row}>
          <p className={styles.label}>{row.title}</p>
          {row.component}
        </div>
      )),
    [rows],
  );

  return (
    <Grid item xs={12}>
      {(activeStep <= THIRD_STEP || isLoadMode) && (
        <Card className={styles.card}>
          <div className={styles.header}>
            <SvgIcon component={logo} inheritViewBox sx={{ width: "60px", height: LOGO_DIMENSIONS }} />
            <h4>Your Tonkey Account Preview</h4>
          </div>

          {wallet ? (
            <div className="flex justify-center items-center flex-col gap-6 mt-10">{Rows}</div>
          ) : (
            <div className={styles.row}>
              <p>Connect a wallet to view your Tonkey accounts or to create a new one.</p>
            </div>
          )}
        </Card>
      )}

      {shouldShowActivateWalletSection && (
        <Card className={styles["hints-container"]}>
          <div className="flex justify-start items-center gap-2 mb-4">
            <InfoOutlined className="text-[#4D94E3]" />
            <h4 className={styles["hints-header"]}>How to activate a wallet?</h4>
          </div>
          <p className={styles["hints-description"]}>
            TON chain requires an active wallet for us to access its public key. The wallet state is inactive by
            default, to activate your wallet, please send any amount of TON to another account.
          </p>
        </Card>
      )}

      {activeStep >= FOURTH_STEP && isProcessing && !isLoadMode && (
        <Card className={styles["hints-container"]}>
          <div className="flex justify-start items-center gap-2 mb-4">
            <InfoOutlined className="text-[#4D94E3]" />
            <h4 className={styles["hints-header"]}>Wait for the creation</h4>
          </div>
          <p className={styles["hints-description"]}>
            As network usage varies, there might be a delay before the transaction is successfully added to the
            blockchain and recognized by our services.
          </p>
        </Card>
      )}

      {activeStep === FIFTH_STEP && hasError && (
        <Card className={styles["error-hints-container"]}>
          <div className="flex justify-start items-center gap-2 mb-4">
            <InfoOutlined className="text-[#FF5449]" />
            <h4 className={styles["error-hints-header"]}>User rejected transaction</h4>
          </div>
          <p className={styles["error-hints-description"]}>
            Your transaction failed. Please either cancel the process or attempt the transaction again.
          </p>
        </Card>
      )}
    </Grid>
  );
};

export default OverviewWidget;
