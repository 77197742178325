import type { ReactElement, ReactNode } from "react";
import styles from "./styles.module.css";

type InfoDetailsProps = {
  children?: ReactNode;
  title: string | ReactElement;
};

export const InfoDetails = ({ children, title }: InfoDetailsProps): ReactElement => (
  <div className={styles.container}>
    <span className={styles.prefix}>{title}</span>
    {children}
  </div>
);
