import { SyntheticEvent, useState } from "react";
import { useContext } from "react";
import { useRouter } from "next/router";
import { DetailedExecutionInfoType, TransactionDetails } from "@safe-global/safe-gateway-typescript-sdk";
import { Button, Tooltip, SvgIcon, Box } from "@mui/material";
import {
  useSafeInfo,
  useWallet,
  useIsWrongChain,
  useTonBalance,
  useTonProvider,
  useSafeAddress,
  useTransactionStatus,
  STATUS_LABELS,
} from "@/hooks";
import { supportedChain } from "@/config/chains";
import RocketIcon from "#/images/transactions/rocket.svg";
import IconButton from "@mui/material/IconButton";
import { TonwebContext } from "@/components/TonwebProvider";
import TonWeb from "tonweb";
import { MULTISIG_CODE_CELL, MultiSig } from "tonkey-sdk";
import * as ton3 from "ton3-core";
import { toRawAddress } from "@/utils/addresses";
import { TransactionSummary } from "@/types/transactions";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { TonContext } from "@/components/TonProvider";
import ExecuteTxModal from "@/components/tx/modals/ExecuteTxModal";
import { setPendingTx, PendingStatus } from "@/store/pendingTxsSlice";
import { useAppDispatch } from "@/store";
import { captureException } from "@/utils";
import { encode } from "@/utils/base64Url";
import { Pathnames } from "@/config";
import styles from "./styles.module.css";

const hexToBytes = TonWeb.utils.hexToBytes;

const ExecuteTxButton = ({
  txSummary,
  txDetails,
  compact = false,
}: {
  txSummary: TransactionSummary;
  txDetails?: TransactionDetails;
  compact?: boolean;
}) => {
  const router = useRouter();
  const safeAddress = useSafeAddress();
  const { safe } = useSafeInfo();
  const wallet = useWallet();
  const isWrongChain = useIsWrongChain();
  const tonweb = useContext(TonwebContext);
  const ton = useContext(TonContext);
  const dispatch = useAppDispatch();
  const { id: txNonce } = txSummary;
  const executor =
    txDetails?.detailedExecutionInfo?.type === DetailedExecutionInfoType.MULTISIG &&
    txDetails.detailedExecutionInfo.executor;
  const signers =
    txDetails?.detailedExecutionInfo?.type === DetailedExecutionInfoType.MULTISIG
      ? txDetails.detailedExecutionInfo.signers
      : [];
  const confirmations =
    txDetails?.detailedExecutionInfo?.type === DetailedExecutionInfoType.MULTISIG
      ? txDetails.detailedExecutionInfo.confirmations
      : [];
  const isPending = executor !== undefined;
  const isNext = txNonce !== undefined && txNonce;
  const [isExecuting, setIsExecuting] = useState(false);
  const { tonBalance } = useTonBalance();
  const { chainId } = supportedChain;
  const txStatus = useTransactionStatus(txSummary);
  const isDisabled = !(
    tonweb &&
    wallet &&
    !isWrongChain &&
    safe.address &&
    isNext &&
    !isPending &&
    !isExecuting &&
    tonBalance &&
    ton &&
    txStatus === STATUS_LABELS.AWAITING_EXECUTION
  );
  const [tonConnectUI] = useTonConnectUI();
  const [open, setOpen] = useState(false);
  const { requestAccounts, rawSign } = useTonProvider();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.stopPropagation();

    closeModal();
    if (!isDisabled) {
      try {
        setIsExecuting(true);

        // TODO: workaround for integration of tonconnect and openmask,
        //       check connection before deploy contract
        await tonConnectUI.getWallets();
        const multiSig = ton.open(
          MultiSig.createFromConfig(
            {
              walletId: safe.walletId,
              threshold: safe.threshold,
              owners: safe.owners
                .filter((owner) => owner.publicKey !== undefined)
                .map((owner) => ({ address: owner.address, publicKey: owner.publicKey as string })),
            },
            MULTISIG_CODE_CELL,
          ),
        );

        const accounts: string[] = await requestAccounts();
        const accountIndex = accounts.findIndex((a) => toRawAddress(a) === wallet.address);
        if (accountIndex == -1) {
          throw new Error("Connected wallet not found");
        }
        const signerAddress = accounts[accountIndex];

        const _confirmations: [Uint8Array, number][] = [];
        confirmations.map((confirmation, idx) => {
          if (confirmation.signature) {
            _confirmations.push([hexToBytes(confirmation.signature), idx]);
          }
        });

        const orderCellBoc = txSummary.id;
        const [orderCell] = ton3.BOC.from(orderCellBoc);
        await multiSig.sendExternalMessageWithSignatures(
          signers.findIndex((signer) => toRawAddress(signer.value) === wallet.address),
          orderCell,
          _confirmations,
          async (orderCellHash) => {
            const signature = await rawSign([
              {
                data: orderCellHash,
              },
              signerAddress,
            ]);
            return signature;
          },
        );
        dispatch(setPendingTx({ txId: txNonce, chainId, status: PendingStatus.PROCESSING }));
        if (txDetails) {
          router.push({
            pathname: Pathnames.TransactionDetail,
            query: {
              safe: safeAddress,
              id: encode(txDetails.txHash || txDetails.txId),
            },
          });
        }
      } catch (error) {
        console.error(error);
        captureException(error);
        setIsExecuting(false);
      }
    }
  };

  return (
    <>
      {compact ? (
        <Tooltip title="Execute" arrow placement="top">
          <span>
            <IconButton color="primary" disabled={isDisabled} size="small">
              <SvgIcon component={RocketIcon} inheritViewBox fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
            <div className="h-2" />
            <Button
              onClick={openModal}
              variant="contained"
              disabled={isDisabled}
              size="stretched"
              className={`${styles.button} ${isDisabled ? "" : "!bg-[#0060a9]"}`}
            >
              Execute
            </Button>

            {open && (
              <form onSubmit={onSubmit}>
                <ExecuteTxModal onClose={closeModal} initialData={[txSummary]} />
              </form>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default ExecuteTxButton;
