import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import ModalDialog from "@/components/common/ModalDialog";
import NameInput from "@/components/common/NameInput";
import { supportedChain } from "@/config/chains";
import { useAppDispatch } from "@/store";
import { addOrUpdateSafe, editName } from "@/store/addedSafesSlice";
import { ChainIndicator } from "@/components/common";
import styles from "./EntryDialog.module.css";
import { SafeInfo } from "tonkey-gateway-typescript-sdk";

export type AddressEntry = {
  name: string;
};

interface EntryDialogProps {
  safeInfo: SafeInfo;
  handleClose: () => void;
  disableAddressInput?: boolean;
  isOpen: boolean;
  isAdded: boolean;
}

const EntryDialog = ({ safeInfo, handleClose, isOpen, isAdded }: EntryDialogProps) => {
  const { name = "" } = safeInfo;
  const dispatch = useAppDispatch();

  const methods = useForm<AddressEntry>({
    defaultValues: {
      name,
    },
    mode: "onChange",
  });

  const { handleSubmit, formState } = methods;

  const onSubmit = (data: AddressEntry) => {
    if (isAdded) {
      dispatch(
        addOrUpdateSafe({
          safe: {
            ...safeInfo,
            name: data.name,
          },
        }),
      );
    } else {
      dispatch(
        editName({
          safe: {
            ...safeInfo,
            name: data.name,
          },
        }),
      );
    }

    handleClose();
  };

  return (
    <ModalDialog open={isOpen} onClose={handleClose} dialogTitle="Edit entry" hideChainIndicator>
      <FormProvider {...methods}>
        <section>
          <div className="flex justify-between items-start px-6 mb-6">
            <p className={styles.label}>Network</p>
            <ChainIndicator chainInfo={supportedChain} inline />
          </div>
          <div className="flex justify-between items-start px-6">
            <p className={styles.label}>Address</p>
            <p className={styles.address}>Address</p>
          </div>
        </section>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={2}>
              <NameInput label="Name" autoFocus name="name" required />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} className={styles["cancel-button"]}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              disableElevation
              className={styles["save-button"]}
              sx={{
                background: `var(--m-3-sys-light-primary, #0060A9) ${!formState.isValid ? "" : "!important"}`,
                color: `var(--m-3-sys-light-on-primary) ${!formState.isValid ? "" : "!important"}`,
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default EntryDialog;
