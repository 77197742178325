import type { ReactElement } from "react";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import { type IdenticonProps, Identicon } from "@/components";

interface ThresholdProps {
  threshold: number | string;
  owners: number | string;
}
const Threshold = ({ threshold, owners }: ThresholdProps): ReactElement => (
  <Box className={styles.threshold} sx={{ color: ({ palette }) => palette.static.main }}>
    {threshold}/{owners}
  </Box>
);

interface SafeIconProps extends IdenticonProps {
  threshold?: ThresholdProps["threshold"];
  owners?: ThresholdProps["owners"];
  size?: number;
}

const SafeIcon = ({ address, threshold, owners, size }: SafeIconProps): ReactElement => (
  <div className={styles.container}>
    {threshold && owners ? <Threshold threshold={threshold} owners={owners} /> : null}
    <Identicon address={address} size={size} />
  </div>
);

export default SafeIcon;
