import { createSelector } from "@reduxjs/toolkit";
import { makeLoadableSlice } from "./common";
import { Balance, TokenInfo } from "@/types/balance";

export const initialBalancesState: Balance = {
  assets: [],
  fiatTotal: "",
};

const { slice, selector } = makeLoadableSlice("balances", initialBalancesState);

export const balancesSlice = slice;
export const selectBalances = selector;

export const selectTokens = createSelector(selectBalances, (balancesState): TokenInfo[] =>
  balancesState.data.assets.map(({ tokenInfo }) => tokenInfo),
);
