import { Box, Divider, Button, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useMnemonicSafeName, useIsWrongChain } from "@/hooks";
import type { StepRenderProps } from "@/components/new-safe/CardStepper/useCardStepper";
import type { NewSafeFormData } from "@/components/new-safe/create";
import useSyncSafeCreationStep from "@/components/new-safe/create/useSyncSafeCreationStep";
import NameInput from "@/components/common/NameInput";
import layoutCss from "@/components/new-safe/create/styles.module.css";
import { InfoIcon } from "@/components";
import NextButton from "../../NextButton";
import styles from "./styles.module.css";

type SetNameStepForm = {
  name: string;
};

enum SetNameStepFields {
  name = "name",
}

const SET_NAME_STEP_FORM_ID = "create-safe-set-name-step-form";

function SetNameStep({
  data,
  onSubmit,
  setStep,
  setSafeName,
}: StepRenderProps<NewSafeFormData> & { setSafeName: (name: string) => void }) {
  const fallbackName = useMnemonicSafeName();
  useSyncSafeCreationStep(setStep);
  const isWrongChain = useIsWrongChain();

  const formMethods = useForm<SetNameStepForm>({
    mode: "all",
    defaultValues: {
      [SetNameStepFields.name]: data.name,
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = formMethods;

  const onFormSubmit = (data: Pick<NewSafeFormData, "name">) => {
    const name = data.name || fallbackName;
    setSafeName(name);
    onSubmit({ ...data, name });
  };

  const disabled = !isValid || isWrongChain;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onFormSubmit)} id={SET_NAME_STEP_FORM_ID}>
        <Box className={styles["input-container"]}>
          <NameInput
            name={SetNameStepFields.name}
            label={errors?.[SetNameStepFields.name]?.message || "Name"}
            placeholder={fallbackName}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InfoIcon
                  tooltipMessage="This locally stored name won't be shared with us or third parties."
                  isOutlined
                />
              ),
            }}
          />

          <p className={styles.description}>
            By continuing, you agree to our{" "}
            <a className={styles.anchor} rel="noreferrer">
              terms of use
            </a>{" "}
            and{" "}
            <a className={styles.anchor} rel="noreferrer">
              privacy policy
            </a>
            .
          </p>
        </Box>

        <Divider className="!border-[#C3C6CF]" />

        <Box className={`${layoutCss.row} !pr-6`}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" gap={3}>
            <NextButton disabled={disabled} text="Next" />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

export default SetNameStep;
