import Link from "next/link";
import { useGetBalanceBySafeAddress } from "tonkey-gateway-typescript-sdk";
import { Coins } from "ton3-core";
import { ListItemButton, ListItemText, ListItem, ListItemIcon, Box, SvgIcon } from "@mui/material";
import { SafeIcon, SafeListItemSecondaryAction } from "@/components";
import { Pathnames } from "@/config";
import { supportedChain } from "@/config";
import { shortenAddress } from "@/utils";
import { SidebarSafeInfo } from "@/types/safeInfo";
import eyeIcon from "#/images/sidebar/eye.svg";
import styles from "./styles.module.css";

const { symbol: TON } = supportedChain.nativeCurrency;

interface SafeListItemProps {
  safeInfo: SidebarSafeInfo;
  closeDrawer?: () => void;
}

const SafeListItem = ({ safeInfo, closeDrawer }: SafeListItemProps) => {
  const { address, chainId, name = "", threshold, owners, isReadOnly } = safeInfo;
  const { data } = useGetBalanceBySafeAddress(address, chainId);
  const tonBalance = data?.assets?.find((asset) => asset.tokenInfo.tokenSymbol === TON)?.balance || 0;
  const balance = Coins.fromNano(tonBalance);

  return (
    <ListItem
      className={styles.container}
      disablePadding
      secondaryAction={
        <Box display="flex" alignItems="center" gap={1}>
          <SafeListItemSecondaryAction safeInfo={safeInfo} />
        </Box>
      }
    >
      <Link
        href={{
          pathname: Pathnames.Balances,
          query: { safe: `${address}`, chain: `${chainId}` },
        }}
        passHref
      >
        <ListItemButton key={address} onClick={closeDrawer} selected={false} className={styles.safe}>
          <ListItemIcon>
            <SafeIcon address={address} threshold={threshold} owners={owners.length} />
          </ListItemIcon>
          <ListItemText
            className="flex-1 !px-0 mr-4"
            sx={{ pr: 10 }}
            primaryTypographyProps={{
              variant: "body2",
              component: "div",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: isReadOnly
                ? "var(--m-3-ref-neutral-variant-neutral-variant-80, #C3C6CF)"
                : "var(--m-3-sys-light-on-secondary-container)",
            }}
            secondaryTypographyProps={{ component: "div", color: "primary" }}
            primary={name}
            secondary={
              <p className={`${styles.address} ${isReadOnly ? "!text-[#C3C6CF]" : ""}`}>{shortenAddress(address)}</p>
            }
          />

          <ListItemText
            className="!px-0 mr-4 text-right"
            primaryTypographyProps={{
              variant: "body2",
              component: "div",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              sx: {
                color: "var(--m-3-sys-light-on-secondary-container)",
                fontFamily: "Roboto",
                fontSize: "11px",
                fontsStyle: "normal",
                fontweight: "500",
                lineHeight: "16px",
                letterSpacing: "0.5px",
              },
            }}
            primary={
              isReadOnly ? (
                <div className="flex justify-end items-center gap-2">
                  <SvgIcon component={eyeIcon} sx={{ width: "16px", height: "16px" }} inheritViewBox />
                  <p>Read only</p>
                </div>
              ) : (
                `${balance} ${TON}`
              )
            }
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default SafeListItem;
