import { useContext, useState } from "react";
import type { Palette } from "@mui/material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { TransactionStatus } from "@safe-global/safe-gateway-typescript-sdk";
import DateTime from "@/components/common/DateTime";
import TxInfo from "@/components/transactions/TxInfo";
import { useTransactionStatus, useIsPending } from "@/hooks";
import { isMultisigExecutionInfo, isTxQueued } from "@/utils/transaction-guards";
import TxType from "@/components/transactions/TxType";
import { TransactionsContext, Checkbox } from "@/components";
import { CompactTransaction } from "@/types/transactions";
import styles from "./TxSummary.module.css";
import TxValue from "../TxValue";

const getStatusColor = (value: TransactionStatus, palette: Palette) => {
  switch (value) {
    case TransactionStatus.SUCCESS:
      return "#4D94E3";
    case TransactionStatus.CANCELLED:
      return "#909094";
    case TransactionStatus.FAILED:
    case TransactionStatus.AWAITING_CONFIRMATIONS:
    case TransactionStatus.AWAITING_EXECUTION:
      return "#FF897D";
    default:
      return palette.primary.main;
  }
};

type TxSummaryProps = {
  isGrouped?: boolean;
  item: CompactTransaction;
};

const TxSummary = ({ item, isGrouped }: TxSummaryProps) => {
  const {
    state: { selectedTransactions, isInDetailPage },
    actions: { setSelectedTransactions },
  } = useContext(TransactionsContext);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const { transaction: tx, transactionDetails } = item;
  const txStatusLabel = useTransactionStatus(tx);

  const { id, txStatus, executionInfo, txInfo, timestamp } = tx;
  const isPending = useIsPending(id);
  const isQueue = isTxQueued(txStatus);
  const nonce = isMultisigExecutionInfo(executionInfo) ? executionInfo.nonce : undefined;

  const onClickCheckbox = (key: string) => {
    const selectedIndex = selectedTransactions.indexOf(key);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTransactions, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTransactions.slice(1));
    } else if (selectedIndex === selectedTransactions.length - 1) {
      newSelected = newSelected.concat(selectedTransactions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTransactions.slice(0, selectedIndex),
        selectedTransactions.slice(selectedIndex + 1),
      );
    }

    setSelectedTransactions(newSelected);
  };

  return (
    <Box
      className={`${styles.gridContainer} ${
        isQueue
          ? nonce && !isGrouped
            ? styles.columnTemplate
            : styles.columnTemplateWithoutNonce
          : styles.columnTemplateTxHistory
      }`}
      id={id}
      onMouseOver={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
    >
      {(isMouseOver || selectedTransactions.length > 0) && !isInDetailPage && (
        <Checkbox
          checked={
            selectedTransactions.includes(id) || selectedTransactions.includes(transactionDetails?.txHash as string)
          }
          onClick={(event) => {
            onClickCheckbox(id || (transactionDetails?.txHash as string));
            event.stopPropagation();
          }}
        />
      )}

      <Box gridArea="type" className={styles.columnWrap}>
        <TxType tx={tx} />
      </Box>

      <Box gridArea="info" className={styles.columnWrap}>
        <TxInfo info={txInfo} />
      </Box>

      <Box gridArea="value" className={styles.columnWrap}>
        <TxValue info={txInfo} />
      </Box>

      <Box gridArea="date">
        <DateTime value={timestamp} forceShowTime />
      </Box>

      <Box
        gridArea="status"
        marginLeft={{ sm: "auto" }}
        marginRight={1}
        display="flex"
        alignItems="center"
        gap={1}
        color={({ palette }) => getStatusColor(txStatus, palette)}
      >
        {isPending && <CircularProgress size={14} color="inherit" />}

        <Typography variant="caption" fontWeight="bold" color={({ palette }) => getStatusColor(txStatus, palette)}>
          {txStatusLabel}
        </Typography>
      </Box>
    </Box>
  );
};

export default TxSummary;
