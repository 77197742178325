import { shortenAddress } from "@/utils";
import CopyButton from "../CopyButton";
import Identicon from "../Identicon";
import AccountExplorerLink from "../AccountExplorerLink";
import styles from "./Profile.module.css";
interface ProfileProps {
  name?: string;
  address: string;
  avatarSize?: number;
  classOfName?: string;
  shortAddress?: boolean;
  showAvatar?: boolean;
}

const Profile = ({
  name,
  address,
  avatarSize = 40,
  classOfName,
  shortAddress = false,
  showAvatar = true,
}: ProfileProps) => (
  <div className={styles.container}>
    {showAvatar && <Identicon address={address} size={avatarSize} />}
    <div>
      {name && <p className={`${styles.name} ${classOfName}`}>{name}</p>}
      <div className="flex justify-start items-center gap-2">
        <p className={styles.address}>{shortAddress ? shortenAddress(address) : address}</p>
        <CopyButton text={address} />
        <AccountExplorerLink address={address} />
      </div>
    </div>
  </div>
);

export default Profile;
