import { type ReactElement } from "react";
import { ExplorerButton } from "@safe-global/safe-react-components";
import { supportedChain } from "@/config/chains";

const ExplorerLink = ({ address }: { address: string }): ReactElement | null => {
  const link = supportedChain ? `${supportedChain.explorerUrl}/address/${[address]}` : undefined;

  if (!link) return null;

  return <ExplorerButton href={link} title="View on explorer" />;
};

export default ExplorerLink;
