import { useCallback, useEffect, useState } from "react";
import { Box, Button, Divider, Paper, Tooltip, Typography } from "@mui/material";
import { useRouter } from "next/router";
import StatusMessage from "@/components/new-safe/create/steps/StatusStep/StatusMessage";
import StatusStepper from "@/components/new-safe/create/steps/StatusStep/StatusStepper";
import type { NewSafeFormData } from "@/components/new-safe/create";
import type { StepRenderProps } from "@/components/new-safe/CardStepper/useCardStepper";
import { useSafeCreation, SafeCreationStatus } from "@/components/new-safe/create/steps/StatusStep/useSafeCreation";
import layoutCss from "@/components/new-safe/create/styles.module.css";
import { clearPendingSafe, selectPendingSafe } from "@/store/pendingSafeSlice";
import { useAppDispatch, useAppSelector } from "@/store";
import { Pathnames } from "@/config";
import { selectAddedSafes } from "@/store/addedSafesSlice";
import { useWallet, useIsWrongChain } from "@/hooks";
import { updateAddressBook } from "@/components/new-safe/create/logic/address-book";
import styles from "./styles.module.css";
import NextButton from "../../NextButton";
import BackButton from "../../BackButton";

export type PendingSafeData = NewSafeFormData & {
  rawAddress: string;
  initCode?: string;
  initData?: string;
  senderAddress?: string;
  sentData?: string;
};

export const CreateSafeStatus = ({
  setProgressColor,
  status = SafeCreationStatus.AWAITING,
  setStatus = () => {},
}: StepRenderProps<NewSafeFormData>) => {
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pendingSafe = useAppSelector(selectPendingSafe);
  const addedSafes = useAppSelector(selectAddedSafes);
  const { createSafe } = useSafeCreation(status, setStatus);
  const wallet = useWallet();
  const isWrongChain = useIsWrongChain();
  const isConnected = wallet && !isWrongChain;

  const onClose = useCallback(() => {
    dispatch(clearPendingSafe());
    router.push(Pathnames.Home);
  }, [dispatch, router]);

  const onCreate = useCallback(() => {
    setStatus(SafeCreationStatus.AWAITING);
    createSafe();
  }, [createSafe, setStatus]);

  const onFinish = useCallback(() => {
    if (pendingSafe) {
      const { address } = pendingSafe;
      router.push(`${Pathnames.Balances}?safe=${address}`);
    } else {
      console.log("unexpected error occurred");
      router.push(Pathnames.Home);
    }
  }, [router, pendingSafe]);

  const displaySafeLink = status >= SafeCreationStatus.INDEXED;
  const isError = status >= SafeCreationStatus.WALLET_REJECTED && status <= SafeCreationStatus.TIMEOUT;

  useEffect(() => {
    const handler = async () => {
      if (!isConnected || !pendingSafe || !pendingSafe.address || isUploading) {
        return;
      }

      if (status === SafeCreationStatus.INDEXED) {
        if (!addedSafes || !addedSafes[wallet.chain] || !addedSafes[wallet.chain][pendingSafe.address]) {
          setIsUploading(true);
          try {
            dispatch(
              updateAddressBook(
                wallet.chain,
                pendingSafe.address,
                pendingSafe.name,
                pendingSafe.walletId,
                pendingSafe.owners,
                pendingSafe.threshold,
              ),
            );
            console.log("safe is added");
          } catch (e) {
            console.log(e);
            setStatus(SafeCreationStatus.ERROR);
          }
          setIsUploading(false);
        } else {
          dispatch(clearPendingSafe());
          console.log("safe is already added");
        }
      }
    };
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedSafes, dispatch, isConnected, pendingSafe, status, wallet]);

  useEffect(() => {
    if (setProgressColor) {
      setProgressColor("white");
    }
  }, [setProgressColor]);

  return (
    <Paper
      sx={{
        textAlign: "center",
      }}
    >
      <Box className={layoutCss.row}>
        <StatusMessage status={status} isError={isError} />
      </Box>

      {!isError && pendingSafe && (
        <>
          <Divider />
          <Box className={layoutCss.row}>
            <StatusStepper pendingSafe={pendingSafe} status={status} />
          </Box>
        </>
      )}

      {displaySafeLink && (
        <>
          <Divider />
          <Box className={`${layoutCss.row} !px-6`}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3}>
              <Button onClick={onClose} variant="outlined" className={styles["back-button"]}>
                Return to dashboard
              </Button>
              <NextButton text="Browse" onClick={onFinish} />
            </Box>
          </Box>
        </>
      )}

      {isError && (
        <>
          <Divider />
          <Box className={`${layoutCss.row} !px-6`}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3}>
              <BackButton onClick={onClose} text="Cancel" />
              <Tooltip title={!isConnected ? "Please make sure your wallet is connected on the correct network." : ""}>
                <Typography display="flex" height={1}>
                  <NextButton text="Retry" onClick={onCreate} disabled={!isConnected} />
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </>
      )}
    </Paper>
  );
};
