import { useState, useEffect } from "react";
import { SUPPORT_RAW_SIGN_WALLETS } from "@/config/constants";
import useWallet from "./useWallet";

const [OPEN_MASK, MY_TON_WALLET] = SUPPORT_RAW_SIGN_WALLETS;

enum WalletProviders {
  OpenMask = "openmask",
  MyTonWallet = "myTonWallet",
}

class MyTonWalletkProvider {
  async switchChain(nextChain: string) {}

  async requestAccounts() {
    return await window[WalletProviders.MyTonWallet].send("ton_requestAccounts");
  }

  async rawSign(payload: any[]) {
    return await window[WalletProviders.MyTonWallet].send("ton_rawSign", payload);
  }
}

class OpenMaskProvider {
  async switchChain(nextChain: string) {
    await window[WalletProviders.OpenMask].provider.send("wallet_switchChain", nextChain);
  }

  async requestAccounts() {
    return await window[WalletProviders.OpenMask].provider.send("ton_requestAccounts");
  }

  async rawSign(payload: any[]) {
    return await window[WalletProviders.OpenMask].provider.send("ton_rawSign", payload);
  }
}

const defaultMethod = () => {};
export const useTonProvider = () => {
  const [walletsFound, setWalletsFound] = useState<boolean>(false);
  const [tonProvider, setTonProvider] = useState<
    InstanceType<typeof OpenMaskProvider> | InstanceType<typeof MyTonWalletkProvider>
  >({
    requestAccounts: defaultMethod,
    rawSign: defaultMethod,
  } as any);
  const connectedWallet = useWallet();
  const { requestAccounts, rawSign } = tonProvider;

  useEffect(() => {
    setWalletsFound(!!(window[WalletProviders.OpenMask] || window[WalletProviders.MyTonWallet]));
  }, []);

  useEffect(() => {
    if (connectedWallet) {
      switch (connectedWallet.name) {
        case OPEN_MASK:
          setTonProvider(new OpenMaskProvider());
          break;
        case MY_TON_WALLET:
          setTonProvider(new MyTonWalletkProvider());
          break;
      }
    }
  }, [connectedWallet]);

  return {
    walletsFound,
    requestAccounts,
    rawSign,
  };
};
