import React from "react";
import { Tooltip as MuiTooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { InfoOutlined, InfoRounded } from "@mui/icons-material";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "transparent",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    background: "#2F3033",
    boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
    marginBottom: "4px !important",
    padding: "4px 8px",
    color: "#F1F0F4",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
  },
}));

interface InfoIconProps {
  size?: string;
  tooltipMessage?: string;
  isOutlined?: boolean;
}

const InfoIcon = ({ size = "24px", tooltipMessage = "", isOutlined = false }: InfoIconProps) => (
  <Tooltip
    title={tooltipMessage}
    enterTouchDelay={0}
    placement="top"
    PopperProps={{ sx: { bottom: 0 } }}
    leaveTouchDelay={3500}
  >
    {isOutlined ? (
      <InfoOutlined style={{ width: size, height: size }} className="text-[#43474E] hover:text-[#43474E]/10" />
    ) : (
      <InfoRounded style={{ width: size, height: size }} className="text-[#C3C6CF] hover:text-[#0060A9]" />
    )}
  </Tooltip>
);

export default InfoIcon;
