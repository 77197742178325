import { useContext } from "react";
import { Button, Grid, Typography, Divider, Box, SvgIcon } from "@mui/material";
import TonWeb from "tonweb";
import { MultiSig, MULTISIG_CODE_CELL } from "tonkey-sdk";
import type { StepRenderProps } from "@/components/new-safe/CardStepper/useCardStepper";
import type { NewSafeFormData } from "@/components/new-safe/create";
import layoutCss from "@/components/new-safe/create/styles.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReviewRow from "@/components/new-safe/ReviewRow";
import useSyncSafeCreationStep from "@/components/new-safe/create/useSyncSafeCreationStep";
import { useIsWrongChain } from "@/hooks";
import { supportedChain } from "@/config/chains";
import { useAppDispatch } from "@/store";
import { setPendingSafe } from "@/store/pendingSafeSlice";
import { TonwebContext } from "@/components/TonwebProvider";
import { getRandomInt } from "@/utils/getRandomInt";
import { TonContext } from "@/components/TonProvider";
import CopyButton from "@/components/common/CopyButton";
import AccountExplorerLink from "@/components/common/AccountExplorerLink";
import { ChainIndicator, Identicon } from "@/components";
import toncoin from "#/images/status-stepper/toncoin.svg";
import styles from "./styles.module.css";
import commonStyles from "../styles.module.css";
import NextButton from "../../NextButton";
import BackButton from "../../BackButton";

// replace static fee later
const FEE = 0.05;
const {
  utils: { bytesToHex },
} = TonWeb;

const ReviewStep = ({ data, onSubmit, onBack, setStep }: StepRenderProps<NewSafeFormData>) => {
  useSyncSafeCreationStep(setStep);
  const isWrongChain = useIsWrongChain();
  const dispatch = useAppDispatch();
  const tonweb = useContext(TonwebContext);
  const ton = useContext(TonContext);

  const handleBack = () => {
    onBack(data);
  };

  const createSafe = async () => {
    if (!supportedChain || !tonweb || !ton) {
      return;
    }
    const walletId = getRandomInt(0, 65535);

    const multiSig = ton.open(
      MultiSig.createFromConfig(
        {
          walletId,
          threshold: data.threshold,
          owners: data.owners
            .filter((owner) => owner.publicKey !== undefined)
            .map((owner) => ({ address: owner.address, publicKey: owner.publicKey as string })),
        },
        MULTISIG_CODE_CELL,
      ),
    );

    try {
      const _address = await multiSig.address;
      const address = _address.toString();
      const rawAddress = _address.toRawString();
      dispatch(
        setPendingSafe({
          ...data,
          walletId,
          address,
          rawAddress,
          initCode: bytesToHex(multiSig.init!.code.toBoc()),
          initData: bytesToHex(multiSig.init!.data.toBoc()),
        }),
      );
      onSubmit({ ...data });
    } catch (e) {
      console.log(e);
      return;
    }
  };

  return (
    <>
      <Box className={layoutCss.row}>
        <Grid container spacing={3}>
          <ReviewRow name="Name" value={<Typography className={styles.value}>{data.name}</Typography>} />
          <ReviewRow name="Network" value={<ChainIndicator chainInfo={supportedChain} inline />} />
          <ReviewRow
            name="Threshold"
            value={
              <Typography className={styles.value}>
                {data.threshold} out of {data.owners.length} owner(s)
              </Typography>
            }
          />
          <ReviewRow
            name="Owners"
            value={
              <Box className={styles.ownersArray}>
                {data.owners.map(({ name, address }, index) => (
                  <div key={index} className="flex justify-start items-center gap-2">
                    <Identicon address={address} size={40} />
                    <div className="w-full flex justify-between items-center">
                      <div>
                        {name && <p className={styles["owner-name"]}>{name}</p>}
                        <p className={styles["owner-address"]}>{address}</p>
                      </div>
                      <div className="flex justify-center items-center gap-2">
                        <CopyButton text={address} />
                        <AccountExplorerLink address={address} />
                      </div>
                    </div>
                  </div>
                ))}
              </Box>
            }
          />
        </Grid>
      </Box>

      <Divider />
      <Box className={layoutCss.row}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <p className={styles.label}>Est. network fee</p>
          </Grid>
          <Grid item xs={9}>
            <div className={styles["fee-container"]}>
              <SvgIcon component={toncoin} inheritViewBox fontSize="small" />≈ {FEE} TON
            </div>
            <div className={styles["fee-description"]}>
              You will have to confirm a transaction with your connected wallet.
            </div>
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <Box className={`${layoutCss.row} !px-6`}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3}>
          <BackButton onClick={handleBack} text="Back" />
          <NextButton disabled={isWrongChain} text="Next" onClick={createSafe} />
        </Box>
      </Box>
    </>
  );
};

export default ReviewStep;
