import { useEffect } from "react";
import type { StepRenderProps } from "@/components/new-safe/CardStepper/useCardStepper";
import type { NewSafeFormData } from "@/components/new-safe/create/index";
import { useAppSelector } from "@/store";
import { selectPendingSafe } from "@/store/pendingSafeSlice";
import { useWallet } from "@/hooks";

const useSyncSafeCreationStep = (setStep: StepRenderProps<NewSafeFormData>["setStep"]) => {
  const pendingSafe = useAppSelector(selectPendingSafe);
  const wallet = useWallet();

  useEffect(() => {
    if (!wallet) {
      setStep(0);
      return;
    }

    // Jump to the status screen if there is already a tx submitted
    if (pendingSafe) {
      setStep(4);
    }
  }, [wallet, setStep, pendingSafe]);
};

export default useSyncSafeCreationStep;
