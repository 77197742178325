export enum Pathnames {
  Home = "/",
  CreateSafe = "/new-safe/create",
  LoadSafe = "/new-safe/load",
  TransactionQueue = "/transactions/queue",
  TransactionHistory = "/transactions/history",
  TransactionDetail = "/transactions/tx",
  AddressBook = "/address-book",
  Balances = "/balances",
  Settings = "/settings/setup",
}
