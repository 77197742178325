import { ReactElement, useContext, useCallback, useRef, useState, useEffect } from "react";
import { InputAdornment, TextField, type TextFieldProps, CircularProgress, Grid } from "@mui/material";
import { useFormContext, useWatch, type Validate, get } from "react-hook-form";
import { useDebounce } from "@/hooks";
import { TonwebContext } from "@/components/TonwebProvider";
import { NewSafeContext } from "@/components";
import { parseAddress } from "@/utils/addresses";

export type AddressInputProps = TextFieldProps & {
  name: string;
  validate?: Validate<string>;
  checkPublicKey?: boolean;
  deps?: string | string[];
};

const publicKeyCache: { [address: string]: Promise<BigInt> } = {};
const defaultFunction = () => {};
const AddressInput = ({
  name,
  validate,
  required = true,
  deps,
  checkPublicKey,
  ...props
}: AddressInputProps): ReactElement => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const rawValueRef = useRef<string>("");
  const watchedValue = useWatch({ name, control });
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState(true);
  const newSafeContext = useContext(NewSafeContext);
  const setSomeOwnerAddressNotActivated = newSafeContext?.actions?.setSomeOwnerAddressNotActivated || defaultFunction;

  const label = `${props.label} address`;

  const fieldError = get(errors, name);

  let error = useDebounce(fieldError, 500);

  const tonweb = useContext(TonwebContext);

  const getPublicKey = useCallback(
    async (address: string) => {
      if (address in publicKeyCache) {
        return publicKeyCache[address];
      }
      if (!tonweb) {
        return null;
      }
      publicKeyCache[address] = tonweb.provider.call2(address, "get_public_key");
      return publicKeyCache[address];
    },
    [tonweb],
  );

  useEffect(() => {
    return () => {
      if (!isActivated) {
        setSomeOwnerAddressNotActivated(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivated]);

  return (
    <Grid container alignItems="center" gap={1}>
      <Grid item flexGrow={1}>
        <TextField
          {...props}
          autoComplete="off"
          label={<>{error?.message || label}</>}
          error={!!error}
          fullWidth
          spellCheck={false}
          InputProps={{
            ...(props.InputProps || {}),

            endAdornment: isValidating && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            ...(props.InputLabelProps || {}),
            shrink: !!watchedValue || props.focused,
          }}
          required={required}
          {...register(name, {
            deps,

            required,

            setValueAs: (value: string): string => {
              rawValueRef.current = value;
              // This also checksums the address
              return value;
            },

            // validate function for later
            validate: async () => {
              const value = rawValueRef.current;
              if (value) {
                setIsValidating(true);

                try {
                  parseAddress(value);
                } catch (e) {
                  console.log(e);
                  setIsValidating(false);
                  return "Invalid addresses format";
                }

                if (checkPublicKey && name.indexOf("address") !== -1) {
                  try {
                    const publicKey = await getPublicKey(value);
                    if (!publicKey) {
                      setIsValidating(false);
                      setIsActivated(false);
                      setSomeOwnerAddressNotActivated(true);
                      return "Network error occurred, please try again later";
                    }
                    const publicKeyHex = publicKey.toString(16);

                    setValue(name.replace("address", "publicKey"), publicKeyHex);
                  } catch (e) {
                    console.log(e);
                    setIsValidating(false);
                    setIsActivated(false);
                    setSomeOwnerAddressNotActivated(true);
                    return "Please use the address of an active wallet";
                  }
                }

                const result = await validate?.(value);
                setIsValidating(false);
                return result;
              }
            },

            // Workaround for a bug in react-hook-form that it restores a cached error state on blur
            onBlur: () => setTimeout(() => trigger(name), 100),
          })}
          // Workaround for a bug in react-hook-form when `register().value` is cached after `setValueAs`
          // Only seems to occur on the `/load` route
          value={watchedValue}
        />
      </Grid>
    </Grid>
  );
};

export default AddressInput;
