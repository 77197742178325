import React from "react";
import { TransferDirection } from "@safe-global/safe-gateway-typescript-sdk";
import type { TransactionStatus, Transfer } from "@safe-global/safe-gateway-typescript-sdk";
import { Profile } from "@/components";
import { isTxQueued } from "@/utils/transaction-guards";
import styles from "./styles.module.css";

type TransferTxInfoProps = {
  txInfo: Transfer;
  txStatus: TransactionStatus;
};

const TransferTxInfoSummary = ({ txInfo: { direction }, txStatus }: TransferTxInfoProps) => (
  <p className={styles.header}>
    {direction === TransferDirection.INCOMING ? "RECEIVED FROM" : isTxQueued(txStatus) ? "SEND TO" : "SENT TO"}
  </p>
);

const TransferTxInfo = ({ txInfo, txStatus }: TransferTxInfoProps) => {
  const { direction, sender, recipient } = txInfo;
  const address = direction.toUpperCase() === TransferDirection.INCOMING ? sender.value : recipient.value;

  return (
    <div className={styles.container}>
      <TransferTxInfoSummary txInfo={txInfo} txStatus={txStatus} />
      <Profile address={address} />
    </div>
  );
};

export default TransferTxInfo;
