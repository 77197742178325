import { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import type { AppProps } from "next/app";
import { AppLayout, Web3Provider, AppProvider } from "@/components";
import { persistor, store, useAppDispatch, useAppSelector } from "@/store";
import { useLoadableStores } from "@/hooks";
import { selectBackupAddedSafes, setBackupAddedSafes } from "@/store/backupAddedSafesSlice";
import { selectAddedSafes, setAddedSafes } from "@/store/addedSafesSlice";
import { supportedChain } from "@/config";
import "../styles/globals.css";

const InitApp = () => {
  const { chainId } = supportedChain;
  const dispatch = useAppDispatch();
  const addedSafes = useAppSelector(selectAddedSafes);
  const backupAddedSafes = useAppSelector(selectBackupAddedSafes);

  useEffect(() => {
    if (!backupAddedSafes) {
      if (addedSafes && addedSafes[chainId] && Object.keys(addedSafes[chainId]).length > 0) {
        dispatch(setBackupAddedSafes({ [chainId]: JSON.parse(JSON.stringify(addedSafes[chainId])) }));
        dispatch(setAddedSafes({ [chainId]: {} }));
      } else {
        dispatch(setBackupAddedSafes({ [chainId]: {} }));
      }
    }
  }, [addedSafes, backupAddedSafes, chainId, dispatch]);

  useLoadableStores();
  return null;
};

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Web3Provider>
          <AppProvider>
            <InitApp />
            <AppLayout pathname={router.pathname}>
              <Component {...pageProps} />
            </AppLayout>
          </AppProvider>
        </Web3Provider>
      </PersistGate>
    </Provider>
  );
}

export default MyApp;
