import { Dispatch, SetStateAction, useCallback } from "react";
import { Paper, SvgIcon, useMediaQuery, useTheme } from "@mui/material";
import Link from "next/link";
import { TonConnectButton } from "@tonconnect/ui-react";
import { Pathnames } from "@/config";
import logo from "#/images/header/logo.svg";
import tonkey from "#/images/header/tonkey.svg";
import menu from "#/images/header/menu.svg";
import close from "#/images/header/close.svg";
import Switch from "./Switch";
import styles from "./AppHeader.module.css";

interface HeaderProps {
  isSideDrawerOpen: boolean;
  setIsSideDrawerOpen: Dispatch<SetStateAction<boolean>>;
  hideSideDrawer: boolean;
}

const Header = ({ isSideDrawerOpen, setIsSideDrawerOpen, hideSideDrawer }: HeaderProps) => {
  const { breakpoints } = useTheme();
  const isLessThanMd = useMediaQuery(breakpoints.down("md"));

  const onMenuToggle = useCallback(() => {
    setIsSideDrawerOpen((prevState) => !prevState);
  }, [setIsSideDrawerOpen]);

  return (
    <header>
      <Paper className={styles.container}>
        {isLessThanMd && !hideSideDrawer ? (
          <div className={styles.menu} onClick={onMenuToggle}>
            <SvgIcon component={isSideDrawerOpen ? close : menu} />
          </div>
        ) : (
          <div className={styles.logo}>
            <Link href={Pathnames.Home} passHref>
              <p className="flex items-center gap-2 cursor-pointer" data-testid="logo">
                <SvgIcon component={logo} inheritViewBox sx={{ width: "37.5px", height: "25px" }} />
                <SvgIcon
                  className={styles["tonkey-text"]}
                  component={tonkey}
                  inheritViewBox
                  sx={{ width: "71.25px", height: "20px" }}
                />
              </p>
            </Link>
          </div>
        )}

        <div className={styles["network-status"]}>
          <Switch />
        </div>

        <div className={styles["connect-button"]}>
          <TonConnectButton />
        </div>
      </Paper>
    </header>
  );
};

export default Header;
