import { useContext } from "react";
import { TransactionStatus } from "@safe-global/safe-gateway-typescript-sdk";
import { ReplaceTxHoverContext } from "@/components/transactions/GroupedTxListItems/ReplaceTxHoverProvider";
import { useAppSelector } from "@/store";
import { PendingStatus, selectPendingTxById } from "@/store/pendingTxsSlice";
import useWallet from "./useWallet";
import { TransactionSummary } from "@/types/transactions";

const ReplacedStatus = "WILL_BE_REPLACED";

type TxLocalStatus = TransactionStatus | PendingStatus | typeof ReplacedStatus;

export const STATUS_LABELS: Record<TxLocalStatus, string> = {
  [TransactionStatus.AWAITING_CONFIRMATIONS]: "Awaiting confirmations",
  [TransactionStatus.AWAITING_EXECUTION]: "Awaiting execution",
  [TransactionStatus.CANCELLED]: "Transaction expired",
  [TransactionStatus.FAILED]: "Failed",
  [TransactionStatus.SUCCESS]: "Success",
  [PendingStatus.SUBMITTING]: "Submitting",
  [PendingStatus.PROCESSING]: "Processing",
  [PendingStatus.INDEXING]: "Indexing",
  [PendingStatus.SIGNING]: "Signing",
  [ReplacedStatus]: "Transaction will be replaced",
};

const WALLET_STATUS_LABELS: Record<TxLocalStatus, string> = {
  ...STATUS_LABELS,
  [TransactionStatus.AWAITING_CONFIRMATIONS]: "Needs your confirmation",
};

const useTransactionStatus = (txSummary: TransactionSummary): string => {
  const { txStatus, id } = txSummary;

  const { replacedTxIds } = useContext(ReplaceTxHoverContext);
  const wallet = useWallet();
  const pendingTx = useAppSelector((state) => selectPendingTxById(state, id));

  if (replacedTxIds.includes(id)) {
    return STATUS_LABELS[ReplacedStatus];
  }

  const statuses = WALLET_STATUS_LABELS;
  return statuses[pendingTx?.status || txStatus] || "";
};

export default useTransactionStatus;
