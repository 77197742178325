import { useContext } from "react";
import type { ReactElement } from "react";
import type { TransactionSummary } from "@safe-global/safe-gateway-typescript-sdk";
import SignOrExecuteForm from "@/components/tx/SignOrExecuteForm";
import { Typography, Accordion, AccordionSummary, Alert, Skeleton } from "@mui/material";
import { TonwebContext } from "@/components/TonwebProvider";
import { useSafeInfo, useTonBalance, useWallet, useIsWrongChain, useFee } from "@/hooks";
import { TonContext } from "@/components/TonProvider";

export type ConfirmProposedTxProps = {
  txSummary: TransactionSummary;
  onSubmit: () => void;
};

const ConfirmProposedTx = ({ txSummary, onSubmit }: ConfirmProposedTxProps): ReactElement => {
  const tonweb = useContext(TonwebContext);
  const ton = useContext(TonContext);
  const { tonBalance } = useTonBalance();
  const { safe } = useSafeInfo();
  const wallet = useWallet();
  const isWrongChain = useIsWrongChain();
  const { fee, isOutOfFee } = useFee(txSummary);
  const isLoading = fee === -Infinity;
  const { id: txNonce } = txSummary;
  const isNext = txNonce !== undefined && txNonce;

  const disableSubmit = !(
    tonweb &&
    wallet &&
    !isWrongChain &&
    safe.address &&
    isNext &&
    !isOutOfFee &&
    tonBalance &&
    ton
  );

  return (
    <SignOrExecuteForm
      txId={txSummary.id}
      onSubmit={onSubmit}
      isExecutable={true}
      onlyExecute={true}
      disableSubmit={disableSubmit}
    >
      <Accordion elevation={0}>
        <AccordionSummary>
          <Typography display="flex" alignItems="center" justifyContent="space-between" width={1}>
            <span>Estimated fee </span>
            {isLoading ? (
              <Skeleton variant="text" sx={{ display: "inline-block", minWidth: "3em" }} />
            ) : (
              <span>{fee.toFixed(4)}</span>
            )}
          </Typography>
        </AccordionSummary>
      </Accordion>

      {fee && isOutOfFee && (
        <Typography variant="body2" color="border.main" textAlign="center" mt={3}>
          <Alert severity="error">Your balance is not enough, please deposit your safe</Alert>
        </Typography>
      )}

      <Typography variant="body2" color="border.main" textAlign="center" mt={3}>
        You&apos;re about to execute a transaction and will need to confirm it with your currently connected wallet.
      </Typography>
    </SignOrExecuteForm>
  );
};

export default ConfirmProposedTx;
