import { supportedChain } from "@/config/chains";
import { parseAddress, sameAddress } from "./addresses";
import { safeFormatUnits, safeParseUnits } from "./formatters";

export const validateAddress = (address: string) => {
  try {
    parseAddress(address);
  } catch (e) {
    return "Invalid address";
  }
};

export const validateChainId = (chainId: string) => {
  if (supportedChain.chainId !== chainId) {
    return "Invalid chain ID";
  }
};

export const uniqueAddress =
  (addresses: string[] = []) =>
  (address: string): string | undefined => {
    const ADDRESS_REPEATED_ERROR = "Address already added";
    const addressExists = addresses.some((addressFromList) => sameAddress(addressFromList, address));
    return addressExists ? ADDRESS_REPEATED_ERROR : undefined;
  };

export const addressIsNotCurrentSafe =
  (safeAddress: string) =>
  (address: string): string | undefined => {
    const OWNER_ADDRESS_IS_SAFE_ADDRESS_ERROR = "Cannot use Safe itself as owner.";
    return sameAddress(safeAddress, address) ? OWNER_ADDRESS_IS_SAFE_ADDRESS_ERROR : undefined;
  };

export const FLOAT_REGEX = /^[0-9]+([,.][0-9]+)?$/;

export const validateAmount = (amount?: string) => {
  if (!amount || isNaN(Number(amount))) {
    return "The value must be a number";
  }

  if (parseFloat(amount) <= 0) {
    return "The value must be greater than 0";
  }
};

export const validateLimitedAmount = (amount: string, decimals?: number, max?: string) => {
  if (!decimals || !max) return;

  const numberError = validateAmount(amount);
  if (numberError) {
    return numberError;
  }
  if (parseInt(max, 10) < 0) return "you don't have enough fee";
  if (safeParseUnits(amount, decimals)?.gt(max)) {
    return `Maximum value is ${safeFormatUnits(max, decimals)}`;
  }
};

export const validateDecimalLength = (value: string, maxLen?: number, minLen = 1) => {
  if (!maxLen || !value.includes(".")) {
    return;
  }

  const decimals = value.split(".")[1] || "";

  if (decimals.length < +minLen || decimals.length > +maxLen) {
    return `Should have ${minLen} to ${maxLen} decimals`;
  }
};

export const isValidURL = (url: string, protocolsAllowed = ["https:"]): boolean => {
  try {
    const urlInfo = new URL(url);

    return protocolsAllowed.includes(urlInfo.protocol) || urlInfo.hostname === "localhost";
  } catch (error) {
    return false;
  }
};
