import { useCallback } from "react";
import { useRouter } from "next/router";
import { Divider, useMediaQuery, useTheme, SvgIcon } from "@mui/material";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarNavigation from "@/components/sidebar/SidebarNavigation";
import { Pathnames } from "@/config";
import logo from "#/images/sidebar/logo.svg";
import styles from "./styles.module.css";

const Sidebar = () => {
  const router = useRouter();
  const { breakpoints } = useTheme();
  const isLessThanMd = useMediaQuery(breakpoints.down("md"));

  const onClickLogo = useCallback(() => {
    router.push(Pathnames.Home);
  }, [router]);

  return (
    <div className={`${styles.container} ${isLessThanMd ? "h-[calc(100vh-71px)] flex justify-between flex-col" : ""}`}>
      <div>
        <SidebarHeader />
        <Divider sx={{ borderColor: "#C3C6CF" }} />
        <SidebarNavigation />
      </div>
      {isLessThanMd && (
        <div className="flex justify-start items-center gap-3 p-4 pl-8 pr-10 cursor-pointer" onClick={onClickLogo}>
          <SvgIcon component={logo} sx={{ color: "white" }} />
          My tonkey accounts
        </div>
      )}
    </div>
  );
};

export default Sidebar;
