// import { getAddress } from 'ethers/lib/utils'
// import { isAddress } from '@ethersproject/address'
import TonWeb from "tonweb";
import { HttpProvider } from "tonweb/dist/types/providers/http-provider";

const Address = TonWeb.Address;

export const isAddressActive = async (provider: HttpProvider, address: string) => {
  try {
    const addressInfo = await provider.getAddressInfo(address);
    return addressInfo.state === "active";
  } catch (e) {
    console.log("fail to check address status");
    return false;
  }
};

export const toUserFriendlyAddress = (address: string) => {
  return parseAddress(address).toString(true, true, true);
};

export const toRawAddress = (address: string) => {
  return parseAddress(address).toString(false);
};

export const parseAddress = (address: string) => {
  return new Address(address);
};

export const sameAddress = (firstAddress: string | undefined, secondAddress: string | undefined): boolean => {
  if (!firstAddress || !secondAddress) {
    return false;
  }

  try {
    return toRawAddress(firstAddress) === toRawAddress(secondAddress);
  } catch (e) {
    return false;
  }
};

export type PrefixedAddress = {
  address: string;
  prefix?: string;
};

export const formatPrefixedAddress = ({ address, prefix }: PrefixedAddress): string => {
  return prefix ? `${prefix}:${address}` : address;
};
