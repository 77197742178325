import { useEffect, useState } from "react";
import { POLLING_INTERVAL } from "@/constants";
import { SafeInfo } from "@/types/safeInfo";
import { AsyncResult, useSafeAddress } from "@/hooks";
import { supportedChain } from "@/config/chains";
import { useWalletByAddress } from "tonkey-gateway-typescript-sdk";
import useSafeInfo from "../useSafeInfo";
import { sameAddress, toRawAddress } from "@/utils/addresses";

export const useLoadSafeInfo = (): AsyncResult<SafeInfo | undefined> => {
  const address = useSafeAddress();
  const { chainId } = supportedChain;
  const { safe } = useSafeInfo();

  const { safeInfo, error, loading, refetch } = useWalletByAddress(address, chainId, POLLING_INTERVAL);

  useEffect(() => {
    if (address) {
      const rawAddress = toRawAddress(address);
      refetch({ chainId: chainId, safeAddress: rawAddress });
    }
  }, [chainId, address, refetch]);

  return [safeInfo ?? (sameAddress(address, safe.address) ? safe : undefined), error, loading];
};

export default useLoadSafeInfo;
