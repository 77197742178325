import SettingsChangeTxInfo from "@/components/transactions/TxDetails/TxData/SettingsChange";
import type { SpendingLimitMethods } from "@/utils/transaction-guards";
import {
  isCancellationTxInfo,
  isCustomTxInfo,
  isMultiSendTxInfo,
  isMultisigDetailedExecutionInfo,
  isSettingsChangeTxInfo,
  isSpendingLimitMethod,
  isSupportedMultiSendAddress,
  isSupportedSpendingLimitAddress,
  isTransferTxInfo,
} from "@/utils/transaction-guards";
import type { TransactionDetails } from "@safe-global/safe-gateway-typescript-sdk";
import RejectionTxInfo from "@/components/transactions/TxDetails/TxData/Rejection";
import DecodedData from "@/components/transactions/TxDetails/TxData/DecodedData";
import TransferTxInfo from "@/components/transactions/TxDetails/TxData/Transfer";
import { supportedChain } from "@/config/chains";
import { MultiSendTxInfo } from "@/components/transactions/TxDetails/TxData/MultiSendTxInfo";

const TxData = ({ txDetails }: { txDetails: TransactionDetails }) => {
  const { chainId } = supportedChain;
  const { txInfo } = txDetails;

  if (isTransferTxInfo(txInfo)) {
    return <TransferTxInfo txInfo={txInfo} txStatus={txDetails.txStatus} />;
  }

  if (isSettingsChangeTxInfo(txInfo)) {
    return <SettingsChangeTxInfo settingsInfo={txInfo.settingsInfo} />;
  }

  if (isCancellationTxInfo(txInfo) && isMultisigDetailedExecutionInfo(txDetails.detailedExecutionInfo)) {
    return <RejectionTxInfo nonce={txDetails.detailedExecutionInfo?.nonce} isTxExecuted={!!txDetails.executedAt} />;
  }

  if (isSupportedMultiSendAddress(txInfo, chainId) && isMultiSendTxInfo(txInfo)) {
    return <MultiSendTxInfo txInfo={txInfo} />;
  }

  const method = txDetails.txData?.dataDecoded?.method as SpendingLimitMethods;
  if (isCustomTxInfo(txInfo) && isSupportedSpendingLimitAddress(txInfo, chainId) && isSpendingLimitMethod(method)) {
    // TODO
    return <span>SpendingLimits WIP</span>;
    // return <SpendingLimits txData={txDetails.txData} txInfo={txInfo} type={method} />
  }

  return <DecodedData txData={txDetails.txData} txInfo={txInfo} />;
};

export default TxData;
