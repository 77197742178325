import { createContext } from "react";

const NewSafeContext = createContext(
  {} as {
    state: Tonkey.NewSafeContext.State;
    actions: Tonkey.NewSafeContext.Actions;
  },
);

export default NewSafeContext;
