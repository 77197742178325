import { isConflictHeaderListItem, isNoneConflictType, isTransactionListItem } from "@/utils/transaction-guards";
import { CompactTransaction, CompactTransactionListItem } from "@/types/transactions";

type GroupedTxs = Array<CompactTransactionListItem | CompactTransaction[]>;

/**
 * Group txs by conflict header
 */
export const groupConflictingTxs = (list: CompactTransactionListItem[]): GroupedTxs => {
  return list
    .reduce<GroupedTxs>((resultItems, item) => {
      if (isConflictHeaderListItem(item)) {
        return resultItems.concat([[]]);
      }

      const prevItem = resultItems[resultItems.length - 1];
      if (Array.isArray(prevItem) && isTransactionListItem(item) && !isNoneConflictType(item)) {
        prevItem.push(item);
        return resultItems;
      }

      return resultItems.concat(item);
    }, [])
    .map((item) => {
      if (Array.isArray(item)) {
        return item.sort((a, b) => b.transaction.timestamp - a.transaction.timestamp);
      }
      return item;
    });
};

const INDEX_OF_LATEST_TRANSACTION = 0;
export const compareTransactions = (
  currentTransaction: CompactTransactionListItem | CompactTransaction[],
  previousTransaction: CompactTransactionListItem | CompactTransaction[],
) => {
  const isCurrentTransactionArray = Array.isArray(currentTransaction);
  const isPreviousTransactionArray = Array.isArray(previousTransaction);

  if (!isCurrentTransactionArray && !isPreviousTransactionArray) {
    return (
      (previousTransaction as CompactTransaction).transaction.timestamp -
      (currentTransaction as CompactTransaction).transaction.timestamp
    );
  } else if (isCurrentTransactionArray && !isPreviousTransactionArray) {
    return (
      (previousTransaction as CompactTransaction).transaction.timestamp -
      currentTransaction[INDEX_OF_LATEST_TRANSACTION].transaction.timestamp
    );
  } else if (!isCurrentTransactionArray && isPreviousTransactionArray) {
    return (
      previousTransaction[INDEX_OF_LATEST_TRANSACTION].transaction.timestamp -
      (currentTransaction as CompactTransaction).transaction.timestamp
    );
  } else if (isCurrentTransactionArray && isPreviousTransactionArray) {
    return (
      previousTransaction[INDEX_OF_LATEST_TRANSACTION].transaction.timestamp -
      currentTransaction[INDEX_OF_LATEST_TRANSACTION].transaction.timestamp
    );
  } else {
    return 0;
  }
};

const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
export const mapTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getTransactionSegments = (records: GroupedTxs, hiddenTransactions: string[]) => {
  const transactionSegments = new Map<string, CompactTransaction[]>();
  const hiddenTxs = new Set(hiddenTransactions);

  for (let i = 0, maxI = records.length; i < maxI; i++) {
    const record = records[i];
    let date: string;
    if (Array.isArray(record)) {
      const [lastTransaction] = record;

      if (hiddenTxs.has(lastTransaction.transaction.id || (lastTransaction.transactionDetails?.txHash as string))) {
        continue;
      }

      date = mapTimestampToDate(lastTransaction.transaction.timestamp);
    } else {
      if (
        hiddenTxs.has(
          (record as CompactTransaction).transaction.id ||
            ((record as CompactTransaction).transactionDetails?.txHash as string),
        )
      ) {
        continue;
      }

      date = mapTimestampToDate((record as CompactTransaction).transaction.timestamp);
    }

    if (transactionSegments.has(date)) {
      const value = transactionSegments.get(date) as CompactTransaction[];
      transactionSegments.set(date, [...value, record as CompactTransaction]);
    } else {
      transactionSegments.set(date, [record as CompactTransaction]);
    }
  }

  return transactionSegments;
};
