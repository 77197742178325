import type { ReactElement } from "react";
import { useContext } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { isMultisigExecutionInfo } from "@/utils/transaction-guards";
import ExpandableTransactionItem from "@/components/transactions/TxListItem/ExpandableTransactionItem";
import styles from "./GroupedTxListItems.module.css";
import { ReplaceTxHoverContext, ReplaceTxHoverProvider } from "./ReplaceTxHoverProvider";
import ExternalLink from "@/components/common/ExternalLink";
import { CompactTransaction } from "@/types/transactions";

const Disclaimer = ({ nonce }: { nonce?: number }) => (
  <Box className={styles.disclaimerContainer}>
    <Typography alignSelf="flex-start">{nonce}</Typography>
    <Typography>
      These transactions conflict as they use the same nonce. Executing one will automatically replace the other(s).
    </Typography>

    <ExternalLink
      href="https://help.safe.global/en/articles/4730252-why-are-transactions-with-the-same-nonce-conflicting-with-each-other"
      title="Why are transactions with the same nonce conflicting with each other?"
      className={styles.link}
    >
      Learn more
    </ExternalLink>
  </Box>
);

const TxGroup = ({ groupedListItems }: { groupedListItems: CompactTransaction[] }): ReactElement => {
  const nonce = isMultisigExecutionInfo(groupedListItems[0].transaction.executionInfo)
    ? groupedListItems[0].transaction.executionInfo.nonce
    : undefined;

  const { replacedTxIds } = useContext(ReplaceTxHoverContext);

  return (
    <Paper className={styles.container} variant="outlined">
      <Disclaimer nonce={nonce} />
      {groupedListItems.map((tx) => (
        <div key={tx.transaction.id} className={replacedTxIds.includes(tx.transaction.id) ? styles.willBeReplaced : ""}>
          <ExpandableTransactionItem item={tx} isGrouped />
        </div>
      ))}
    </Paper>
  );
};

const GroupedTxListItems = ({ groupedListItems }: { groupedListItems: CompactTransaction[] }): ReactElement => {
  return (
    <ReplaceTxHoverProvider groupedListItems={groupedListItems}>
      <TxGroup groupedListItems={groupedListItems} />
    </ReplaceTxHoverProvider>
  );
};

export default GroupedTxListItems;
