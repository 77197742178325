import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1240,
      lg: 1440,
      xl: 4000,
    },
  },
});
