import { useMemo } from "react";
import { type Transfer, type TransactionInfo, TransactionTokenType } from "@safe-global/safe-gateway-typescript-sdk";
import { isNativeTokenTransfer, isTransferTxInfo } from "@/utils/transaction-guards";
import InfoIcon from "#/images/notifications/info.svg";
import { useBalances } from "@/hooks";
import FiatValue from "@/components/common/FiatValue";
import { SvgIcon, Tooltip } from "@mui/material";
import { TON_FIAT_CONVERSION_FALLBACK_VALUE, TON_JETTON_MINTER_ADDRESS } from "@/constants";
import { Coins as Coins3 } from "ton3-core";

export const TransferTx = ({ info, fiatConversion }: { info: Transfer; fiatConversion: number }) => {
  let fiatBalance = "";

  const { transferInfo: transfer } = info;

  if (isNativeTokenTransfer(transfer)) {
    fiatBalance = Coins3.fromNano(transfer.value).mul(fiatConversion).toString();
  }

  return (
    <div className="flex justify-start items-center gap-1">
      <FiatValue value={fiatBalance} />
      {fiatBalance === "" && (
        <Tooltip
          title="Value may be zero due to missing token price information"
          placement="top"
          arrow
          className="flex justify-center items-center"
        >
          <span>
            <SvgIcon component={InfoIcon} inheritViewBox color="error" fontSize="small" />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

const TxValue = ({ info }: { info: TransactionInfo }) => {
  const { loading, error, balances } = useBalances();

  const fiatConversion = useMemo(() => {
    if (loading || error) {
      return TON_FIAT_CONVERSION_FALLBACK_VALUE;
    }

    const asset = balances.assets.find((asset) => asset.tokenInfo.jettonMinterAddress === TON_JETTON_MINTER_ADDRESS);
    if (asset) {
      const parsedFiatConversion = parseFloat(asset.fiatConversion);
      if (!isNaN(parsedFiatConversion)) {
        return parsedFiatConversion;
      }
    }

    return TON_FIAT_CONVERSION_FALLBACK_VALUE;
  }, [loading, error, balances.assets]);

  if (isTransferTxInfo(info)) {
    return <TransferTx info={info} fiatConversion={fiatConversion} />;
  }

  return <></>;
};

export default TxValue;
