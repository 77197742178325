import { Close, VisibilityOff } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import { useHiddenTokens, useHiddenTransactions } from "@/hooks";
import { useAppDispatch } from "@/store";
import { setHiddenTokens, setHiddenTransactionsInHistory, setHiddenTransactionsInQueue } from "@/store/settingsSlice";
import { supportedChain } from "@/config";
import styles from "./EnhancedTableToolbar.module.css";

const { chainId } = supportedChain;

interface EnhancedTableToolbarProps {
  selectedItems: string[];
  onSelectAllClick: () => void;
  deselect: () => void;
  isInAssets?: boolean;
  isInQueueTab?: boolean;
}

const EnhancedTableToolbar = ({
  selectedItems,
  onSelectAllClick,
  deselect,
  isInAssets = false,
  isInQueueTab = false,
}: EnhancedTableToolbarProps) => {
  const dispatch = useAppDispatch();
  const hiddenTokens = useHiddenTokens();
  const { queue, history } = useHiddenTransactions();

  const { length: selectedItemsAmount } = selectedItems;

  const onClickHideToken = () => {
    if (isInAssets) {
      dispatch(setHiddenTokens({ chainId, assets: [...selectedItems, ...hiddenTokens] }));
    } else {
      if (isInQueueTab) {
        dispatch(setHiddenTransactionsInQueue({ chainId, transactions: [...selectedItems, ...queue] }));
      } else {
        dispatch(setHiddenTransactionsInHistory({ chainId, transactions: [...selectedItems, ...history] }));
      }
    }

    deselect();
  };

  return selectedItemsAmount > 0 ? (
    <>
      <div className="flex justify-between items-center pr-6 py-4">
        <div className="flex justify-start items-center gap-6">
          <p className={styles.description}>Selected {selectedItemsAmount} item.</p>
          <div className="flex justify-center items-center gap-2">
            <Button onClick={onSelectAllClick} className={styles["select-all-button"]}>
              Select all
            </Button>
            <Button onClick={onClickHideToken} className={styles["hidden-button"]}>
              <VisibilityOff sx={{ width: "18px", height: "18px", marginRight: "8px", color: "#0060A9" }} /> Hide{" "}
              {isInAssets ? "token" : "transaction"}
            </Button>
          </div>
        </div>
        <Close onClick={deselect} />
      </div>
      {!isInAssets && <Divider sx={{ borderColor: "#C3C6CF" }} />}
    </>
  ) : null;
};

export default EnhancedTableToolbar;
