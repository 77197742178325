import { AsyncResult, useSafeAddress } from "@/hooks";
import { supportedChain } from "@/config/chains";
import { Transaction } from "@/types/tx";
import { useTxQueue } from "tonkey-gateway-typescript-sdk";
import { POLLING_INTERVAL } from "@/constants";

export const useLoadTxQueue = (): AsyncResult<Transaction[]> => {
  const address = useSafeAddress();
  const { chainId } = supportedChain;

  const { data, error, loading } = useTxQueue(address, chainId, POLLING_INTERVAL);

  return [data, error, loading];
};

export default useLoadTxQueue;
