import { Button, ButtonProps } from "@mui/material";

interface NextButtonProps extends ButtonProps {
  text: string;
}

const NextButton = ({ text, onClick = () => {}, disabled = false }: NextButtonProps) => (
  <Button
    type="submit"
    variant="contained"
    size="stretched"
    onClick={onClick}
    disabled={disabled}
    sx={{
      height: "40px",
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 49px",
      background: "var(--m-3-sys-light-primary)",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      ":hover": {
        background: "var(--m-3-sys-light-primary)",
      },
    }}
  >
    {text}
  </Button>
);

export default NextButton;
