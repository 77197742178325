/* eslint-disable @next/next/no-img-element */
import type { ReactNode } from "react";
import { Button } from "@mui/material";
import { useSafeInfo, useSafeAddress } from "@/hooks";
import PagePlaceholder from "../PagePlaceholder";
import { Pathnames } from "@/config";
import Link from "next/link";

const SafeLoadingError = ({ children }: { children: ReactNode }) => {
  const address = useSafeAddress();
  const { safe } = useSafeInfo();

  if (!address || safe.address) return <>{children}</>;

  return (
    <PagePlaceholder
      img={<img src="/images/common/error.png" alt="A vault with a red icon in the bottom right corner" />}
      text="This Safe couldn't be loaded"
    >
      <Link href={Pathnames.Home} passHref>
        <Button variant="contained" color="primary" size="large" sx={{ mt: 2 }}>
          Go to the main page
        </Button>
      </Link>
    </PagePlaceholder>
  );
};

export default SafeLoadingError;
