import ImageFallback from "@/components/common/ImageFallback";
import { useTransactionType } from "@/hooks";
import { Box } from "@mui/material";
import css from "./styles.module.css";
import { TransactionSummary } from "@/types/transactions";

type TxTypeProps = {
  tx: TransactionSummary;
};

const TxType = ({ tx }: TxTypeProps) => {
  const type = useTransactionType(tx);

  return (
    <Box className={css.txType}>
      <div className="w-[24px] h-[24px] flex justify-center items-center  rounded-[24px] bg-[#D3E4FF]">
        <ImageFallback
          src={type.icon}
          fallbackSrc="/images/transactions/custom.svg"
          alt="Transaction type"
          width={16}
          height={16}
        />
      </div>

      {type.text}
    </Box>
  );
};

export default TxType;
