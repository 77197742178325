import { useMemo } from "react";
import { useRouter } from "next/router";
import { ListItem, ListItemText } from "@mui/material";
import { SidebarList, SidebarListItemButton, SidebarListItemIcon } from "@/components/sidebar/SidebarList";
import { navItems } from "./config";
import styles from "./styles.module.css";

const getSubdirectory = (pathname: string) => pathname.split("/")[1];

const SidebarNavigation = () => {
  const {
    pathname,
    query: { safe },
  } = useRouter();

  const currentSubdirectory = getSubdirectory(pathname);
  const content = useMemo(
    () =>
      navItems.map(({ href, icon, label }) => {
        const isSelected = currentSubdirectory === getSubdirectory(href);

        return (
          <ListItem key={href} disablePadding>
            <SidebarListItemButton selected={isSelected} href={{ pathname: href, query: { safe } }}>
              {icon && <SidebarListItemIcon>{icon}</SidebarListItemIcon>}
              <ListItemText className={styles.label}>{label}</ListItemText>
            </SidebarListItemButton>
          </ListItem>
        );
      }),
    [currentSubdirectory, safe],
  );

  return <SidebarList>{content}</SidebarList>;
};

export default SidebarNavigation;
