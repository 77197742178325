import { isDateLabel, isLabelListItem, isTransactionListItem } from "@/utils/transaction-guards";
import GroupLabel from "@/components/transactions/GroupLabel";
import TxDateLabel from "@/components/transactions/TxDateLabel";
import ExpandableTransactionItem from "./ExpandableTransactionItem";
import { CompactTransactionListItem } from "@/types/transactions";

type TxListItemProps = {
  item: CompactTransactionListItem;
};

const TxListItem = ({ item }: TxListItemProps) => {
  if (isLabelListItem(item)) {
    return <GroupLabel item={item} />;
  }
  if (isTransactionListItem(item)) {
    return <ExpandableTransactionItem item={item} txDetails={item.transactionDetails} />;
  }
  if (isDateLabel(item)) {
    return <TxDateLabel item={item} />;
  }
  return null;
};

export default TxListItem;
