import { supportedChain } from "@/config/chains";
import { isClientSide } from "@/utils";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { createContext, FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import TonWeb from "tonweb";

export type TonwebState = TonWeb | undefined;

const initialState: TonwebState = undefined;

export const TonwebContext = createContext<TonwebState>(initialState);

type TonwebProviderProps = {
  children: JSX.Element;
};

const TonWebWrappedHttpProvider = new Proxy(TonWeb.HttpProvider, {
  construct(target, args) {
    // Intercept the creation of new instances and modify input
    const instance = new target(...args);

    const sendProxy = new Proxy(instance.send, {
      async apply(doCallTarget, thisArg, doCallArgs) {
        // Intercept the apply (method call) and modify the method
        // const modifiedMethod = doCallArgs[0].toUpperCase() // For example, convert method to uppercase
        const modifiedMethod = "ton_" + doCallArgs[0];
        console.log(`Proxy doCall method called with method: ${modifiedMethod}`);
        doCallArgs[0] = modifiedMethod;

        return Reflect.apply(doCallTarget, thisArg, doCallArgs);
      },
    });

    instance.send = sendProxy;

    return instance;
  },
});

class TonAdapter extends TonWeb {
  constructor({ endpoint }: { endpoint: string }) {
    super(new TonWebWrappedHttpProvider(endpoint));
  }
}

const TonwebProvider: FunctionComponent<TonwebProviderProps> = ({ children }) => {
  const [tonweb, setTonweb] = useState<TonWeb>();

  useEffect(() => {
    const handler = async () => {
      if (!isClientSide()) {
        return undefined;
      }

      if (!supportedChain) {
        return undefined;
      }

      let endpoint = supportedChain.tonCenterUrl;
      setTonweb(new TonAdapter({ endpoint }));
    };
    handler();
  }, []);

  return <TonwebContext.Provider value={tonweb}>{children}</TonwebContext.Provider>;
};

export default memo(TonwebProvider);
