import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { supportedChain } from "@/config";

type SettingsState = {
  [chainId: string]: {
    hiddenTokens: string[];
    hiddenTransactions: {
      queue: string[];
      history: string[];
    };
  };
};

const initialState: SettingsState = {
  [supportedChain.chainId]: {
    hiddenTokens: [],
    hiddenTransactions: {
      queue: [],
      history: [],
    },
  },
};

export const settingsSlice = createSlice({
  name: "settingsV2",
  initialState,
  reducers: {
    setHiddenTokens: (state, { payload }: PayloadAction<{ chainId: string; assets: string[] }>) => {
      const { chainId, assets } = payload;
      state[chainId].hiddenTokens = assets;
    },
    setHiddenTransactionsInQueue: (state, { payload }: PayloadAction<{ chainId: string; transactions: string[] }>) => {
      const { chainId, transactions } = payload;
      state[chainId].hiddenTransactions.queue = transactions;
    },
    setHiddenTransactionsInHistory: (
      state,
      { payload }: PayloadAction<{ chainId: string; transactions: string[] }>,
    ) => {
      const { chainId, transactions } = payload;
      state[chainId].hiddenTransactions.history = transactions;
    },
  },
});

export const { setHiddenTokens, setHiddenTransactionsInQueue, setHiddenTransactionsInHistory } = settingsSlice.actions;

export const selectSettings = (state: RootState): SettingsState => state[settingsSlice.name];

export const selectHiddenTokensPerChain = (state: RootState, chainId: string) =>
  state[settingsSlice.name][chainId]?.hiddenTokens || initialState[supportedChain.chainId].hiddenTokens;

export const selectHiddenTransactions = (state: RootState, chainId: string) =>
  state[settingsSlice.name][chainId]?.hiddenTransactions || initialState[supportedChain.chainId].hiddenTransactions;
