import { Button, ButtonProps } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

interface BackButtonProps extends ButtonProps {
  isDisabled?: boolean;
  text: string;
}

const BackButton = ({ isDisabled = false, text, onClick = () => {} }: BackButtonProps) => (
  <Button
    variant="outlined"
    size="small"
    startIcon={<ArrowBack fontSize="small" />}
    onClick={onClick}
    disabled={isDisabled}
    sx={{
      height: "40px",
      textTransform: "none",
      borderRadius: "8px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "var(--m-3-sys-light-on-primary-container)",
      border: "1px solid var(--m-3-sys-light-outline)",
      padding: "10px 24px 10px 16px",
      ":hover": {
        border: "1px solid var(--m-3-sys-light-outline)",
      },
    }}
  >
    {text}
  </Button>
);

export default BackButton;
