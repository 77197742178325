import { useMemo } from "react";
import { supportedChain } from "@/config/chains";
import useBalances from "./useBalances";

const DEFAULT_BALANCE = "0";
const useTonBalance = () => {
  const {
    balances: { assets },
    loading,
    error,
  } = useBalances();

  const { balance } = useMemo(
    () =>
      assets.find(({ tokenInfo }) => tokenInfo.tokenSymbol === supportedChain.nativeCurrency.symbol) || {
        balance: DEFAULT_BALANCE,
      },
    [assets],
  );

  return {
    tonBalance: parseInt(balance, 10),
    loading,
    error,
  };
};

export default useTonBalance;
