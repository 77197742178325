import React, { useState } from "react";
import { Link, SvgIcon } from "@mui/material";
import { useRouter } from "next/router";
import type { TransactionDetails, Transfer } from "@safe-global/safe-gateway-typescript-sdk";
import { DetailedExecutionInfoType, Operation, TransferDirection } from "@safe-global/safe-gateway-typescript-sdk";
import { generateDataRowValue, TxDataRow } from "@/components/transactions/TxDetails/Summary/TxDataRow";
import { isMultisigDetailedExecutionInfo } from "@/utils/transaction-guards";
import { dateString } from "@/utils/formatters";
import CopyButton from "@/components/common/CopyButton";
import linkIcon from "#/images/common/link.svg";
import { Pathnames } from "@/config";
import styles from "./Summary.module.css";

interface SummaryProps {
  txDetails: TransactionDetails;
  txSummary: TransactionSummary;
  defaultExpanded?: boolean;
}

import { TransactionSummary } from "@/types/transactions";

const Summary = ({ txSummary, txDetails, defaultExpanded = false }: SummaryProps) => {
  const {
    query: { safe = "" },
  } = useRouter();

  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const toggleExpanded = () => {
    setExpanded((val) => !val);
  };

  const isOutgoing = (txDetails?.txInfo as Transfer)?.direction === TransferDirection.OUTGOING;

  const { executionInfo, id } = txSummary;
  const { txHash, detailedExecutionInfo, txData, txId, executedAt } = txDetails;
  let submittedAt, confirmations, baseGas, gasPrice, gasToken, refundReceiver, safeTxGas;
  if (isMultisigDetailedExecutionInfo(detailedExecutionInfo)) {
    ({ submittedAt, confirmations, baseGas, gasPrice, gasToken, safeTxGas } = detailedExecutionInfo);
    refundReceiver = detailedExecutionInfo.refundReceiver?.value;
  }
  const hrefOfDetailPage = `${window.location.origin}${Pathnames.TransactionDetail}/?safe=${safe}&id=${txId}`;
  const hrefOfParser = `https://boc.tonkey.app/?boc=${id}`;

  return (
    <div className={`${styles.container} h-full`}>
      <div className="flex flex-col justify-between items-start h-full">
        <div>
          {txHash && (
            <TxDataRow title="Transaction hash" className="mb-2">
              {generateDataRowValue(txHash, "hash", true)}
            </TxDataRow>
          )}

          {id && (
            <TxDataRow title="Raw transaction" className="mb-2">
              <div className="flex justify-start items-start gap-1">
                <span className="text-neutral text-xs break-all">{id}</span>
                <div>
                  <CopyButton text={hrefOfParser} />
                  <a href={hrefOfParser} target="_blank" rel="noreferrer">
                    <SvgIcon
                      component={linkIcon}
                      inheritViewBox
                      sx={{ width: "16px", height: "16px", color: "#C3C6CF", marginLeft: "4px" }}
                    />
                  </a>
                </div>
              </div>
            </TxDataRow>
          )}

          <TxDataRow title="Created" className="mb-2">
            <div className="text-neutral text-xs">
              {isOutgoing ? (submittedAt ? dateString(submittedAt) : "--") : executedAt ? dateString(executedAt) : "--"}
            </div>
          </TxDataRow>

          {isOutgoing && (
            <TxDataRow title="Executed" className="mb-2">
              <div className="text-neutral text-xs">{executedAt ? dateString(executedAt) : "--"}</div>
            </TxDataRow>
          )}

          {executionInfo?.type === DetailedExecutionInfoType.MULTISIG ? (
            <TxDataRow title="Remark">
              <div className="text-neutral text-xs">{executionInfo.remark}</div>
            </TxDataRow>
          ) : null}
        </div>

        {isOutgoing && (
          <TxDataRow title="Detail page" className="mt-[53px]">
            <CopyButton text={hrefOfDetailPage} />
            <a href={hrefOfDetailPage} target="_blank" rel="noreferrer">
              <SvgIcon component={linkIcon} inheritViewBox sx={{ width: "16px", height: "16px", color: "#C3C6CF" }} />
            </a>
          </TxDataRow>
        )}
      </div>

      {txData && (
        <>
          {!defaultExpanded && (
            <Link className={styles.buttonExpand} onClick={toggleExpanded} component="button" variant="body1">
              Advanced details
            </Link>
          )}

          {expanded && (
            <div>
              <TxDataRow title="Operation:">
                {`${txData.operation} (${Operation[txData.operation].toLowerCase()})`}
              </TxDataRow>
              <TxDataRow title="safeTxGas:">{safeTxGas}</TxDataRow>
              <TxDataRow title="baseGas:">{baseGas}</TxDataRow>
              <TxDataRow title="gasPrice:">{gasPrice}</TxDataRow>
              <TxDataRow title="gasToken:">{generateDataRowValue(gasToken, "hash", true)}</TxDataRow>
              <TxDataRow title="refundReceiver:">{generateDataRowValue(refundReceiver, "hash", true)}</TxDataRow>
              {confirmations?.map(({ signature }, index) => (
                <TxDataRow title={`Signature ${index + 1}:`} key={`signature-${index}:`}>
                  {generateDataRowValue(signature, "rawData")}
                </TxDataRow>
              ))}
              <TxDataRow title="Raw data:">{generateDataRowValue(txData.hexData, "rawData")}</TxDataRow>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Summary;
