import { CHAIN } from "@tonconnect/protocol";
import { SafeInfo } from "../types/safeInfo";
import { makeLoadableSlice } from "./common";

export const defaultSafeInfo: SafeInfo = {
  name: "",
  address: "",
  chainId: CHAIN.TESTNET,
  walletId: 0,
  threshold: 0,
  owners: [],
};

const { slice, selector } = makeLoadableSlice("safeInfo", undefined as SafeInfo | undefined);

export const safeInfoSlice = slice;
export const selectSafeInfo = selector;
