import { useEffect, useState } from "react";
import { supportedChain } from "@/config/chains";
import { useWallet } from "@/hooks";
import { useWalletsByOwnerAddress } from "tonkey-gateway-typescript-sdk";
import { SidebarSafeInfo, SafeInfo } from "@/types/safeInfo";
import { toUserFriendlyAddress } from "@/utils/addresses";
import { CHAIN } from "@tonconnect/protocol";
import { isOwner } from "@/utils/transaction-guards";

const { chainId } = supportedChain;

export type OwnedSafes = {
  [chainId in CHAIN]: SidebarSafeInfo[];
};

const defaultOwnedSafes: OwnedSafes = {
  [CHAIN.MAINNET]: [],
  [CHAIN.TESTNET]: [],
};

const useOwnedSafes = () => {
  const [ownedSafes, setOwnedSafes] = useState(defaultOwnedSafes);
  const wallet = useWallet();
  const { safeList, loading, error } = useWalletsByOwnerAddress(wallet?.address || "");

  useEffect(() => {
    if (safeList.length) {
      const nextOwnedSafes = {
        ...defaultOwnedSafes,
      };

      nextOwnedSafes[chainId] = safeList
        .filter((safes: SafeInfo) => safes.chainId === supportedChain.chainId)
        .map(({ address, owners, ...rest }) => ({
          ...rest,
          owners,
          address: toUserFriendlyAddress(address),
          isReadOnly: !isOwner(owners, wallet?.address || ""),
        }));

      setOwnedSafes(nextOwnedSafes);
    }
  }, [safeList, wallet?.address]);

  return {
    loading,
    error,
    ownedSafes,
  };
};

export default useOwnedSafes;
