import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          top: "10px",
          width: "250px !important",
          right: "500px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: "200px",
          padding: "12px",
        },
      },
    },
  },
});
