import { useMemo } from "react";
import useBalances from "./useBalances";
import useHiddenTokens from "./useHiddenTokens";
import { Balance } from "@/types/balance";

const filterHiddenTokens = (assets: Balance["assets"], hiddenAssets: string[]) =>
  assets.filter((balanceItem) => !hiddenAssets.includes(balanceItem.tokenInfo.jettonMinterAddress));

export const useVisibleBalances = (): {
  balances: Balance;
  loading: boolean;
  error?: string;
} => {
  const data = useBalances();
  const hiddenTokens = useHiddenTokens();

  return useMemo(
    () => ({
      ...data,
      balances: {
        assets: filterHiddenTokens(data.balances.assets, hiddenTokens),
        fiatTotal: data.balances.fiatTotal ?? "",
      },
    }),
    [data, hiddenTokens],
  );
};
