import React from "react";
import { Button, DialogActions, Grid, LinearProgress } from "@mui/material";
import type { TxStepperProps } from "@/components/tx/TxStepper/useTxStepper";
import { useTxStepper } from "@/components/tx/TxStepper/useTxStepper";
import { ModalDialogTitle } from "@/components/common/ModalDialog";
import styles from "./styles.module.css";

const TxStepper = ({ steps, initialData, initialStep, onClose }: TxStepperProps) => {
  const { onBack, onSubmit, setStep, activeStep, stepData, firstStep } = useTxStepper({
    steps,
    initialData,
    initialStep,
    onClose,
  });

  const activeLabel = steps[activeStep].label;
  const activeStepData = stepData[Math.max(0, activeStep)];
  const progress = ((activeStep + 1) / steps.length) * 100;

  return (
    <div className={styles.container}>
      <ModalDialogTitle onClose={onClose} hideChainIndicator={true}>
        <Grid container px={1} alignItems="center" gap={2}>
          <Grid item className={styles.title}>
            {typeof activeLabel === "string" ? activeLabel : activeLabel(activeStepData)}
          </Grid>
        </Grid>
      </ModalDialogTitle>

      <LinearProgress
        variant="determinate"
        value={Math.min(progress, 100)}
        sx={{ background: "#E3E2E6", ".MuiLinearProgress-bar": { background: "#0060A9" } }}
      />

      {steps[activeStep].render(activeStepData, onSubmit, onBack, setStep)}

      <DialogActions>
        <Button color="inherit" onClick={() => onBack()} className={styles.button}>
          {firstStep ? "Cancel" : "Back"}
        </Button>
      </DialogActions>
    </div>
  );
};

export default TxStepper;
