import type { ReactElement, ReactNode } from "react";
import { Typography } from "@mui/material";
import CopyButton from "@/components/common/CopyButton";
import { HexEncodedData } from "@/components/transactions/HexEncodedData";
import EthHashInfo from "@/components/common/EthHashInfo";
import styles from "./styles.module.css";

type TxDataRowProps = {
  title: string;
  children?: ReactNode;
  className?: string;
};

// FIXME: naive impl
function hexDataLength(v: string): number {
  return Math.ceil(v.length / 2);
}

export const TxDataRow = ({ title, children, className = "" }: TxDataRowProps): ReactElement | null => {
  if (children == undefined) return null;
  return (
    <div className={`${styles.gridRow} ${className}`}>
      <div className={styles.title}>{title}</div>
      <Typography component="div" sx={{ maxWidth: "calc(100% - 120px)" }}>
        {children}
      </Typography>
    </div>
  );
};

export const generateDataRowValue = (
  value?: string,
  type?: "hash" | "rawData" | "address" | "bytes",
  hasExplorer?: boolean,
): ReactElement | null => {
  if (value == undefined) return null;
  switch (type) {
    case "hash":
      return (
        <EthHashInfo
          address={value}
          hasExplorer={hasExplorer}
          showAvatar={false}
          showCopyButton
          isTx
          shortAddress={false}
        />
      );
    case "address":
      return <EthHashInfo address={value} hasExplorer={hasExplorer} showAvatar={false} showCopyButton />;
    case "rawData":
      return (
        <div className={styles.rawData}>
          <div>{value ? hexDataLength(value) : 0} bytes</div>
          <CopyButton text={value} />
        </div>
      );
    case "bytes":
      return <HexEncodedData limit={60} hexData={value} />;
    default:
      return <Typography sx={{ wordBreak: "break-all" }}>{value}</Typography>;
  }
};
