import { SET_ALL_TRANSACTIONS, SET_SELECTED_TRANSACTIONS, CLEAR_SELECTED_TRANSACTIONS } from "@/config";

export const transactionsReducer = (
  state: Tonkey.TransactionsContext.State,
  action: Tonkey.TransactionsContext.Action,
) => {
  const { type, selectedTransactions, allTransactions } = action;

  switch (type) {
    case SET_ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions,
      };
    case SET_SELECTED_TRANSACTIONS:
      return {
        ...state,
        selectedTransactions,
      };
    case CLEAR_SELECTED_TRANSACTIONS:
      return {
        ...state,
        selectedTransactions: [],
      };
    default:
      return state;
  }
};
