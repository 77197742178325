import { useState, PropsWithChildren, useMemo } from "react";
import classnames from "classnames";
import { AppHeader, AppFooter } from "@/components";
import { shouldHideSideDrawer } from "@/utils";
import SideDrawer from "./SideDrawer";
import SafeLoadingError from "../SafeLoadingError";
import styles from "./AppLayout.module.css";
import Head from "next/head";

interface AppLayoutProps extends PropsWithChildren {
  pathname: string;
}

const AppLayout = ({ pathname, children }: AppLayoutProps) => {
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState<boolean>(true);
  const hideSideDrawer = useMemo(() => shouldHideSideDrawer(pathname), [pathname]);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Head>
      <AppHeader
        isSideDrawerOpen={isSideDrawerOpen}
        setIsSideDrawerOpen={setIsSideDrawerOpen}
        hideSideDrawer={hideSideDrawer}
      />

      {!hideSideDrawer && <SideDrawer isOpen={isSideDrawerOpen} onToggle={setIsSideDrawerOpen} />}

      <div
        className={classnames(styles.main, {
          [styles["without-sidebar"]]: hideSideDrawer || !isSideDrawerOpen,
          [styles["without-footer"]]: !hideSideDrawer,
        })}
      >
        <div className={styles.content}>
          <SafeLoadingError>{children}</SafeLoadingError>
        </div>
      </div>

      {hideSideDrawer && <AppFooter />}
    </>
  );
};

export default AppLayout;
