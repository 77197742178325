import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { Sidebar } from "@/components";

type SideDrawerProps = {
  isOpen: boolean;
  onToggle: Dispatch<SetStateAction<boolean>>;
};

const commonSx = {
  border: "0.5px solid #C3C6CF",
  background: "#FFF",
  minWidth: "308px",
  boxSizing: "content-box",
  zIndex: 1000,
};

const SideDrawer = ({ isOpen, onToggle }: SideDrawerProps) => {
  const { pathname, query } = useRouter();
  const { breakpoints } = useTheme();
  const isLessThanMd = useMediaQuery(breakpoints.down("md"));

  const sx = useMemo(
    () =>
      isLessThanMd
        ? { left: 0, top: "56px", borderRadius: 0, height: "calc(100vh - 24px)" }
        : {
            left: "24px",
            top: "80px",
            borderRadius: "16px",
            height: "calc(100vh - 80px - 24px)",
          },
    [isLessThanMd],
  );

  useEffect(() => {
    onToggle(!isLessThanMd);
  }, [isLessThanMd, onToggle, pathname, query]);

  return (
    <>
      <Drawer
        variant={isLessThanMd ? "temporary" : "persistent"}
        anchor="left"
        open={isOpen}
        onClose={() => onToggle(false)}
        PaperProps={{
          sx: {
            ...commonSx,
            ...sx,
          },
        }}
      >
        <Sidebar />
      </Drawer>
    </>
  );
};

export default SideDrawer;
