import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CHAIN } from "@tonconnect/protocol";
import { RootState } from ".";
import { OwnerInfo } from "../types/safeInfo";

export type AddedSafesOnChain = {
  [address: string]: {
    name: string;
    address: string;
    chainId: CHAIN;
    walletId: number;
    threshold: number;
    owners: OwnerInfo[];
    shouldHide?: boolean;
  };
};

export type BackupAddedSafesState = {
  [chainId: string]: AddedSafesOnChain;
} | null;

const initialState = null as BackupAddedSafesState;

export const backupAddedSafesSlice = createSlice({
  name: "backupAddedSafes",
  initialState,
  reducers: {
    setBackupAddedSafes: (_, { payload }: PayloadAction<BackupAddedSafesState>) => {
      return payload;
    },
  },
});

export const { setBackupAddedSafes } = backupAddedSafesSlice.actions;

export const selectBackupAddedSafes = (state: RootState): BackupAddedSafesState => {
  return state[backupAddedSafesSlice.name];
};
