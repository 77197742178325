import { useMemo, useReducer, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useRouter } from "next/router";
import OverviewWidget from "@/components/new-safe/create/OverviewWidget";
import type { TxStepperProps } from "@/components/new-safe/CardStepper/useCardStepper";
import { ConnectWalletStep, SetNameStep, OwnerPolicyStep, ReviewStep } from "@/components/new-safe/create/steps";
import { CreateSafeStatus } from "@/components/new-safe/create/steps/StatusStep";
import { CardStepper, NewSafeContext } from "@/components";
import { OwnerInfo } from "@/types/safeInfo";
import { Pathnames } from "@/config";
import { SafeCreationStatus } from "./steps/StatusStep/useSafeCreation";
import { SET_SOME_OWNER_ADDRESS_NOT_ACTIVATED } from "./action";
import { reducer } from "./reducer";
import styles from "./styles.module.css";

export type NewSafeFormData = {
  name: string;
  address?: string;
  walletId: number;
  threshold: number;
  owners: OwnerInfo[];
};

const initialState: Tonkey.NewSafeContext.State = {
  someOwnerAddressNotActivated: false,
};

const CreateSafe = () => {
  const router = useRouter();

  const [safeName, setSafeName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [status, setStatus] = useState<SafeCreationStatus>(SafeCreationStatus.AWAITING);

  const CreateSafeSteps: TxStepperProps<NewSafeFormData>["steps"] = [
    {
      title: "Connect wallet",
      subtitle: "The connected wallet will pay the network fees for the Safe Account creation.",
      render: (data, onSubmit, onBack, setStep) => (
        <ConnectWalletStep data={data} onSubmit={onSubmit} onBack={onBack} setStep={setStep} />
      ),
    },
    {
      title: "Name of your Tonkey Account",
      subtitle: "Name each of your accounts distinctively for quick recognition.",
      render: (data, onSubmit, onBack, setStep) => (
        <SetNameStep setSafeName={setSafeName} data={data} onSubmit={onSubmit} onBack={onBack} setStep={setStep} />
      ),
    },
    {
      title: "Owners and confirmations",
      subtitle: "Set owner wallets and required confirmations for valid transactions in your Tonkey Account.",
      render: (data, onSubmit, onBack, setStep) => (
        <OwnerPolicyStep data={data} onSubmit={onSubmit} onBack={onBack} setStep={setStep} />
      ),
    },
    {
      title: "Review",
      subtitle: "Prepare to create a new Tonkey. Confirm the transaction using your connected wallet to proceed.",
      render: (data, onSubmit, onBack, setStep) => (
        <ReviewStep data={data} onSubmit={onSubmit} onBack={onBack} setStep={setStep} />
      ),
    },
    {
      title: "",
      subtitle: "",
      render: (data, onSubmit, onBack, setStep, setProgressColor) => (
        <CreateSafeStatus
          data={data}
          onSubmit={onSubmit}
          onBack={onBack}
          setStep={setStep}
          setProgressColor={setProgressColor}
          status={status}
          setStatus={setStatus}
        />
      ),
    },
  ];

  const initialData: NewSafeFormData = {
    name: "",
    walletId: 0,
    threshold: 1,
    owners: [],
  };

  const onClose = () => {
    router.push(Pathnames.Home);
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useMemo(
    () => ({
      setSomeOwnerAddressNotActivated(
        someOwnerAddressNotActivated: Tonkey.NewSafeContext.State["someOwnerAddressNotActivated"],
      ) {
        dispatch({ type: SET_SOME_OWNER_ADDRESS_NOT_ACTIVATED, ...state, someOwnerAddressNotActivated });
      },
    }),
    [state],
  );

  const valueOfNewSafeContext = useMemo(
    () => ({
      state,
      actions,
    }),
    [state, actions],
  );

  return (
    <NewSafeContext.Provider value={valueOfNewSafeContext}>
      <Container className={styles.container}>
        <Grid container columnSpacing={3} justifyContent="center">
          <Grid item xs={12}>
            <h2 className={styles.header}>Create New Tonkey</h2>
          </Grid>

          <Grid item xs={12} md={8} order={[1, null, 0]}>
            <CardStepper
              initialData={initialData}
              onClose={onClose}
              steps={CreateSafeSteps}
              setWidgetStep={setActiveStep}
            />
          </Grid>

          <Grid item xs={12} md={4} mb={[3, null, 0]} order={[0, null, 1]}>
            <Grid container spacing={3}>
              <OverviewWidget safeName={safeName} activeStep={activeStep} status={status} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </NewSafeContext.Provider>
  );
};

export default CreateSafe;
