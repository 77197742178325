import { ReactElement, useEffect } from "react";
import { Button, SvgIcon, MenuItem, Select, Divider, Box, useMediaQuery, useTheme } from "@mui/material";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import type { StepRenderProps } from "@/components/new-safe/CardStepper/useCardStepper";
import type { NewSafeFormData } from "@/components/new-safe/create";
import css from "@/components/new-safe/create/steps/OwnerPolicyStep/styles.module.css";
import layoutCss from "@/components/new-safe/create/styles.module.css";
import OwnerRow from "@/components/new-safe/OwnerRow";
import useSyncSafeCreationStep from "@/components/new-safe/create/useSyncSafeCreationStep";
import { OwnerInfo } from "@/types/safeInfo";
import { useIsWrongChain } from "@/hooks";
import { InfoIcon } from "@/components";
import AddIcon from "#/images/common/add.svg";
import NextButton from "../../NextButton";
import BackButton from "../../BackButton";
import styles from "./styles.module.css";

enum OwnerPolicyStepFields {
  owners = "owners",
  threshold = "threshold",
}

export type OwnerPolicyStepForm = {
  [OwnerPolicyStepFields.owners]: OwnerInfo[];
  [OwnerPolicyStepFields.threshold]: number;
};

const OWNER_POLICY_STEP_FORM_ID = "create-safe-owner-policy-step-form";

const OwnerPolicyStep = ({ onSubmit, onBack, data, setStep }: StepRenderProps<NewSafeFormData>): ReactElement => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  useSyncSafeCreationStep(setStep);
  const isWrongChain = useIsWrongChain();

  const formMethods = useForm<OwnerPolicyStepForm>({
    mode: "onChange",
    defaultValues: {
      [OwnerPolicyStepFields.owners]: data.owners,
      [OwnerPolicyStepFields.threshold]: data.threshold,
    },
  });

  const { handleSubmit, control, watch, formState, getValues, setValue, trigger } = formMethods;

  const threshold = watch(OwnerPolicyStepFields.threshold);

  const {
    fields: ownerFields,
    append: appendOwner,
    remove,
  } = useFieldArray({ control, name: OwnerPolicyStepFields.owners });

  const removeOwner = (index: number): void => {
    // Set threshold if it's greater than the number of owners
    setValue(OwnerPolicyStepFields.threshold, Math.min(threshold, ownerFields.length - 1));
    remove(index);
    trigger(OwnerPolicyStepFields.owners);
  };

  const disabled = !formState.isValid || isWrongChain;

  const handleBack = () => {
    const formData = getValues();
    onBack(formData);
  };

  const onFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  useEffect(() => {
    trigger(OwnerPolicyStepFields.owners);
  }, [trigger]);

  return (
    <form onSubmit={onFormSubmit} id={OWNER_POLICY_STEP_FORM_ID}>
      <FormProvider {...formMethods}>
        <Box className={layoutCss.row}>
          {ownerFields.map(({ id }, index) => (
            <OwnerRow
              key={id}
              index={index}
              removable={index > 0}
              groupName={OwnerPolicyStepFields.owners}
              remove={removeOwner}
            />
          ))}

          <Button
            variant="text"
            onClick={() => appendOwner({ name: "", address: "" }, { shouldFocus: true })}
            startIcon={<SvgIcon component={AddIcon} inheritViewBox fontSize="small" />}
            size="large"
            className="!capitalize"
          >
            Add new owner
          </Button>
        </Box>

        <Divider />
        <Box className={layoutCss.row}>
          <h4 className={styles.header}>
            Threshold
            <InfoIcon
              tooltipMessage="The threshold of a Safe specifies how many owners need to confirm a Safe transaction before it can be executed."
              size="16px"
            />
          </h4>
          <p className={styles.description}>Any transaction requires the confirmation of:</p>
          <Box
            display="flex"
            alignItems="center"
            className={`${styles["threshold-status"]} ${isSm ? "flex-col !items-start gap-4" : ""}`}
          >
            <Controller
              name={OwnerPolicyStepFields.threshold}
              control={control}
              render={({ field }) => (
                <Select {...field} className={`${css.select} ${isSm ? "w-full" : "w-[94px]"}`}>
                  {ownerFields.map(({ id }, i) => (
                    <MenuItem key={id} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <span>out of {ownerFields.length} owner(s).</span>
          </Box>
        </Box>

        <Divider />
        <Box className={`${layoutCss.row} !px-6`}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3}>
            <BackButton onClick={handleBack} text="Back" />
            <NextButton disabled={disabled} text="Next" />
          </Box>
        </Box>
      </FormProvider>
    </form>
  );
};

export default OwnerPolicyStep;
