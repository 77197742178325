import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Loadable<T> = {
  data: T;
  loading: boolean;
  error?: string;
};

export const makeLoadableSlice = <T>(name: string, data: T) => {
  type SliceState = Loadable<T>;

  const initialState: SliceState = {
    data,
    loading: false,
  };

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      set: (_, { payload }: PayloadAction<Loadable<T>>): SliceState => ({
        ...payload,
        data: payload.data ?? initialState.data, // fallback to initialState.data
      }),
    },
  });

  const selector = (state: Record<string, SliceState>) => state[name];

  return {
    slice,
    selector,
  };
};
