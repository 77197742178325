import { SET_SOME_OWNER_ADDRESS_NOT_ACTIVATED } from "./action";

export const reducer = (state: Tonkey.NewSafeContext.State, action: Tonkey.NewSafeContext.Action) => {
  const { type, someOwnerAddressNotActivated } = action;

  switch (type) {
    case SET_SOME_OWNER_ADDRESS_NOT_ACTIVATED:
      return {
        ...state,
        someOwnerAddressNotActivated,
      };
    default:
      return state;
  }
};
