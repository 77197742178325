import { SyntheticEvent, useContext, useState, type ReactElement } from "react";
import { DetailedExecutionInfoType } from "@safe-global/safe-gateway-typescript-sdk";
import { Button, Tooltip } from "@mui/material";
import { isSignableBy } from "@/utils/transaction-guards";
import { useWallet, useIsSafeOwner, useIsWrongChain, useSafeInfo, useTonProvider } from "@/hooks";
import { supportedChain } from "@/config/chains";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import * as ton3 from "ton3-core";
import { TonwebContext } from "@/components/TonwebProvider";
import { MultiSig } from "tonkey-sdk";
import TonWeb from "tonweb";
import { isClientSide } from "@/utils";
import { getSignTransactionPayload, useSignTransaction } from "tonkey-gateway-typescript-sdk";
import { toRawAddress, toUserFriendlyAddress } from "@/utils/addresses";
import { TransactionSummary } from "@/types/transactions";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { captureException } from "@/utils";

const BN = TonWeb.utils.BN;

const SignTxButton = ({
  txSummary,
  compact = false,
}: {
  txSummary: TransactionSummary;
  compact?: boolean;
}): ReactElement => {
  const wallet = useWallet();
  const { chainId } = supportedChain;
  const isWrongChain = useIsWrongChain();
  const isSafeOwner = useIsSafeOwner();
  const isSignable = isSignableBy(txSummary, wallet ? toUserFriendlyAddress(wallet.address) : "");
  const tonweb = useContext(TonwebContext);
  const { safe } = useSafeInfo();
  const { signTransaction } = useSignTransaction();
  const [isSigning, setIsSigning] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const { requestAccounts, rawSign, walletsFound } = useTonProvider();

  const onClick = async (e: SyntheticEvent) => {
    e.stopPropagation();
    const queryId =
      txSummary.executionInfo?.type === DetailedExecutionInfoType.MULTISIG
        ? txSummary.executionInfo.queryId
        : undefined;
    if (tonweb && wallet && queryId) {
      setIsSigning(true);

      // TODO: workaround for integration of tonconnect and openmask,
      //       check connection before deploy contract
      await tonConnectUI.getWallets();

      const accounts: string[] = await requestAccounts();
      const accountIndex = accounts.findIndex((a) => toRawAddress(a) === wallet.address);
      if (accountIndex == -1) {
        throw new Error("Connected wallet not found");
      }
      const signerAddress = accounts[accountIndex];

      const orderCellBoc = txSummary.id;
      const [orderCell] = ton3.BOC.from(orderCellBoc);
      let signature = undefined;
      try {
        signature = await MultiSig.signOrder(orderCell, async (orderCellHash) => {
          const signature = await rawSign([
            {
              data: orderCellHash,
            },
            signerAddress,
          ]);
          return signature;
        });
      } catch (error) {
        captureException(error);
        setIsSigning(false);
        console.log("sign rejected");
        return;
      }
      const payload = await getSignTransactionPayload(chainId, signature, wallet.address, queryId, safe.address);
      const result = await signTransaction({ variables: { content: payload } });
      if (result.errors || !result.data) {
        setIsSigning(false);
        throw new Error("Something wrong with graphql server");
      }
      const status = result.data.signTransaction;
      if (!status.success) {
        setIsSigning(false);
        console.log(JSON.stringify(status?.error));
        throw new Error("Sign Transaction failed");
      }
    }
  };

  const isDisabled = !isClientSide() || !walletsFound || !tonweb || !wallet || isWrongChain || !isSignable || isSigning;

  return (
    <>
      <div>
        {compact ? (
          <Tooltip title="Confirm" arrow placement="top">
            <span>
              <IconButton color="primary" disabled={isDisabled} size="small">
                <CheckIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Button onClick={onClick} variant="contained" disabled={isDisabled} size="stretched">
            Confirm
          </Button>
        )}
      </div>
    </>
  );
};

export default SignTxButton;
