import { useCallback, useMemo } from "react";
import { FormControl, Grid, IconButton, SvgIcon, Typography } from "@mui/material";
import NameInput from "@/components/common/NameInput";
import DeleteIcon from "#/images/common/delete.svg";
import { useFormContext, useWatch } from "react-hook-form";
import { OwnerInfo } from "@/types/safeInfo";
import { parseAddress, sameAddress } from "@/utils/addresses";
import AddressBookInput from "@/components/common/AddressBookInput";
import { InfoIcon, Profile } from "@/components";
import styles from "./styles.module.css";

export const OwnerRow = ({
  index,
  groupName,
  removable = true,
  remove,
  readOnly = false,
}: {
  index: number;
  removable?: boolean;
  groupName: string;
  remove?: (index: number) => void;
  readOnly?: boolean;
}) => {
  const fieldName = `${groupName}.${index}`;
  const { control, getValues } = useFormContext();

  const owners = useWatch({
    control,
    name: groupName,
  });

  const owner = useWatch({
    control,
    name: fieldName,
  });

  const deps = useMemo(() => {
    return Array.from({ length: owners.length }, (_, i) => `${groupName}.${i}`);
  }, [owners, groupName]);

  const validateSafeAddress = useCallback(
    async (address: string) => {
      const owners = getValues("owners");
      if (owners.filter((owner: OwnerInfo) => sameAddress(owner.address, address)).length > 1) {
        return "Duplicated owner";
      }

      try {
        parseAddress(address);
      } catch (e) {
        return "Invalid address";
      }
    },
    [getValues],
  );

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      marginBottom={3}
      flexWrap={["wrap", undefined, "nowrap"]}
      className={styles.helper}
    >
      <Grid item xs={12} sm={3} md={readOnly ? 3 : 4}>
        <FormControl fullWidth>
          <NameInput
            className={styles.name}
            name={`${fieldName}.name`}
            label="Owner name"
            InputLabelProps={{ shrink: true }}
            placeholder={`Owner ${index + 1}`}
          />
        </FormControl>
      </Grid>

      <Grid item xs={11} sm={8} md={9}>
        {readOnly ? (
          <Typography variant="body2" component="div">
            <Profile address={owner.address} showAvatar={false} />
          </Typography>
        ) : (
          <FormControl fullWidth className="">
            <AddressBookInput
              name={`${fieldName}.address`}
              label="Owner"
              validate={validateSafeAddress}
              checkPublicKey
              deps={deps}
            />
          </FormControl>
        )}
      </Grid>

      {!readOnly && (
        <Grid
          item
          ml={-3}
          xs={1}
          alignSelf="stretch"
          maxHeight="80px"
          display="flex"
          alignItems="center"
          flexShrink={0}
        >
          {index === 0 && (
            <div className="p-4">
              <InfoIcon tooltipMessage="Default is your connected wallet." size="16px" />
            </div>
          )}
          {removable && (
            <>
              <IconButton onClick={() => remove?.(index)} aria-label="Remove owner" className="!p-4">
                <SvgIcon component={DeleteIcon} inheritViewBox sx={{ width: "24px", height: "24px" }} />
              </IconButton>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default OwnerRow;
