import type { ReactNode } from "react";
import { Box, StepLabel, SvgIcon } from "@mui/material";
import processingIcon from "#/images/status-stepper/processing.svg";
import successIcon from "#/images/status-stepper/success.svg";
import failIcon from "#/images/status-stepper/fail.svg";
import styles from "./styles.module.css";

export enum StepStatus {
  Pending = "pending",
  Processing = "processing",
  Success = "success",
  Fail = "fail",
}

const icons = {
  [StepStatus.Pending]: <div className={`${styles.icon} !bg-white border-[1px] border-solid`}></div>,
  [StepStatus.Processing]: (
    <div className={styles.icon}>
      <SvgIcon component={processingIcon} />
    </div>
  ),
  [StepStatus.Success]: (
    <div className={styles.icon}>
      <SvgIcon component={successIcon} />
    </div>
  ),
  [StepStatus.Fail]: (
    <div className={`${styles.icon} !bg-[#FFDAD6]`}>
      <SvgIcon component={failIcon} />
    </div>
  ),
};

const StatusStep = ({ children, status }: { children: ReactNode; status: StepStatus }) => {
  return (
    <StepLabel className={styles.label} icon={icons[status]}>
      <Box display="flex" alignItems="center" gap={2}>
        {children}
      </Box>
    </StepLabel>
  );
};

export default StatusStep;
