import { useSafeInfo, useWallet } from "@/hooks";
import { isOwner } from "@/utils/transaction-guards";

const useIsSafeOwner = () => {
  const wallet = useWallet();
  const { safe } = useSafeInfo();

  if (!wallet || !safe) {
    return false;
  }

  return isOwner(safe.owners, wallet.address);
};

export default useIsSafeOwner;
