/**
 * @param {string} input base64 value
 * @returns {string} base64url value
 * @spec
 * https://stackoverflow.com/questions/55389211/string-based-data-encoding-base64-vs-base64url
 * https://stackoverflow.com/questions/5234581/base64url-decoding-via-javascript
 */
export const encode = (input: string) => input.replace(/\+/g, "-").replace(/\//g, "_");

/**
 * @param {string} input base64url value
 * @returns {string} base64 value
 * @spec
 * https://stackoverflow.com/questions/55389211/string-based-data-encoding-base64-vs-base64url
 * https://stackoverflow.com/questions/5234581/base64url-decoding-via-javascript
 */
export const decode = (input: string) => input.replace(/-/g, "+").replace(/_/g, "/");
