import React from "react";
import { Box } from "@mui/system";
import { InfoDetails } from "@/components/transactions/InfoDetails";
import { Profile } from "@/components";
import { generateDataRowValue, TxDataRow } from "@/components/transactions/TxDetails/Summary/TxDataRow";
import { dateString } from "@/utils/formatters";
import { isCreationTxInfo } from "@/utils/transaction-guards";
import { TransactionSummary } from "@/types/transactions";
import styles from "./styles.module.css";

type SafeCreationTxProps = {
  txSummary: TransactionSummary;
};

const SafeCreationTx = ({ txSummary }: SafeCreationTxProps) => {
  if (!isCreationTxInfo(txSummary.txInfo)) return null;

  const {
    timestamp,
    txInfo: {
      creator: { name = "", value: address },
      transactionHash,
    },
  } = txSummary;

  return (
    <>
      <Box className={styles.txCreation}>
        <InfoDetails title="CREATED">
          <Profile address={address} name={name} />
        </InfoDetails>
      </Box>
      <Box className={styles.txSummary}>
        <TxDataRow title="Transaction hash" className="mb-2">
          <span>{generateDataRowValue(transactionHash, "hash", true)}</span>
        </TxDataRow>
        <TxDataRow title="Created" className="mb-[53px]">
          <span className={styles.description}>{timestamp ? dateString(timestamp) : null}</span>
        </TxDataRow>
      </Box>
    </>
  );
};

export default SafeCreationTx;
