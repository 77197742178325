import { FunctionComponent, memo, useEffect, useState } from "react";
import { TonConnect } from "@tonconnect/sdk";
import { TonConnectUIProvider, WalletInfo } from "@tonconnect/ui-react";
import TonwebProvider from "@/components/TonwebProvider";
import { SUPPORT_RAW_SIGN_WALLETS } from "@/config/constants";
import TonProvider from "../TonProvider";

type Web3ProviderProps = {
  children: JSX.Element;
};
class MyTonConnect extends TonConnect {
  supportWallets = new Set(SUPPORT_RAW_SIGN_WALLETS);

  override async getWallets(): Promise<WalletInfo[]> {
    const wallets = await super.getWallets();
    return wallets.filter(({ name }) => this.supportWallets.has(name));
  }
}

const Web3Provider: FunctionComponent<Web3ProviderProps> = ({ children }) => {
  const [connector, setConnector] = useState<InstanceType<typeof MyTonConnect> | null>(null);

  useEffect(() => {
    const newConnector = new MyTonConnect({
      manifestUrl: process.env.NEXT_PUBLIC_TON_CONNECT_MANIFEST_URL,
    });
    setConnector(newConnector);
  }, []);

  return connector ? (
    <TonConnectUIProvider connector={connector}>
      <TonwebProvider>
        <TonProvider>{children}</TonProvider>
      </TonwebProvider>
    </TonConnectUIProvider>
  ) : null;
};

export default memo(Web3Provider);
