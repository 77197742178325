import { useState } from "react";
import { Box } from "@mui/system";
import { Card, LinearProgress, CardHeader, Avatar, Typography, CardContent } from "@mui/material";
import type { TxStepperProps } from "./useCardStepper";
import { useCardStepper } from "./useCardStepper";
import styles from "./styles.module.css";

function CardStepper<StepperData>(props: TxStepperProps<StepperData>) {
  const [progressColor, setProgressColor] = useState("#0060A9");
  const { activeStep, onSubmit, onBack, stepData, setStep } = useCardStepper<StepperData>(props);
  const { steps } = props;
  const currentStep = steps[activeStep];
  const progress = ((activeStep + 1) / steps.length) * 100;

  return (
    <Card className={styles.card}>
      {currentStep.title && (
        <CardHeader
          title={currentStep.title}
          subheader={currentStep.subtitle}
          titleTypographyProps={{
            variant: "h4",
            color: "var(--m-3-sys-light-on-surface-variant)",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500 !important",
            lineHeight: "24px",
            letterSpacing: "0.15px",
          }}
          subheaderTypographyProps={{
            variant: "body2",
            color: "var(--m-3-ref-neutral-variant-neutral-variant-60) !important",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "0.25px",
          }}
          avatar={
            <Avatar className={styles.step}>
              <Typography variant="body2">{activeStep + 1}</Typography>
            </Avatar>
          }
          className={styles.header}
        />
      )}

      <Box className={styles.progress} color={progressColor}>
        <LinearProgress color="inherit" variant="determinate" value={Math.min(progress, 100)} />
      </Box>

      <CardContent className={styles.content}>
        {currentStep.render(stepData, onSubmit, onBack, setStep, setProgressColor)}
      </CardContent>
    </Card>
  );
}

export default CardStepper;
