import { Suspense, useState, type ReactElement } from "react";
import dynamic from "next/dynamic";
import Button from "@mui/material/Button";
import { useIsSafeOwner, useIsWrongChain, useWallet } from "@/hooks";
import css from "./styles.module.css";

const NewTxModal = dynamic(() => import("@/components/tx/modals/NewTxModal"));

const NewTxButton = (): ReactElement => {
  const [txOpen, setTxOpen] = useState<boolean>(false);
  const wallet = useWallet();
  const isSafeOwner = useIsSafeOwner();
  const isWrongChain = useIsWrongChain();
  const canCreateTx = isSafeOwner;

  const onClick = () => {
    setTxOpen(true);
  };

  return (
    <>
      <Button
        onClick={onClick}
        variant="contained"
        size="small"
        disabled={!canCreateTx || isWrongChain}
        fullWidth
        className={css.button}
        disableElevation
      >
        {!wallet ? "Not connected" : isWrongChain ? "Wrong chain" : canCreateTx ? "New transaction" : "Read only"}
      </Button>

      {txOpen && (
        <Suspense>
          <NewTxModal onClose={() => setTxOpen(false)} />
        </Suspense>
      )}
    </>
  );
};

export default NewTxButton;
