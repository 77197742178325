import { useTonWallet } from "@tonconnect/ui-react";
import { CHAIN } from "@tonconnect/protocol";
import { useMemo } from "react";

export type WalletInfo = { address: string; chain: CHAIN; name: string };

const useWallet = (): WalletInfo | null => {
  const wallet = useTonWallet();

  return useMemo(() => {
    if (wallet) {
      const {
        name,
        account: { address, chain },
      } = wallet;

      return {
        address,
        chain,
        name,
      };
    } else {
      return null;
    }
  }, [wallet]);
};

export default useWallet;
