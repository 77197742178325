import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CHAIN } from "@tonconnect/protocol";
import { RootState } from ".";
import { OwnerInfo, SafeInfo } from "../types/safeInfo";

export type AddedSafesOnChain = {
  [address: string]: {
    name: string;
    address: string;
    chainId: CHAIN;
    walletId: number;
    threshold: number;
    owners: OwnerInfo[];
    shouldHide?: boolean;
  };
};

export type AddedSafesState = {
  [chainId: string]: AddedSafesOnChain;
};

const initialState: AddedSafesState = {};

export const addedSafesSlice = createSlice({
  name: "addedSafes",
  initialState,
  reducers: {
    setAddedSafes: (_, { payload }: PayloadAction<AddedSafesState>) => {
      return payload;
    },
    addOrUpdateSafe: (state, { payload }: PayloadAction<{ safe: SafeInfo }>) => {
      const { name, chainId, address, walletId, threshold, owners } = payload.safe;
      state[chainId] ??= {};
      state[chainId][address] = {
        name,
        address,
        chainId,
        walletId,
        threshold,
        owners,
      };
    },
    removeSafe: (state, { payload }: PayloadAction<{ chainId: string; address: string }>) => {
      const { chainId, address } = payload;
      delete state[chainId]?.[address];
      if (Object.keys(state[chainId]).length === 0) {
        delete state[chainId];
      }
    },
    editName: (state, { payload }: PayloadAction<{ safe: SafeInfo }>) => {
      const { name, chainId, address, walletId, threshold, owners } = payload.safe;
      state[chainId] ??= {};
      state[chainId][address] = {
        name,
        address,
        chainId,
        walletId,
        threshold,
        owners,
        shouldHide: true,
      };
    },
  },
});

export const { setAddedSafes, addOrUpdateSafe, removeSafe, editName } = addedSafesSlice.actions;

export const selectAddedSafes = (state: RootState): AddedSafesState => {
  return state[addedSafesSlice.name];
};
