import { useMemo } from "react";
import { useAppSelector } from "../store";
import { defaultSafeInfo, selectSafeInfo } from "../store/safeInfoSlice";
import { SafeInfo } from "@/types/safeInfo";

const useSafeInfo = (): {
  safe: SafeInfo;
  safeLoading: boolean;
  safeError?: string;
} => {
  const { data, error, loading } = useAppSelector(selectSafeInfo);

  return useMemo(
    () => ({
      safe: data || defaultSafeInfo,
      safeError: error,
      safeLoading: loading,
    }),
    [data, error, loading],
  );
};

export default useSafeInfo;
