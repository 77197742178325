import { useEffect, useState } from "react";
import { Box, Step, StepConnector, Stepper, SvgIcon } from "@mui/material";
import { SafeCreationStatus } from "@/components/new-safe/create/steps/StatusStep/useSafeCreation";
import type { PendingSafeData } from "@/components/new-safe/create/steps/StatusStep/index";
import StatusStep from "@/components/new-safe/create/steps/StatusStep/StatusStep";
import CopyButton from "@/components/common/CopyButton";
import AccountExplorerLink from "@/components/common/AccountExplorerLink";
import copyIcon from "#/images/common/copy.svg";
import { StepStatus } from "./StatusStep";
import styles from "./styles.module.css";

const StatusStepper = ({ pendingSafe, status }: { pendingSafe: PendingSafeData; status: SafeCreationStatus }) => {
  const [firstStepStatus, setFirstStepStatus] = useState<StepStatus>(StepStatus.Pending);
  const [secondStepStatus, setSecondStepStatus] = useState<StepStatus>(StepStatus.Pending);
  const [thirdStepStatus, setThirdStepStatus] = useState<StepStatus>(StepStatus.Pending);
  const [fourthStepStatus, setFourthStepStatus] = useState<StepStatus>(StepStatus.Pending);

  useEffect(() => {
    switch (status) {
      case SafeCreationStatus.AWAITING:
      case SafeCreationStatus.PROCESSING:
      case SafeCreationStatus.REVERTED:
        setFirstStepStatus(StepStatus.Processing);
        break;
      case SafeCreationStatus.WALLET_REJECTED:
      case SafeCreationStatus.ERROR:
      case SafeCreationStatus.TIMEOUT:
        setFirstStepStatus(StepStatus.Fail);
        break;
      case SafeCreationStatus.SUCCESS:
        setFirstStepStatus(StepStatus.Success);

        if (!pendingSafe?.sentData) {
          setSecondStepStatus(StepStatus.Processing);
        } else {
          setSecondStepStatus(StepStatus.Success);
          setThirdStepStatus(StepStatus.Processing);
        }

        setThirdStepStatus(StepStatus.Success);
        setFourthStepStatus(StepStatus.Processing);
        break;
      case SafeCreationStatus.INDEXED:
        setFourthStepStatus(StepStatus.Success);
        break;
      case SafeCreationStatus.INDEX_FAILED:
        setFourthStepStatus(StepStatus.Fail);
        break;
    }
  }, [pendingSafe?.sentData, status]);

  if (!pendingSafe?.address) return null;

  return (
    <Stepper orientation="vertical" nonLinear connector={<StepConnector className={styles.connector} />}>
      <Step>
        <StatusStep status={firstStepStatus}>
          <Box className="w-full">
            <p className={styles["step-header"]}>Your Tonkey address</p>
            <div className="w-full flex justify-between items-center">
              <div>
                <p className={styles["owner-address"]}>{pendingSafe.address}</p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <CopyButton text={pendingSafe.address}>
                  <SvgIcon
                    component={copyIcon}
                    inheritViewBox
                    sx={{ width: "16px", height: "16px", color: "#A1A3A7" }}
                  />
                </CopyButton>
                <AccountExplorerLink address={pendingSafe.address} />
              </div>
            </div>
          </Box>
        </StatusStep>
      </Step>

      <Step>
        <StatusStep status={secondStepStatus}>
          <Box>
            <p
              className={`${styles["step-header"]} ${
                secondStepStatus !== StepStatus.Success && secondStepStatus === StepStatus.Pending
                  ? "!text-[#C6C6CA]"
                  : ""
              }`}
            >
              Validating transaction
            </p>
          </Box>
        </StatusStep>
      </Step>

      <Step>
        <StatusStep status={thirdStepStatus}>
          <p className={`${styles["step-header"]} ${thirdStepStatus === StepStatus.Pending ? "!text-[#C6C6CA]" : ""}`}>
            Processing
          </p>
        </StatusStep>
      </Step>

      <Step>
        <StatusStep status={fourthStepStatus}>
          <p className={`${styles["step-header"]} ${fourthStepStatus === StepStatus.Pending ? "!text-[#C6C6CA]" : ""}`}>
            Tonkey is ready
          </p>
        </StatusStep>
      </Step>
    </Stepper>
  );
};

export default StatusStepper;
