import { useMemo, PropsWithChildren, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import TxListItem from "../TxListItem";
import GroupedTxListItems from "@/components/transactions/GroupedTxListItems";
import { groupConflictingTxs, compareTransactions, getTransactionSegments } from "@/utils";
import { CompactTransaction, CompactTransactionListPage, MultisigExecutionInfo } from "@/types/transactions";
import { EnhancedTableToolbar, TransactionsContext } from "@/components";
import { useHiddenTransactions } from "@/hooks";
import styles from "./TxList.module.css";

interface TxListProps {
  items: CompactTransactionListPage["results"];
  showDate?: boolean;
}

export const TxListGrid = ({ children }: PropsWithChildren) => <Box className={styles.container}>{children}</Box>;

const TxList = ({ items, showDate = true }: TxListProps) => {
  const {
    state: { selectedTransactions, isInQueueTab, allTransactions },
    actions: { clearSelectedTransactions, setAllTransactions, setSelectedTransactions },
  } = useContext(TransactionsContext);

  const { queue, history } = useHiddenTransactions();

  const transactions = useMemo(() => groupConflictingTxs(items).sort(compareTransactions), [items]);
  const transactionSegments = useMemo(
    () => getTransactionSegments(transactions, isInQueueTab ? queue : history),
    [history, isInQueueTab, queue, transactions],
  );

  const onSelectAllClick = () => {
    setSelectedTransactions(allTransactions);
  };

  const deselect = () => {
    clearSelectedTransactions();
  };

  const content = useMemo(
    () =>
      [...transactionSegments.keys()].map((date) => {
        const transactions = transactionSegments.get(date);

        return (
          <div key={date} className="mb-10">
            {showDate ? (
              <p className={styles.date}>{date}</p>
            ) : (
              <p className={styles.date}>
                QUEUED - TRANSACTION WITH NONCE{" "}
                {((transactions![0] as CompactTransaction).transaction.executionInfo as MultisigExecutionInfo).nonce}{" "}
                NEEDS TO BE EXECUTED FIRST
              </p>
            )}
            {transactions?.map((item, index) =>
              !Array.isArray(item) ? (
                <TxListItem key={item.transaction.id || item.transactionDetails?.txHash} item={item} />
              ) : (
                <GroupedTxListItems key={index} groupedListItems={item} />
              ),
            )}
          </div>
        );
      }),
    [showDate, transactionSegments],
  );

  useEffect(() => {
    setAllTransactions(
      transactions.map((record) =>
        Array.isArray(record)
          ? record[0].transaction.id || (record[0].transactionDetails?.txHash as string)
          : (record as CompactTransaction).transaction.id ||
            ((record as CompactTransaction).transactionDetails?.txHash as string),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return (
    <>
      <EnhancedTableToolbar
        selectedItems={selectedTransactions}
        isInQueueTab={isInQueueTab}
        onSelectAllClick={onSelectAllClick}
        deselect={deselect}
      />
      <TxListGrid>{content}</TxListGrid>
    </>
  );
};

export default TxList;
