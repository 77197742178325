import { txHistorySlice } from "@/store/txHistorySlice";
import { txQueueSlice } from "@/store/txQueueSlice";
import { type Slice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { safeInfoSlice } from "../store/safeInfoSlice";
import useLoadSafeInfo from "./loadables/useLoadSafeInfo";
import useLoadTxHistory from "./loadables/useLoadTxHistory";
import useLoadTxQueue from "./loadables/useLoadTxQueue";
import { AsyncResult } from "./useAsync";
import { balancesSlice } from "@/store/balancesSlice";
import useLoadBalances from "./loadables/useLoadBalances";

const useUpdateStore = (slice: Slice, useLoadHook: () => AsyncResult<unknown>) => {
  const dispatch = useAppDispatch();
  const [data, error, loading] = useLoadHook();
  const setAction = slice.actions.set;

  useEffect(() => {
    dispatch(
      setAction({
        data,
        error: data ? undefined : error?.message,
        loading: loading && !data,
      }),
    );
  }, [dispatch, setAction, data, error, loading]);
};

const useLoadableStores = () => {
  useUpdateStore(safeInfoSlice, useLoadSafeInfo);
  useUpdateStore(txQueueSlice, useLoadTxQueue);
  useUpdateStore(txHistorySlice, useLoadTxHistory);
  useUpdateStore(balancesSlice, useLoadBalances);
};

export default useLoadableStores;
