import type { AppThunk } from "@/store";
import { addOrUpdateSafe } from "@/store/addedSafesSlice";
import { upsertAddressBookEntry } from "@/store/addressBookSlice";
import { OwnerInfo } from "@/types/safeInfo";
import { CHAIN } from "@tonconnect/protocol";

export const updateAddressBook = (
  chainId: CHAIN,
  address: string,
  name: string,
  walletId: number,
  owners: OwnerInfo[],
  threshold: number,
): AppThunk => {
  return (dispatch) => {
    dispatch(
      addOrUpdateSafe({
        safe: {
          name: name,
          address: address,
          chainId: chainId,
          walletId: walletId,
          threshold: threshold,
          owners: owners,
        },
      }),
    );
    dispatch(
      upsertAddressBookEntry({
        chainId: chainId,
        address: address,
        name: name,
      }),
    );
    owners.forEach((owner) => {
      const entryName = owner.name;
      if (entryName) {
        dispatch(upsertAddressBookEntry({ chainId: chainId, address: owner.address, name: entryName }));
      }
    });
  };
};
