import { useState } from "react";
import { IconButton, Menu, MenuItem, ThemeProvider, SvgIcon, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectAddedSafes, removeSafe, addOrUpdateSafe } from "@/store/addedSafesSlice";
import { SidebarSafeInfo } from "@/types/safeInfo";
import pinIcon from "#/images/sidebar/pin.svg";
import unpinIcon from "#/images/sidebar/unpin.svg";
import renameIcon from "#/images/sidebar/rename.svg";
import EntryDialog from "../EntryDialog";
import { theme } from "./theme";
import styles from "./styles.module.css";

interface SafeListItemSecondaryActionProps {
  safeInfo: SidebarSafeInfo;
}

const SafeListItemSecondaryAction = ({ safeInfo }: SafeListItemSecondaryActionProps) => {
  const dispatch = useAppDispatch();
  const { address, chainId, isReadOnly, shouldHide } = safeInfo;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const addedSafes = useAppSelector((state) => selectAddedSafes(state))[chainId];
  const isAdded = !!addedSafes?.[address] && !addedSafes?.[address]?.shouldHide;
  const isAddedSafeOwner = true;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!anchorEl;
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const onClickRename = () => {
    onClose();
    openDialog();
  };

  const onClickPin = () => {
    if (isAdded) {
      dispatch(
        removeSafe({
          chainId,
          address,
        }),
      );
    } else {
      dispatch(
        addOrUpdateSafe({
          safe: safeInfo,
        }),
      );
    }
  };

  return !isAddedSafeOwner ? (
    <Typography
      variant="body2"
      display="flex"
      alignItems="center"
      sx={({ palette }) => ({ color: palette.border.main })}
    >
      <VisibilityIcon fontSize="inherit" sx={{ marginRight: 1 }} /> Read only
    </Typography>
  ) : (
    <>
      <IconButton aria-label="delete" className={styles.addButton} onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <ThemeProvider theme={theme}>
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={onClose}>
          {!isReadOnly && (
            <MenuItem onClick={onClickRename} className={styles.menuItem} data-testid="rename-menu-item">
              <>
                <SvgIcon component={renameIcon} className="mr-3" inheritViewBox />
                Rename
              </>
            </MenuItem>
          )}

          <MenuItem onClick={onClickPin} className={styles.menuItem} data-testid="pin-menu-item">
            <SvgIcon component={isAdded ? unpinIcon : pinIcon} className="mr-3" inheritViewBox />
            {isAdded && !shouldHide ? "Unpin" : "Pin"}
          </MenuItem>
        </Menu>
      </ThemeProvider>
      <EntryDialog handleClose={closeDialog} isOpen={isDialogOpen} safeInfo={safeInfo} isAdded={isAdded} />
    </>
  );
};

export default SafeListItemSecondaryAction;
