import { SvgIcon } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import github from "#/images/footer/github.svg";
import twitter from "#/images/footer/twitter.svg";
import telegram from "#/images/footer/telegram.svg";
import styles from "./AppFooter.module.css";

const iconSize = "24px";
const anchors = [
  { icon: github, href: "https://github.com/tonkey-app" },
  {
    icon: twitter,
    href: "https://twitter.com/TonkeyApp",
  },
  { icon: telegram, href: "https://t.me/tonkeyapp" },
];
const Anchors = anchors.map(({ icon, href }) => (
  <a href={href} key={href} target="_blank" rel="noreferrer">
    <SvgIcon component={icon} inheritViewBox sx={{ width: iconSize, height: iconSize }} />
  </a>
));

const Footer = () => (
  <footer className={styles.container}>
    <a className={styles.header} href="https://v1.tonkey.app/" target="_blank" rel="noreferrer">
      Original Version <PlayArrow sx={{ width: iconSize, height: iconSize, color: "#909094" }} />
    </a>
    <div className="flex justify-center items-center gap-4">{Anchors}</div>
  </footer>
);

export default Footer;
