import React from "react";
import { SvgIcon } from "@mui/material";
import { Pathnames } from "@/config";
import AssetsIcon from "#/images/sidebar/assets.svg";
import TransactionIcon from "#/images/sidebar/transactions.svg";
import ABIcon from "#/images/sidebar/address-book.svg";
import SettingsIcon from "#/images/sidebar/settings.svg";

export type NavItem = (typeof transactionNavItems)[number] & {
  icon?: JSX.Element;
};

export const navItems = [
  {
    label: "Assets",
    icon: <SvgIcon component={AssetsIcon} inheritViewBox />,
    href: Pathnames.Balances,
  },
  {
    label: "Transactions",
    icon: <SvgIcon component={TransactionIcon} inheritViewBox />,
    href: Pathnames.TransactionHistory,
  },
  {
    label: "Address book",
    icon: <SvgIcon component={ABIcon} inheritViewBox />,
    href: Pathnames.AddressBook,
  },
  {
    label: "Settings",
    icon: <SvgIcon component={SettingsIcon} inheritViewBox />,
    href: Pathnames.Settings,
  },
];

export const transactionNavItems = [
  {
    label: "Queue",
    href: Pathnames.TransactionQueue,
  },
  {
    label: "History",
    href: Pathnames.TransactionHistory,
  },
];
