import { Box } from "@mui/material";
import Image from "next/image";
import { SafeCreationStatus } from "@/components/new-safe/create/steps/StatusStep/useSafeCreation";
import tonkeyProcessing from "#/images/new-safe/tonkey_processing.gif";
import tonkeyError from "#/images/new-safe/tonkey_error.png";
import tonkeyLogo from "#/images/new-safe/tonkey_logo.png";
import styles from "./styles.module.css";

const getStep = (status: SafeCreationStatus) => {
  const ERROR_TEXT = "Please cancel the process or retry the transaction.";

  switch (status) {
    case SafeCreationStatus.AWAITING:
      return {
        description: "Waiting for transaction confirmation.",
        instruction: "Please confirm the transaction with your connected wallet.",
      };
    case SafeCreationStatus.WALLET_REJECTED:
      return {
        description: "Transaction was rejected.",
        instruction: "",
      };
    case SafeCreationStatus.PROCESSING:
      return {
        description: "Transaction is being executed.",
        instruction: "Please do not leave this page.",
      };
    case SafeCreationStatus.ERROR:
      return {
        description: "There was an error.",
        instruction: ERROR_TEXT,
      };
    case SafeCreationStatus.REVERTED:
      return {
        description: "Transaction was reverted.",
        instruction: ERROR_TEXT,
      };
    case SafeCreationStatus.TIMEOUT:
      return {
        description: "Transaction was not found. Be aware that it might still be processed.",
        instruction: ERROR_TEXT,
      };
    case SafeCreationStatus.SUCCESS:
      return {
        description: "It is now being indexed...",
        instruction: "Please do not leave this page.",
      };
    case SafeCreationStatus.INDEXED:
      return {
        description: "Your Tonkey was successfully created.",
        instruction: "",
      };
    case SafeCreationStatus.INDEX_FAILED:
      return {
        description: "Your Safe is created and will be picked up by our services shortly.",
        instruction:
          "You can already open your Safe. It might take a moment until it becomes fully usable in the interface.",
      };
  }
};

const StatusMessage = ({ status, isError }: { status: SafeCreationStatus; isError: boolean }) => {
  const stepInfo = getStep(status);

  return (
    <>
      <Box>
        {!isError ? (
          status === SafeCreationStatus.INDEXED ? (
            <div className="px-[10px] py-[22px]">
              <Image src={tonkeyLogo} alt="processing" width={76} height={52} />
            </div>
          ) : (
            <Image src={tonkeyProcessing} alt="processing" width={96} height={96} />
          )
        ) : (
          <Image src={tonkeyError} alt="error" width={96} height={96} />
        )}
        <h6 className={styles.description}>{stepInfo.description}</h6>
      </Box>
      {stepInfo.instruction && (
        <Box mt={2}>
          <p className={styles.instruction}>{stepInfo.instruction}</p>
        </Box>
      )}
    </>
  );
};

export default StatusMessage;
