import { useWallet } from "@/hooks";
import useSafeInfo from "./useSafeInfo";
import { defaultSafeInfo } from "@/store/safeInfoSlice";
import { supportedChain } from "@/config/chains";

const useIsWrongChain = (): boolean => {
  const { safe } = useSafeInfo();
  const isValidSafe = safe && safe.address !== defaultSafeInfo.address;
  const wallet = useWallet();
  const isValidWallet = !!wallet;

  if (isValidWallet) {
    if (isValidSafe) {
      if (wallet.chain !== safe.chainId) {
        return true;
      }
    }

    if (wallet.chain !== supportedChain.chainId) {
      return true;
    }
  }
  return false;
};

export default useIsWrongChain;
