import { Checkbox as MuiCheckbox, checkboxClasses, CheckboxProps } from "@mui/material";

const Checkbox = ({ checked, onClick }: CheckboxProps) => (
  <MuiCheckbox
    checked={checked}
    onClick={onClick}
    sx={{
      padding: 0,
      color: "#43474E",
      [`&.${checkboxClasses.checked}`]: {
        color: "#0060A9",
      },
    }}
  />
);

export default Checkbox;
