import type { ReactElement } from "react";
import classnames from "classnames";
import { Skeleton } from "@mui/material";
import { CHAIN } from "@tonconnect/protocol";
import { ChainInfo } from "@/types/chainInfo";
import styles from "./styles.module.css";

type ChainIndicatorProps = {
  chainInfo?: ChainInfo;
  inline?: boolean;
  className?: string;
  renderWhiteSpaceIfNoChain?: boolean;
};

const ChainIndicator = ({ chainInfo, className, inline = false }: ChainIndicatorProps): ReactElement | null => {
  const name = chainInfo ? chainInfo.chainName : "Unknown";
  const bg = chainInfo && chainInfo.chainId === CHAIN.MAINNET ? "#4d99eb" : "#dddddd";
  const textColor = "black";

  const style = {
    backgroundColor: bg,
    color: textColor,
  };

  return name ? (
    <span style={style} className={classnames(inline ? styles.inlineIndicator : styles.indicator, className)}>
      {name}
    </span>
  ) : (
    <Skeleton width="100%" height="22px" variant="rectangular" sx={{ flexShrink: 0 }} />
  );
};

export default ChainIndicator;
